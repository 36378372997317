var intialState = {
 directoryList: {},
 documentList: {},
 previewDownload: {},
 deleteDocuments: {},
 searchedDocumentsList: {},
 isReloadAfterDeleted: {}
}

let apiCallCounter = 0

export default function myDocument(state = intialState, action, index) {
  switch (action.type) {

    case 'GOT_MY_DOCUMENTS_FOLDER_LIST':
      return { ...state, directoryList: action.payload }
    case 'COMPONENT_LOADER_MY_DOCUMENTS':
      return {...state,loaderMyDocument:action.payload}
    case 'FAILED_MY_DOCUMENTS_FOLDER_LIST':
      return { ...state, directoryList: { errorMsg: action.payload } }

    case 'GOT_DOCUMENTS_LIST':
      return { ...state, documentList: action.payload }
    case 'COMPONENT_LOADER_DOCUMENTS_LIST':
      return {...state,loaderDocumentsList:action.payload}
    case 'FAILED_DOCUMENTS_LIST':
      return { ...state, documentList: { errorMsg: action.payload } }

    case 'GOT_PREVIEW_DOWNLOAD_DOCUMENTS':
    let previewDownload = state.previewDownload||{}
        previewDownload[action.index] = action.payload
        apiCallCounter = apiCallCounter +1
      return { ...state, previewDownload: {...previewDownload}, apiCallCounter: apiCallCounter}
    case 'FAILED_PREVIEW_DOWNLOAD_DOCUMENTS':
      return { ...state, previewDownload: { errorMsg: action.payload } }

    case 'GETTING_DELETE_DOCUMENTS':
      return { ...state, deleted:false }

    case 'GOT_DELETE_DOCUMENTS':
      return { ...state, deleteDocuments: action.payload, deleted:true }
    case 'FAILED_DELETE_DOCUMENTS':
      return { ...state, deleteDocuments: { errorMsg: action.payload } }

    case 'RELOAD_AFTER_DELETE': 
      return {...state, isReloadAfterDeleted: action.payload}

    case 'GOT_SEARCHED_DOCUMENTS':
      return { ...state, searchedDocumentsList: action.payload }
    case 'COMPONENT_LOADER_SEARCH_DOCUMENTS_LIST':
      return{...state,loaderSearchDoc:action.payload}
    case 'FAILED_SEARCHED_DOCUMENTS':
      return { ...state, searchedDocumentsList: { errorMsg: action.payload } }

    case 'UPLOAD_FILE_SIZE_BIGGER_THAN_CHECK':
      return { ...state, fileSizeBigger: action.payload }

    case 'GOT_DOWNLOAD_DOCUMENTS':
      return { ...state , downloadedDocument : action.payload }
    case 'FAILED_DOWNLOAD_DOCUMENTS':
      return { ...state , downloadedDocument : action.payload }
    
    default:
      new Error('No action type matched in reducer')
  }
  return state
}