var intialState = {};

export default function fileStatusReducer(state=intialState,action){
	switch(action.type){
        case 'GOT_BROKER_WISE_FILE_DIRECTORY':
            return {...state, fileDirectoryData: action.payload }
        case 'FAILED_TO_GET_BROKER_WISE_FILE_DIRECTORY':
            return {...state, fileDirectoryData: [] }
        case 'GOT_DIRECTORY_DETAILS':
            return {...state, directoryDetails: action.payload }
        case 'FAILED_TO_GET_DIRECTORY_DETAILS':
            return {...state, directoryDetails: [] }
        case 'GOT_FOLIOS_COUNT':
            return {...state, foliosCountData: action.payload }
        case 'FAILED_FOLIOS_COUNT':
            return {...state, foliosCountData: action.payload }
        case 'GOT_UNREAD_MAIL_COUNT':
            return {...state, unreadMailData: action.payload }
        case 'FAILED_UNREAD_MAIL_COUNT':
            return {...state, unreadMailData: action.payload }
        case 'COMPONENT_LOADER_FOR_FOLIOCOUNT_AND_CREATIONTIME':
            return {...state, loaderForFolioAndCreationTime: action.payload }
        case 'GOT_MOBILE_APP_RELEASE_STATUS':
            return { ...state, getMobileAppStatus: action.payload }
        case 'FAILED_MOBILE_APP_RELEASE_STATUS':
            return { ...state, getMobileAppStatus: action.payload }
            
		default:
      		new Error('No action type matched in reducer')
	}
	return state;
}
