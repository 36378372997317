let initialState = {
}
 
export default function iMail(state=initialState,action){
 switch(action.type){	
     
	    case 'GOT_TEMPLATE_LIST':
	    	return {...state, getTemplatesList:action.payload}
	         
	    case 'FAILED_TEMPLATE_LIST':
	        return {...state, getTemplatesList: action.payload }

	    case 'GOT_CAMPAIN_DATA_LIST' :
	    	return {...state, campaignDataList : action.payload }

	    case 'FAILED_CAMPAIN_DATA_LIST' :
	    	return {...state, campaignDataList : {errorMsg:action.payload} }

	    case 'COMPONENT_LOADER_CAMPAIGN_LIST':
			return {...state, loaderCampaignList:action.payload}

	    case 'CREATE_TEMPLATE_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_TO_CREATE_TEMPLATE':
	        return {...state, notification:action.payload, errorNotification: true}

	    case 'DELETE_TEMPLATE_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_TO_DELETE_TEMPLATE':
	        return {...state, notification:action.payload, errorNotification: true}

	    case 'HIDE_NOTIFICATION':
	        return{...state , notification:action.payload, errorNotification: false}

	    case 'COMPONENT_LOADER_CREATE_CAMPAIGN':
			return {...state, loaderCreateCampaign:action.payload}

	    case 'CREATE_UPDATE_CAMPAIGN_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_UPDATE_CAMPAIGN_SUCCESSFULLY':
	        return {...state, notification:action.payload, errorNotification: true}

	    case 'SELECTED_TEMPLATE_DATA_TO_EDIT':
	        return {...state, selectedTemplateToEdit:action.payload}

	    case 'AFTER_UPLOAD_GET_CALL':
	    	return {...state, getCallAfterUpload: action.payload}
	    	
	    case 'DELETE_CAMPAIGN_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_DELETE_CAMPAIGN_SUCCESSFULLY':
	        return {...state, notification:action.payload, errorNotification: true}

	    case 'SELECTED_THUMBNAIL_PATH_TO_EDIT':
	        return {...state, selectedThumbnailToEdit:action.payload}

	    case 'GOT_MAIL_REPORT' :
	    	return {...state, mailReportPreferences : action.payload }

	    case 'FAILED_MAIL_REPORT' :
	    	return {...state, mailReportPreferences : {errorMsg:action.payload} }

	    case 'SHOW_EDITOR' :
            return {...state, showEditor : action.payload}

        case 'CREATE_EDIT_TEMPLATE': 
        	return {...state, createOrEditTemplate : action.payload}
        	
        case 'UPDATE_CAMPAIGN_STATUS_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_UPDATE_CAMPAIGN_STATUS':
	        return {...state, notification:action.payload, errorNotification: true}

	    case 'GOT_CLIENTS_IN_CAMPAIGN_DATA': 
        	return {...state, clientsCampaignData : action.payload}
        case 'COMPONENT_LOADER_CLIENTS_IN_CAMPAIGN':
	    	return {...state, loaderClientsCampaign:action.payload}
	    case 'FAILED_CLIENTS_IN_CAMPAIGN_DATA':
	        return {...state, clientsCampaignData:action.payload}


	    case 'GOT_EXIST_FILE_NAME': 
        	return {...state, existingFile : action.payload}
        case 'COMPONENT_LOADER_FILE_NAME_EXIST':
	    	return {...state, loaderExistingFile:action.payload}
	    case 'FAILED_TO_GET_EXIST_FILE_NAME':
	        return {...state, existingFile:action.payload}

	    case 'GOT_CLIENTS_ASSIGNED_TO_CAMPAIGN': 
        	return {...state, clientsAssignedToCampaign : action.payload}
        case 'COMPONENT_LOADER_CLIENTS_ASSIGNED_TO_CAMPAIGN':
	    	return {...state, loaderClientsCampaign:action.payload}
	    case 'FAILED_CLIENTS_ASSIGNED_TO_CAMPAIGN':
	        return {...state, clientsAssignedToCampaign : action.payload}

	    case 'CLIENTS_ADDED_TO_CAMPAIGN_SUCCESSFULLY' :
	    	return {...state, addRemoveClientNotification: action.payload, addRemoveClientError: false }
	    case 'FAILED_TO_ADD_CLIENTS_TO_CAMPAIGN' :
	    	return {...state, addRemoveClientNotification: action.payload, addRemoveClientError: true }

	    case 'CLIENTS_REMOVED_FROM_CAMPAIGN_SUCCESSFULLY' :
	    	return {...state, addRemoveClientNotification: action.payload, addRemoveClientError: false }
	    case 'FAILED_TO_REMOVE_CLIENTS_FROM_CAMPAIGN' :
	    	return {...state, addRemoveClientNotification: action.payload, addRemoveClientError: true }

	    case 'HIDE_ADD_REMOVE_CLIENT_NOTIFICATION':
	        return{...state , addRemoveClientNotification:null, addRemoveClientError: false}

	    case 'RESET_TEMPLATE_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}
	         
	    case 'FAILED_TO_RESET_TEMPLATE':
	        return {...state, notification:action.payload, errorNotification: true}
		
		case 'LOADER_FOR_RESEND_MAIL':
			return {...state, loaderForResendMail:action.payload}

	    case 'RESEND_MAIL_SUCCESSFULLY':
	    	return {...state, notification:action.payload, errorNotification: false}

	    case 'RESEND_MAIL_FAILED':
	        return {...state, notification:action.payload, errorNotification: true}

  		default:
            new Error('No action type matched in reducer')
    }

  return state; 
} 