var intialState = {
    aumDeleteBrokerFolioTxns : {},
    aumDeleteAdminFolioTxns : {},
    deleteSoaFolioTxns : {},
}

export default function aumDeleteAllFoliosTxns(state = intialState, action) {
    switch ( action.type ) {
        case 'GETTING_AUM_DELETE_FOLIO_TXNS' :
            return { ...state, aumDeleteBrokerFolioTxns : { apiStatus : 1, deletedAllTxn : false, deletingAllTxn : true } }
        case 'GOT_AUM_DELETE_FOLIO_TXNS' :
            return { ...state, aumDeleteBrokerFolioTxns : { apiStatus : 0, deletedAllTxn : true} }
        case 'FAILED_AUM_DELETE_FOLIO_TXNS' :
            return { ...state, aumDeleteBrokerFolioTxns : { errorMsg : action.payload, apiStatus:-1, deletedAllTxn : false } }

        case 'GETTING_AUM_DLT_ADMIN_FOLIO_TXNS' :
            return {...state, aumDeleteAdminFolioTxns : { apiStatus : 1, deletedAllTxn : false, deletingAllTxn : true } }
        case 'COMPONENT_LOADER_AUM_DELETE_FOLIO_TXNS':
             return {...state, loaderAumDelFolioTxn:action.payload}
        case 'GOT_AUM_DLT_ADMIN_FOLIO_TXNS' :
            return {...state, aumDeleteAdminFolioTxns : { apiStatus : 0, deletedAllTxn : true } }
        case 'COMPONENT_LOADER_AUM_DELETE_FOLIO_TXNS':
            return{...state,loaderTxns:action.payload}
        case 'FAILED_AUM_DLT_ADMIN_FOLIO_TXNS' :
            return {...state, aumDeleteAdminFolioTxns : { errorMsg : action.payload, apiStatus:-1, deletedAllTxn : false } }
        
        case 'GETTING_DELETE_SOA_TXNS' : 
            return {...state, deleteSoaFolioTxns : {  deletedTxn : false, deletingTxn : true } }
        case 'GOT_DELETE_SOA_TXNS' : 
            return {...state, deleteSoaFolioTxns : {  deletedTxn : true }, notification: true }
         case 'COMPONENT_LOADER_DELETE_SOA_TXNS':
            return{...state,loaderTxns:action.payload}
        case 'FAILED_DELETE_SOA_TXNS' :
            return {...state, deleteSoaFolioTxns : { errorMsg : action.payload,  deletedTxn : false } }
        
        case 'HIDE_NOTIFICATION':
        return {...state, notification:action.payload }

        case 'GOT_BROKER_LIST_DATA':
            return {...state,brokerListData:action.payload}

        case 'FAILED_BROKER_LIST_DATA':
            return {...state,brokerListData:action.payload}

        case 'GETTING_DETAILED_DOWNLOAD':
            return {...state,downloadDetailsData:action.payload, downloadingData : true, downloadedData : false }
        case 'GOT_DETAILED_DOWNLOAD':
            return {...state,downloadDetailsData:action.payload,  downloadedData : true}

        case 'FAILED_DETAILED_DOWNLOAD':
            return {...state,downloadDetailsData:action.payload, downloadedData : false}

        case 'GET_AUM_RECONCILATION_ARN_RTA_WISE':
            return { ...state, aumRecoArnAndRtaWise: action.payload }
        case 'SHOW_ARN_SUMMARY_TABLE':
            return {...state, showArnSummaryTable: action.payload}
        case 'FAILED_AUM_RECONCILATION_ARN_RTA_WISE':
            return { ...state, aumRecoArnAndRtaWise: action.payload }
        default:
            new Error('No action type matched in reducer')
    }
    
    return state
}

