import React from 'react'
import Cookie from '../../../utils/Cookie'
import {connect} from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import AlertMsg from '../../shared/alert/errorMsg'
import {THEMES,LOADER_TEXT} from '../../../constants/dashboardConst'

import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import Loader from 'app/uiCollection/shared/loaders'

import * as utilsMethods from 'app/utils/dataFormater'


class TxnsSuccessfullyPopup extends React.Component {
  

  render() {
  	let placeMFUOrder = this.props.openStore && this.props.openStore.placeMFUOrder
    
    return (
    	<div id="popupContainer">
            <div class="popupArea box whtBg">
                <div class="popupContentsBox txtCenter">
                    <div class="iconBox txtCenter">
                        {placeMFUOrder && placeMFUOrder.status == "success" && <img src="/ux/confirmation/media/images/rightCheck.png" alt="rightCheck" />}
                        {placeMFUOrder && placeMFUOrder.status != "success" && <img src="/ux/confirmation/media/images/failure.png" alt="failure" />}
                    </div>
                    {placeMFUOrder && placeMFUOrder.status == "success" && <h3 class="grayColor"> {this.props.orderType} Order Successfully placed to MFU!</h3>}
                    {placeMFUOrder && placeMFUOrder.status != "success" && <h3 class="grayColor">Order Failed at MFU!</h3>}
                    <div class="deviderLine"></div>

                    {(placeMFUOrder && placeMFUOrder.status == "failed") && <p>{placeMFUOrder && placeMFUOrder.message}</p> }
                    
                    {placeMFUOrder && placeMFUOrder.status == "success" && <div class="optionArea">
                        <ul>
                            <li>
                                Scheme Name :
                                <span>{placeMFUOrder && placeMFUOrder.schemeName}</span>
                            </li>
                            <li>
                                <span>Order Number  #{placeMFUOrder && placeMFUOrder.orderNo}</span>
                                { utilsMethods.ShowDateAndTime(placeMFUOrder && placeMFUOrder.date)}	
                            </li>
                        </ul>
                    </div>}
                    <div class="btnsContainer  mTop20">
                        <a class="gray" href="javascript:void(0)" onClick= {()=> this.props.closeTab()}>Ok</a>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    openStore: state.factsheetReducer
  }
}
const mapDispatchToProp = (dispatch) => {
  return {dispatch:dispatch}
 }

export default connect(mapStateToProps, mapDispatchToProp)(TxnsSuccessfullyPopup)




