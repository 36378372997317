var intialState = {
    searchRejectionData:{},
    searchFolioNumData:{},
    searchFolioIdData:{},
    aumRecData:{},
    schemesData:{},
    fundsData:{},
    tableUpdateData:{}

};
export default function adminDashboard(state=intialState,action){
	switch(action.type){
        case 'GETTING_REJECTION_DATA':
            return {...state, searchRejectionData:{apiStatus:1} }
        case 'GOT_REJECTION_DATA':
             return {...state, searchRejectionData:{data:action.payload,apiStatus:0} }
        case 'FAILED_REJECTION_DATA':
            return {...state, searchRejectionData:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_FOLIO_NUMBER_DATA':
            return {...state, searchFolioNumData:{apiStatus:1} }
        case 'COMPONENT_LOADER_FOLIO_NUMBER_DATA':
            return {...state, loaderSearchFolio:action.payload }
        case 'GOT_FOLIO_NUMBER_DATA':
             return {...state, searchFolioNumData:{data:action.payload,apiStatus:0} }
        case 'FAILED_FOLIO_NUMBER_DATA':
            return {...state, searchFolioNumData:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_FOLIO_ID_DATA':
            return {...state, searchFolioIdData:{apiStatus:1} }
        case 'COMPONENT_LOADER_FOLIO_ID_DATA':
            return {...state, loaderSearchFolio:action.payload }
        case 'GOT_FOLIO_ID_DATA':
             return {...state, searchFolioIdData:{data:action.payload,apiStatus:0} }
        case 'FAILED_FOLIO_ID_DATA':
            return {...state, searchFolioIdData:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_AUMREC_DATA':
            return {...state, aumRecData:{apiStatus:1} }
        case 'GOT_AUMREC_DATA':
             return {...state, aumRecData:{data:action.payload,apiStatus:0} }
        case 'COMPONENT_LOADER_AUM_RECONCILATION':
             return {...state, loaderAumReconcilation:action.payload}
        case 'FAILED_AUMREC_DATA':
            return {...state, aumRecData:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_SCHEMES_DATA' :
            return {...state, schemesData:{apiStatus:1}}
        case 'GOT_SCHEMES_DATA' :
            return {...state, schemesData:{data:action.payload, apiStatus:0} }
        case 'COMPONENT_LOADER_SYSTEM_DATA':
        return{...state,laoderSystemData:action.payload}
        case 'FAILED_SCHEMES_DATA' :
            return {...state, schemesData:{errorMsg:action.payload, apiStatus:-1} }

        case 'GOT_NAV_HISTORY' :
                return {...state, navHistoryData:action.payload }
        case 'FAILED_NAV_HISTORY' :
                return {...state, navHistoryData:action.payload }

        case 'GETTING_FUND_DATA' :
            return {...state, fundsData : {apiStatus:1}}
        case 'GOT_FUND_DATA' :
            return {...state, fundsData:{data:action.payload, apiStatus:0}}
        case 'FAILED_FUND_DATA' :
            return {...state, fundsData : {errorMsg:action.payload, apiStatus:-1}}

        case 'GETTING_TABLEUPDATE_DATA' :
            return {...state, tableUpdateData :{ apiStatus:1}}
        case 'GOT_TABLEUPDATE_DATA' :
            return {...state, tableUpdateData : {data: action.payload, apiStatus:0}}
        case 'FAILED_TABLEUPDATE_DATA' :
            return {...state, tableUpdateData : {errorMsg:action.payload, apiStatus:-1}}

        case 'GETTING_MISMATCH_DATA' :
            return {...state, mismatchResult :{ gettingMismatchData:true}}
        case 'GOT_MISMATCH_DATA' :
            return {...state, mismatchResult : {...action.payload,gotMismatchData:true}}
        case 'COMPONENT_LOADER_MISMATCH_DATA':
            return{...state,loaderMisMatch:action.payload}
        case 'FAILED_GETTING_MISMATCH' :
            return {...state, mismatchResult : {errorMsg:action.payload}}
        case 'DELETING_TXN' :
            return {...state, deletingTxn:true,deletedTxn:false}
        case 'DELETED_TXN' :
            return {...state, deletingTxn:false,deletedTxn:true, notificationStatus:action.payload}
        case 'DELETED_TXN_SUCCESSFULLY':
            return{...state, notification : action.payload}
        case 'COMPONENT_LOADER_DELETE_TXN' :
            return {...state, loaderDeleteTxn : action.payload}
        case 'FAILED_DELETING_TXN' :
            return {...state,deletingTxn:false,failedDeletingTxn:true, errorMsg :action.payload ,notification:action.payload }
        case 'DELETED_MULTIPLE_TXN_SUCCESSFULLY':
            return {...state, deletingTxn:false,deletedTxn:true, notificationStatus:action.payload}
        case 'FAILED_TO_DELETE_MULTIPLE_TXN':
            return {...state,deletingTxn:false,failedDeletingTxn:true, errorMsg :action.payload  }
        case 'GOT_TXN_TYPE_REJECTION_MAPPING_DATA':
        case 'FAILED_TXN_TYPE_REJECTION_MAPPING_DATA':
            return {...state, txnRejectionMappingData : action.payload}
       
        case 'CLEAR_NOTIFICATION' :
            return {...state, notificationStatus : action.payload, notificationError : false, failedDeletingTxn: false  }

        case 'COMPONENT_LOADER_ASSET_SUBASSET' :
            return {...state, loaderAsetAndSubAset : action.payload}

        case 'GOT_CREATE_ASSET' :
            return {...state, notificationStatus : action.payload, notificationError : false   }
        case 'FAILED_CREATE_ASSET' :
            return {...state, notificationStatus : action.payload,  notificationError : true}

        case 'GOT_CREATE_SUB_ASSET' :
            return {...state, notificationStatus : action.payload, notificationError : false }
        case 'FAILED_CREATE_SUB_ASSET' :
            return {...state, notificationStatus : action.payload,  notificationError : true }

        case 'GOT_ASSET_FD_DATA_LIST' :
            return {...state, assetFDListData : action.payload}
        case 'COMPONENT_LOADER_ASSETDATALIST' :
            return { ...state, loaderForAssetdatalist : action.payload}
        case 'FAILED_ASSET_FD_DATA_LIST' :
            return {...state, assetFDListData : action.payload }

        case 'GOT_ASSET_TYPE_LISTS' :
            return {...state, assetTypeListData : action.payload}
        case 'FAILED_ASSET_TYPE_LISTS' :
            return {...state, assetTypeListData : action.payload }

        case 'COMPONENT_LOADER_FROM_ARN' :
            return {...state, loaderArnMapping : action.payload}
        case 'GOT_FROM_ARN' :
            return {...state, fromArnListData : action.payload}
        case 'FAILED_FROM_ARN' :
            return {...state, fromArnListData : action.payload }


        case 'COMPONENT_LOADER_TO_ARN' :
            return {...state, loaderArnMapping : action.payload}
        case 'GOT_TO_ARN' :
            return {...state, toArnListData : action.payload}
        case 'FAILED_TO_ARN' :
            return {...state, toArnListData : action.payload }

        case 'SUBMIT_ARN_MAPPING' :
            return {...state, submitArnData : action.payload, notificationError : false   }
        case 'FAILED_ARN_MAPPING' :
            return {...state, submitArnData : action.payload,  notificationError : true}

        case 'ADD_REMARKS_SUCCESSFULLY':
            return {...state, notification: action.payload, errorMsg: false}
        case 'REMARKS_FAILED':
            return {...state, notification: action.payload, errorMsg: true}

        case 'HIDE_NOTIFICATION':
        return {...state, notification:action.payload, notificationmsg: action.payload }

        case 'GOT_RTAS' :
            return {...state, rtaDataList : action.payload}
        case 'FAILED_RTAS' :
            return {...state, rtaDataList : action.payload}
        case 'COMPONENT_LOADER_RTAS' :
            return {...state, loaderRta : action.payload}

        case 'FOLIOS_UNFREEZE_SUCCESFULLY' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FOLIOS_UNFREEZE_FAILED' :
            return { ...state, notification: action.payload, errorMsg: true }

        case 'GET_RESET_TOKEN_REQUEST_SUCCESFULLY':
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FAILED_TO_GET_RESET_TOKEN_REQUEST' :
            return { ...state, notification: action.payload, errorMsg: true }

        case 'DB_SHARDING_STARTED' :
            return { ...state, notification: action.payload, errorMsg: false ,shardMeNotification: action.status}
        case 'DB_SHARDING_FAILED' :
            return { ...state, notification: action.payload, errorMsg: true  ,shardMeNotification: action.payload }
        case 'FOLIOS_FREEZE_SUCCESFULLY' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FOLIOS_FREEZE_FAILED' :
            return { ...state, notification: action.payload, errorMsg: true }

       case 'GOT_MF_SCHEME_DATA':
            return {...state,mfSchemesData:action.payload}
        case 'FAILED_MF_SCHEME_DATA':
            return {...state,mfSchemesData:action.payload}
        case 'COMPONENT_LOADER_FUND_SCHEMES_DATA':
            return {...state,loaderAumSearch:action.payload}

        case 'GOT_FOLIOS_UNDER_BROKER_DATA':
            return {...state,folioUnderBrokerData:action.payload}
        case 'FAILED_FOLIOS_UNDER_BROKER_DATA':
            return {...state,folioUnderBrokerData:action.payload}
        case 'FOLIOS_TRANSMISSION_LOADER':
            return {...state,loaderAumSearch:action.payload}
        case 'GOT_FOLIOS_UNDER_TRANSMISSION':
            return {...state,loaderUndertransmission:action.payload}
        case 'FAILED_FOLIOS_UNDER_TRANSMISSION':
            return {...state,loaderUndertransmission:action.payload}
        case 'COMPONENT_LOADER_FOLIOS_UNDER_BROKER_DATA':
            return {...state,loaderAumSearch:action.payload}
        case 'UPDATE_ARNID_SUCCESSFULLY':
            return {...state,notification: action.payload, errorMsg: false, msgFlag: true }
        case 'FAILED_TO_UPDATE_ARNID':
            return {...state,notification: action.payload, errorMsg: true, msgFlag: true}
        case 'COMPONENT_LOADER_FOR_UPDATE_ARN':
            return {...state,loaderUpdateArnId:action.payload}

        case 'G0T_SEARCH_FOLIO_DATA':
            return {...state,searchFolioInDirectoryData:action.payload, errorMsg: false}
        case 'FAILED_SEARCH_FOLIO_DATA':
            return {...state,searchFolioInDirectoryData:action.payload, errorMsg: true}
        case 'COMPONENT_LOADER_SEARCH_FOLIO':
            return {...state, loderFolioInDirectory:action.payload }
        case 'GET_GO_BACK_CALL':
            return {...state,goBackCall:action.payload}
        case 'GOT_DATA_FILE_REPROCESS_ADMIN':
            return {...state,reprocessAdmin:action.payload , notificationmsg:action.payload, errorMsg: false} 
        case 'FAILED_TO_REPROCESS_FILE_ADMIN':
            return {...state,reprocessAdmin:action.payload , notificationmsg:action.payload, errorMsg: true}
        case 'SAVE_TO_DB_SUCCESSFULLY':
             return {...state, aumReconcilationData:{data:action.payload,apiStatus:0} }
        case 'COMPONENT_LOADER_SAVE_TO_DB':
             return {...state, loaderAumReconcilation:action.payload}
        case 'FAILED_SAVE_TO_DB':
            return {...state, aumReconcilationData:{errorMsg:action.payload,apiStatus:-1} }
        
            return {...state,reprocessAdmin:action.payload ,notificationmsg:action.payload}   

        case 'COMPONENT_LOADER_CREATE_SOA' : 
            return {...state,loaderCreateSOARequest:action.payload}
        case 'REQUEST_SOA_SUCCESSFULLY' : 
            return {...state,createSOARequest:action.payload}
        case 'FAILED_CREATE_SOA' : 
            return {...state,createSOARequest:action.payload}

        case 'COMPONENT_LOADER_SOA_JOB_LIST' : 
            return {...state,loaderSOAJobTable:action.payload}
        case 'GOT_SOA_JOB_LIST' : 
            return {...state,SOAJobDataList:action.payload}
        case 'FAILED_SOA_JOB_LIST' : 
            return {...state,SOAJobDataList:action.payload}

        case 'COMPONENT_LOADER_EMAIL_LOG_DATA':
            return {...state,loaderEmailLogTable: action.payload}
        case 'GOT_EMAIL_LOG_DATA_SUCCESSFULLY':
            return {...state, emailLogData: action.payload}
        case 'FAILED_TO_GET_EMAIL_LOG_DATA':
            return {...state, emailLogData: { message : action.payload, status : -1} }

        case 'COMPONENT_LOADER_LOG_STATUS_LIST':
            return { ...state, loaderStatusList: action.payload }
        case 'GOT_LOG_STATUS_LIST_SUCCESSFULLY':
            return { ...state, logStatusList: action.payload }
        case 'FAILED_TO_GET_LOG_STATUS_LIST':
            return { ...state, logStatusList: [] }

        case 'COMPONENT_LOADER_SIP_RECONCILIATION' :
            return {...state, loaderSipReco : action.payload}
        case 'SIP_RECONCILIATION':
            return {...state, sipRecoData: action.payload}
        case 'FAILED_SIP_RECONCILIATION' :
            return {...state, sipRecoData :action.payload  }
        
        case 'UPDATE_AUM_SUCCESFULLY' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'UPDATE_AUM_FAILED' :
            return { ...state, notification: action.payload, errorMsg: true }
            
        case 'GOT_BROKERAGE_REPORT_DATA':
            return { ...state, brokerageData: action.payload}
        case 'FAILED_TO_GET_BROKERAGE_REPORT_DATA':
            return { ...state, brokerageData: null}


        case 'GOT_CRON_INFO_DATA':
            return { ...state, cronInfo: action.payload}
        case 'FAILED_TO_GET_CRON_INFO_DATA':
            return { ...state, cronInfo: null}

        case 'GOT_CRON_DETAILS':
            return { ...state, cronDetails: action.payload}
        case 'FAILED_TO_GET_CRON_DETAILS':
            return { ...state, cronDetails: null}

        case 'CRON_DETAILS_PRIORITY_WISE':
            return{...state, cronDetailsPriorityWise:action.payload} 
        case 'FAILED_CRON_DETAILS_PRIORITY_WISE':
            return{...state, cronDetailsPriorityWise:action.payload}  

        case 'GOT_SCRAPPER_CRON_INFO_DATA':
            return { ...state, scrapperCronInfo: action.payload}
        case 'FAILED_TO_GET_SCRAPPER_CRON_INFO_DATA':
            return { ...state, scrapperCronInfo: null}

        case 'GOT_SCRAPPER_CRON_DETAILS':
            return { ...state, scrapperCronDetails: action.payload }
        case 'FAILED_TO_GET_SCRAPPER_CRON_DETAILS':
            return { ...state, scrapperCronDetails: null }

        case 'SCRAPPER_CRON_DETAILS_PRIORITY_WISE':
            return { ...state, scrapperCronDetailsPriorityWise: action.payload }
        case 'FAILED_SCRAPPER_CRON_DETAILS_PRIORITY_WISE':
            return { ...state, scrapperCronDetailsPriorityWise: action.payload }

        case 'GOT_IMPORT_REJECTION_DATA':
            return {...state, importRejectionsData: action.payload}
        case 'FAILED_IMPORT_REJECTION_DATA' :
            return {...state, importRejectionsData :action.payload  }

        case 'GOT_CRON_HISTORY_DATA':
            return { ...state, cronHistoryData: action.payload}
        case 'FAILED_CRON_HISTORY_DATA':
            return { ...state, cronHistoryData: null}

        case 'GOT_CRON_TAB_DATA' :
            return { ...state, cronTabData : action.payload}    
        case 'FAILED_CRON_HISTORY_DATA' :
            return { ...state, cronTabData : action.payload} 
        case 'SUCCESS_MERGED_ASSET' :
            return {...state, notificationStatus : action.payload, notificationError : false   }
        case 'FAILED_MERGED_ASSET' :
            return {...state, notificationStatus : action.payload,  notificationError : true}

        case 'COMPONENT_LOADER_DBF_UPLOAD':
            return { ...state, loaderDbfUpload: action.payload }
        case 'DBF_UPLOAD_SUCESSFUL':
            return { ...state, dbfUploadData: action.payload }
        case 'FAILED_DBF_UPLOAD':
            return { ...state, dbfUploadData: action.payload }

        case "SAVE_BID_FOR_FILE_IMPORT_HISTORY":
            return { ...state, bidForFileImportHistory: action.payload }

        case 'GOT_CORPORATE_ACTIONS_DATA' :
            return {...state, corporteActionsData:action.payload }
        case 'FAILED_CORPORATE_ACTIONS_DATA' :
            return {...state, corporteActionsData: action.payload}

        case 'GOT_ALL_SCHEME_DATA' :
            return {...state, allSchemesData:action.payload }
        case 'FAILED_ALL_SCHEME_DATA' :
            return {...state, allSchemesData: action.payload}

        case 'SUCCESS_DELETE_AUM' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FAILED_DELETE_AUM' :
            return { ...state, notification: action.payload, errorMsg: true }

        case 'SUCCESS_DELETE_REVERSAL' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FAILED_DELETE_REVERSAL' :
            return { ...state, notification: action.payload, errorMsg: true }

        case 'SUCCESS_DELETE_PROCESS_TXNS' :
            return { ...state, notification: action.payload, errorMsg: false }
        case 'FAILED_DELETE_PROCESS_TXNS' :
            return { ...state, notification: action.payload, errorMsg: true }

        case 'GOT_INVESTOR_FOLIO_LIST_DATA' :
            return {...state, allFoliosAndClientsData:action.payload }
        case 'FAILED_INVESTOR_FOLIO_LIST_DATA' :
            return {...state, allFoliosAndClientsData: action.payload}
        case 'COMPONENT_LOADER_FOLIO_CLIENT_AUM_RECONCILATION':
            return {...state,loaderFoliosAndClientSearch:action.payload}
        case 'SUCCESS_TOUCH_FOLIOS' :
            return { ...state, notification: action.payload, errorMsg: false}
        case 'FAILED_TOUCH_FOLIOS' :
            return { ...state, notification: action.payload, errorMsg: true}
        case 'GET_MASTER_SCHEMES_DATA':
        case 'FAILED_MASTER_SCHEMES_DATA':
            return { ...state, masterSchemesData: action.payload }
        case 'GET_SCHEME_MISMATCH_CODE':
        case 'FAILED_SCHEME_MISMATCH_CODE':
            return { ...state, schemeMismatchCodeData: action.payload }
        case 'GET_SUBMIT_BSE_SCHEME_AND_FREQ_MASTER_MESSAGE':
            return {...state, submitBseSchemeAndFreqMaster: action.payload}
        case 'SUBMIT_BSE_SCHEME_MASTER_LOADER':
            return {...state, submitBseSchemeMasterLoader: action.payload}
        
        case 'GET_UPDATE_SCHEME_CODE':
        case 'FAILED_UPDATE_SCHEME_CODE':
            return { ...state, notification: action.payload }
        case 'LOADER_MASTER_SCHEME_DATA' :    
            return { ...state, masterSchemeLoader: action.payload }        
        case 'EMAIL_PARSING_LOGS':
            return {...state, emailParsingLogs: action.payload}
        case 'GET_SERVER_HOST_NAME':
        case 'FAILED_SERVER_HOST_NAME':
            return { ...state, serverHostName: action.payload }
        case 'GET_SCRAPPER_CRON_TAB_DATA':
        case 'FAILED_SCRAPPER_CRON_TAB_DATA':
            return { ...state, scrapperCronTabData: action.payload }
        case 'GOT_TASK_INFO_DATA':
        case 'FAILED_TASK_INFO_DATA':
            return { ...state, getTaskInfoData: action.payload }
        case 'UPDATE_TASK_INFO_DATA':
        case 'FAILED_TO_UPDATE_TASK_INFO_DATA':
            return { ...state, notificationStatus: action.payload }
        case 'CRON_PUSH_STARTED':
        case 'FAILED_TO_START_CRON':
            return { ...state, notificationStatus: action.payload }
        case 'GOT_TASK_INFO_DATA_FOR_RUNNING':
        case 'FAILED_TASK_INFO_DATA_FOR_RUNNING':
            return { ...state, getTaskInfoRunningData: action.payload }
        case 'GOT_TASK_INFO_DATA_FOR_FAILED':
        case 'FAILED_TASK_INFO_DATA_FOR_FAILED':
            return { ...state, getTaskInfoFailedData: action.payload }
        case 'GOT_TASK_INFO_DATA_FOR_DEAD_QUEUE':
        case 'FAILED_TASK_INFO_DATA_FOR_DEAD_QUEUE':
            return { ...state, getTaskInfoQueueData: action.payload }
        case 'GOT_TASK_INFO_DATA_FOR_PRODUCED':
        case 'FAILED_TASK_INFO_DATA_FOR_PRODUCED':
            return { ...state, getTaskInfoProducedData: action.payload }    
        case 'SELECTED_PRIORITY_STATUS':
            return { ...state, selectedPriorityStatus: action.payload }    
        case 'COMPONENT_LOADER_TABLE_RUNNING':
            return { ...state, tableLoaderRunning: action.payload }    
        case 'COMPONENT_LOADER_TABLE_FAILED':
            return { ...state, tableLoaderFailed: action.payload }
        case 'COMPONENT_LOADER_TABLE_DEAD_QUEUE':
            return { ...state, tableLoaderDeadQueued: action.payload }
        case 'COMPONENT_LOADER_TABLE_PRODUCED':
            return { ...state, tableLoaderProduced: action.payload }    

		default:
      		new Error('No action type matched in reducer')
	}
	return state;
}
