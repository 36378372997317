let intialState = {}

export default function deleteBroker(state=intialState,action){
	switch(action.type){	

        case 'DELETE_BROKER_DETAIL_SUCCESSFULLY':
                return {...state, notificationStatus:action.payload, error: false }

        case 'DELETE_BROKER_DETAIL_FAILED':
                return {...state, notificationStatus:action.payload, error: true }

        case 'HIDE_NOTIFICATION':
      		return{...state , notificationStatus:action.payload}
    
		default:
      		new Error('No action type matched in reducer')
	}
	return state;
}