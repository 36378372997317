

var initialState = {}

export default function userDefinedSecuritiesReducer( state = initialState, action){

	switch(action.type){
        case 'FORM_DATA_LOADER':
            return { ...state, userdefinedSecuritiesLoader: action.payload }
		case 'GET_USER_DEFINED_CATEGORIES_DATA':
        case 'FAILED_USER_DEFINED_CATEGORIES_DATA':
            return { ...state, userDefinedCategoriesData: action.payload }
        case 'CREATE_OR_UPDATE_USER_DEFINED_CATEGORY':
        case 'FAILED_CREATE_OR_UPDATE_USER_DEFINED_CATEGORY':
            return { ...state, createUserDefinedCategoryResponse: action.payload }
        case 'DELETE_USER_DEFINED_CATEGORY':
        case 'FAILED_DELETE_USER_DEFINED_CATEGORY':
            return { ...state, deleteUserDefinedCategoryResponse: action.payload }
        
        case 'GET_USER_DEFINED_SECURITIES_DATA':
        case 'FAILED_USER_DEFINED_SECURITIES_DATA':
            return { ...state, userDefinedSecuritiesData: action.payload }
        case 'CREATE_OR_UPDATE_USER_DEFINED_SECURITY':
        case 'FAILED_CREATE_OR_UPDATE_USER_DEFINED_SECURITY':
            return { ...state, createUserDefinedSecurityResponse: action.payload }
        case 'DELETE_USER_DEFINED_SECURITY':
        case 'FAILED_DELETE_USER_DEFINED_SECURITY':
            return { ...state, deleteUserDefinedSecurityResponse: action.payload }

        case 'GET_ALL_USER_DEFINED_PRICES_DATA':
        case 'FAILED_ALL_USER_DEFINED_PRICES_DATA':
            return { ...state, allUserDefinedPricesData: action.payload }
            
        case 'CREATE_USER_DEFINED_NAV_IN_PRICE':
        case 'FAILED_CREATE_USER_DEFINED_NAV_IN_PRICE':
            return { ...state, createUserDefinedPriceInNavResponse: action.payload }
        case 'UPDATE_USER_DEFINED_NAV_IN_PRICE':
        case 'FAILED_UPDATE_USER_DEFINED_NAV_IN_PRICE':
            return { ...state, updateUserDefinedPriceResponse: action.payload }
    
        case 'GET_USER_DEFINED_PRICES_DATA':
        case 'FAILED_USER_DEFINED_PRICES_DATA':
            return { ...state, getUserDefinedPricesData: action.payload }
        case 'DELETE_USER_DEFINED_ALL_PRICE':
        case 'FAILED_DELETE_USER_DEFINED_ALL_PRICE':
            return { ...state, deleteUserDefinedAllPriceResponse: action.payload }
        case 'DELETE_USER_DEFINED_SECURITY_PRICE':
        case 'FAILED_DELETE_USER_DEFINED_SECURITY_PRICE':
            return { ...state, deleteUserDefinedSecurityPriceResponse: action.payload }
        
        case 'GET_USER_DEFINED_SECURITY_LIST_DATA':
        case 'FAILED_USER_DEFINED_SECURITY_LIST_DATA':
            return { ...state, userDefinedSecurityListData: action.payload }
        case 'GET_USER_DEFINED_SUB_CATEGORY_DATA':
        case 'FAILED_USER_DEFINED_SUB_CATEGORY_DATA':
            return { ...state, userDefinedSubCategoryData: action.payload }

		default:
        new Error('No action type matched in reducer')
	}

	return state
}