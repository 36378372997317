import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'app/uiCollection/shared/chunks/loading';
//import delay from '../utils/delay';

const LoadableLogin = Loadable({
  loader: () => import('app/uiCollection/user/login'),
  loading: Loading,
});

export default function LoadableLoginCont() {
	
  return <LoadableLogin/>;
}