export default function onboardingBseReducer(state, action) {
    switch (action.type) {
        case "CHANGE_STEP_NO":
            return { ...state, stepNo: action.payload };

        case 'SAVE_BSE_ID':
            return { ...state, bseid: action.payload };

        case "ADD_PERSONAL_INFO_VARIABLES":
            return { ...state, personalInfoVariables: action.payload };

        case "ADD_ADDRESS_FORM_VARIABLES":
            return { ...state, addressFormVariables: action.payload };

        case "ADD_BANK_DETAILS_VARIABLES":
            return { ...state, bankDetailVariables: action.payload };

        case 'ADD_FATCA_FORM_VARIABLES':
            return { ...state, fatcaFormVariables: action.payload };

        case 'ADD_SUBMIT_DOCUMENT_VARIABLES':
            return { ...state, submitDocumentVariables: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING':
            return { ...state, loaderOnBoardingComponent: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING_BSE':
                return { ...state, loaderOnBoardingComponentBSE: action.payload };
    
        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }

        case 'CREATED_UCC_SUCCESSFULLY':
            return { ...state, uccCreationData: action.payload && action.payload.result, notificationData: action.payload };
        case 'FAILED_TO_CREATE_UCC':
            return { ...state, uccCreationData: null, notificationData: action.payload };

        case 'GOT_UCC_LIST_BY_APPID':
            return { ...state, bseDetailsByAppid: action.payload }
        case 'FAILED_TO_GET_UCC_LIST_BY_APPID':
            return { ...state, bseDetailsByAppid: null }

        case 'GOT_UCC_TEMP_DATA':
            return { ...state, uccTempData: action.payload }
        case 'FAILED_TO_GET_UCC_TEMP_DATA':
            return { ...state, uccTempData: null } 

        case 'GOT_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: action.payload && action.payload.result, notificationData: action.payload }
        case 'FAILED_TO_GET_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: null, notificationData: action.payload } 

        case 'COMPONENT_LOADER_BSE_COUNTRY_MASTER':
            return { ...state, loaderCountries: action.payload }
        case 'GOT_BSE_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: action.payload };
        case 'FAILED_TO_GET_BSE_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: null };

        case 'GOT_CLIENT_INFORMATION_SUCCESSFULLY':
            return { ...state, clientInformation: action.payload };
        case 'FAILED_TO_GET_CLIENT_INFORMATION':
            return { ...state, clientInformation: null };

        case 'CHECKED_KYC_SUCCESSFULLY':
            return { ...state, checkKycData: action.payload  };
        case 'FAILED_TO_CHECK_KYC':
            return { ...state, checkKycData: null };


        case 'GOT_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: action.payload }
        case 'FAILED_TO_GET_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: null };

        case 'COMPONENT_LOADER_BANKS_MASTER':
            return { ...state, loaderBanks: action.payload }
        case 'GOT_BANKS_MASTER_DATA':
            return { ...state, banksMaster: action.payload }
        case 'FAILED_TO_GET_BANKS_MASTER_DATA':
            return { ...state, banksMaster: null };

        case 'GOT_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: action.payload }
        case 'FAILED_TO_GET_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: null };

        case 'COMPONENT_LOADER_STATE_MASTER':
            return { ...state, loaderStates: action.payload }
        case 'GOT_BSE_STATE_MASTER_DATA':
            return { ...state, stateMaster: action.payload }
        case 'FAILED_TO_GET_BSE_STATE_MASTER_DATA':
            return { ...state, stateMaster: null };

        case 'COMPONENT_LOADER_APPLICABLE_INCOMES':
            return { ...state, loaderApplicableIncomes: action.payload }
        case 'GOT_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: action.payload }
        case 'FAILED_TO_GET_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: null };

        case 'GOT_UCC_HOLDERS_AND_BANKS':
            return { ...state, holdersAndBanks: action.payload }
        case 'FAILED_TO_GET_UCC_HOLDERS_AND_BANKS':
            return { ...state, holdersAndBanks: null };



        case 'COMPONENT_LOADER_SUBMIT_DOCUMENT':
            return { ...state, loaderSubmitDocument: action.payload }

        case 'UPLOADED_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }


        case 'SUBMIT_NSE_CREATION_DOCUMENT_SUCCESSFUL':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
        case 'FAILED_TO_SUBMIT_NSE_CREATION_DOCUMENT':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }

        case 'UPLOAD_CHEQUES_SUCCESSFULLY':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_CHEQUES':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
            

        case 'CHECKED_NSE_FATCA_SUCCESSFULLY':
            return { ...state, checkFatcaData: action.payload }
        case 'FAILED_TO_CHECK_NSE_FATCA':
            return { ...state, checkFatcaData: null }


        case 'SUBMITTED_BSE_FATCA_SUCCESSFULLY':
            return { ...state, bseFatcaResponse: action.payload, notificationData: action.payload }
        case 'FAILED_TO_SUBMIT_BSE_FATCA':
            return { ...state, bseFatcaResponse: null, notificationData: action.payload }

        case 'GOT_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}
        case 'FAILED_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}

        case 'ONBOARDING_EDIT_FATCA_DETAILS_DATA':
            return { ...state, editFatcaDetails: action.payload }

        case 'SHOW_COMPLIATION_PAGE' :
            return { ...state, completionPage: action.payload }
            case 'EXCHANGE_TYPE' :
                return { ...state, exchangeNo: action.payload }
    
        case 'CLEAR_ONBOARDING_STORE':
            return {}

        default:
            return { ...state }
    }
}