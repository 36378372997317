export default function PossibleDuplicates(state={},action){
	
	switch(action.type){

		case 'GOT_POTENTIAL_DUPLICATES_DATA':
			return{...state,duplicatesData:action.payload}
		case 'COMPONENT_LOADER_POTENTIAL_DUPLICATES':
			return {...state,loaderDuplicates:action.payload}
		case 'FAILED_POTENTIAL_DUPLICATES_DATA':
			return{...state,duplicatesData:action.payload}

		case 'GOT_BULK_MERGE_DUPLICATES_DATA':
			return {...state,bulkMergeDuplicateData:action.payload}
		case 'COMPONENT_LOADER_BULK_MERGE':
			return {...state,loaderBulkMerge:action.payload}
		
		case 'GOT_SET_FAMILY_HEAD_BULK_DATA':
			return {...state,bulktFamilyHeadData:action.payload}
		case 'COMPONENT_LOADER_SET_FAMILY_HEAD_BULK':
			return {...state,loaderbulkFamilyHead:action.payload}

		case 'SHOW_HIDE_NOTIFICATION':
			return {...state,notificationData:action.payload}

		default:
      		new Error('No action type matched in Filter reducer')
	}
	return state;
}