import SessionData from '../../utils/sessionData'
var intialState = {
    investorMergeClients:{},
    investorSetFamilyHead:{},
    investorUpdateLock:{},
    setRelationshipManager: {},
    getRelationshipManagerList: {},
    investorLookup: {},
    topSchemes : {},
    fileImport : {},
    generalInfoData: {},
    additionalInfoData: {},
    billingInfoData: {},
    missingData : {},
    notification:null,
    clientFinderVariables: {
        userTypeFilter:'familyHead',
        userTypeVal:'client',
        listOfUId: {},
        selectedRows:{},
        rowListCount:0,
        searchPlaceHolder: true,
        selectedOptionVal: '',
        getIds: [],
        clearSearchToggle:true,
        showHideEditTable:true,
        selectedRowObj:''
    }
}    
    
    
export default function dashboard(state=intialState,action){
switch (action.type) {

  case 'GETTING_DATA_FILE_IMPORT':
      return {...state, fileImport:{apiStatus:1} }

  case 'GOT_DATA_FILE_IMPORT':
    return {...state, fileImport:{data:action.payload.data,apiStatus:0} }

  case 'DATA_FILE_IMPORT_FAILED':
      return {...state, fileImport:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'GETTING_DATA_TOP_SCHEMES':
      return {...state, topSchemes:{apiStatus:1} }

  case 'GOT_DATA_TOP_SCHEMES':
    return {...state, topSchemes:{data:action.payload.data,apiStatus:0} }

  case 'DATA_FILE_TOP_SCHEMES':
      return {...state, topSchemes:{data:intialState.topSchemes.data,errorMsg:action.payload,apiStatus:-1
      } }


  case 'GETTING_INVESTOR_LOOKUP_DATA':
    return {...state, investorLookup:{apiStatus:1} }

  case 'GOT_INVESTOR_LOOKUP_DATA':
    return {...state, investorLookup:{data:action.payload,apiStatus:0}, hideTable: false }

  case 'COMPONENT_LOADER_INVESTOR_LOOKUP':
    return {...state, loaderInvestorLookup:action.payload }


  case 'FAILED_INVESTOR_LOOKUP_DATA':
      return {...state, investorLookup:{errorMsg:action.payload,apiStatus:-1
      } }


  case 'GETTING_MERGE_CLIENTS_DATA':
    return {...state, investorMergeClients:{} }

  case 'COMPONENT_LOADER_INVESTOR':
    return{...state,loaderInvestorLookup:action.payload}
  case 'GOT_MERGE_CLIENTS_DATA':
    return {...state, investorMergeClients:{data:action.payload,apiStatus:0} }
  case 'MERGE_CLIENTS_IN_QUEUE':
    return {...state, investorMergeClients:{message:action.payload,apiStatus:1} }

  case 'FAILED_MERGE_CLIENTS_DATA':
      return {...state, investorMergeClients:{errorMsg:action.payload&&action.payload.message,apiStatus:action.payload.status
      } }

  case 'GETTING_FAMILY_HEAD_DATA':
    return {...state, investorSetFamilyHead:{apiStatus:1} }

  case 'GOT_FAMILY_HEAD_DATA':
    return {...state, investorSetFamilyHead:{data:action.payload,apiStatus:0}, notification:action.payload, hideTable: true  }

  case 'FAILED_FAMILY_HEAD_DATA':
      return {...state, investorSetFamilyHead:{errorMsg:action.payload&&action.payload.message,apiStatus:action.payload&&action.payload.status}, notification:action.payload, errorMsg: true }


  case 'GETTING_INVESTOR_LOCK_DATA':
    return {...state, investorUpdateLock:{apiStatus:1} }

  case 'GOT_INVESTOR_LOCK_DATA':
    return {...state, investorUpdateLock:{data:action.payload,apiStatus:0} }

  case 'FAILED_INVESTOR_LOCK_DATA':
      return {...state, investorUpdateLock:{errorMsg:action.payload,apiStatus:-1
      } }

    case 'GETTING_SET_RELATION_SHIP_MANAGER_DATA':
    return {...state, setRelationshipManager:{apiStatus:1} }

  case 'GOT_SET_RELATION_SHIP_MANAGER_DATA':
    return {...state, setRelationshipManager:{data:action.payload,apiStatus:0} }

  case 'FAILED_SET_RELATION_SHIP_MANAGER_DATA':
      return {...state, setRelationshipManager:{errorMsg:action.payload,apiStatus:-1
      } }

  
  case 'GETTING_GET_RELATION_SHIP_MANAGER_LIST':
    return {...state, getRelationshipManagerList:{apiStatus:1} }

  case 'GOT_GET_RELATION_SHIP_MANAGER_LIST':
    return {...state, getRelationshipManagerList:{data:action.payload.data,apiStatus:0} }

  case 'FAILED_GET_RELATION_SHIP_MANAGER_LIST':
      return {...state, getRelationshipManagerList:{errorMsg:action.payload,apiStatus:-1
      } }

  
  case 'GETTING_PERSONAL_INFO_DATA':
    return {...state, profileData:{apiStatus:1} }

  case 'GOT_PERSONAL_INFO_DATA':
    return {...state, profileData:{data:action.payload.data,apiStatus:0} }
  case 'COMPONENT_LOADER_PERSONAL_INFO':
    return {...state, loaderEditProfile:action.payload }

  case 'FAILED_PERSONAL_INFO_DATA':
      return {...state, profileData:{errorMsg:action.payload,apiStatus:-1
      } }


  case 'GETTING_GENERAL_INFO_DATA':
    return {...state, generalInfoData:{apiStatus:1} }

  case 'GOT_GENERAL_INFO_DATA':
    return {...state, generalInfoData:{data:action.payload && action.payload.data,apiStatus:0}, notification:action.payload && action.payload.message }

  case 'COMPONENT_LOADER_GENERAL_INFO':
    return {...state,loaderEditProfile:action.payload}
  
  case 'FAILED_GENERAL_INFO_DATA':
    return {...state, generalInfoData:{errorMsg:action.payload,apiStatus:-1 }, notification:action.payload && action.payload.message, errorNotification: true }

  case 'HIDE_NOTIFICATION':
    return {...state, notification:action.payload, clientFinderImport: null, errorNotification: false ,invitationMail:null,editTableColumn:null,investorSetFamilyHead:{apiStatus:1},investorMergeClients:{}}


  case 'GETTING_ADDITIONAL_INFO_DATA':
    return {...state, additionalInfoData:{apiStatus:1} }

  case 'GOT_ADDITIONAL_INFO_DATA':
    return {...state, additionalInfoData:{data:action.payload && action.payload.data,apiStatus:0}, notification:action.payload && action.payload.message }

  case 'FAILED_ADDITIONAL_INFO_DATA':
      return {...state, additionalInfoData:{errorMsg:action.payload,apiStatus:-1 }, notification:action.payload && action.payload.message, errorNotification: true  }

  case 'GETTING_BILLING_INFO_DATA':
    return {...state, billingInfoData:{apiStatus:1} }

  case 'GOT_BILLING_INFO_DATA':
    return {...state, billingInfoData:{data:action.payload.data,apiStatus:0}, notification:action.payload }

  case 'FAILED_BILLING_INFO_DATA':

    return {...state, billingInfoData:{data:intialState.billingInfoData.data,errorMsg:action.payload,apiStatus:-1 }, notification:action.payload && action.payload, errorNotification: true   }

  case 'GOT_CLIENTS_OF_FAMILY':
    return {...state, clientsOfFamily:action.payload }

  case 'COMPONENT_LOADER_CLIENTS_OF_FAMILYHEAD':
    return {...state, loaderClientsOfFamily:action.payload }

  case 'FAILED_CLIENTS_OF_FAMILY':
      return {...state, clientsOfFamily:action.payload}


  case 'UNGROUP_FAMILY_MEMBERS_SUCCESSFULLY':
    return {...state, unGroupFamilyMembers:action.payload, notification:action.payload, getClientsDetails:true, errorMsg: false}

  case 'FAILED_UNGROUP_FAMILY_MEMBERS':
      return {...state, errorMsg: true, notification:action.payload}

  case 'UPDATED_FAMILY_HEAD_SUCCESSFULLY':
    return {...state, updatedFamily:action.payload, getClientsDetails: true, errorMsg: false  }

  case 'UPDATED_FAMILY_HEAD_FAILED':
      return {...state, notification:'Something Went Wrong', errorMsg: true}

  case 'CLEAR_NOTIFICATION':
    return {...state, notification: action.payload ,investorSetFamilyHead:{data:action.payload}}
  
  case 'GOT_SEND_INVITATION_DATA':
    return {...state,invitationMail:action.payload}

  case 'FAILED_SEND_INVITATION_DATA':
    return {...state,invitationMail:action.payload}
  
  case 'GET_CLIENTS_DATA': 
  return {...state, getClientsDetails: action.payload}

  case 'GOT_EDIT_TABLE_DATA':
    return {...state,editTableColumn:action.payload}
  
  case 'FAILED_EDIT_TABLE_DATA': 
  return {...state, editTableColumn: action.payload}

  case 'LOCAL_STORAGE' : 
      return {...state, missingData : action.payload}
  
  case 'COMMON_LOCAL_STORAGE' :
      return {...state, commonLocalStorage:action.payload}
      
  case 'WHATS_NEW_POP_STORAGE' :
    return {...state, whatsNewPopStorage:action.payload}

  case 'SUCCESSFULLY_UPDATED_NOTIFICATION' :
      return {...state,notification:action.payload,errorMsg:false}  

  case 'FAILED_UPDATION_NOTIFICATION':
      return{...state, notification: action.payload,errorMsg:true} 

  case 'INVESTOR_SUCCESSFULLY_CREATED':
    return{...state, createInvestor:action.payload, notification:action.payload, errorMsg:false, nextStep: true }

  case 'INVESTOR_NOT_CREATED':
    return {...state, notification:action.payload,errorMsg:true, nextStep: false } 
   
  case 'GETTING_CLIENT_INFORMATION_DATA':
    return{...state, getClientInformation:action.payload}

  case 'GOT_CLIENT_INFORMATION_DATA':
    return {...state, getClientInformation:action.payload }

  case 'FAILED_TO_GET_CLIENT_INFORMATION_DATA':
    return {...state, getClientInformation:action.payload }

  case 'DOWNLOAD_PORTFOLIO_PPT':
  case 'FAILED_TO_DOWNLOAD_PORTFOLIO_PPT':
    return { ...state, portfolioPPT: action.payload }   

  case 'COMPONENT_LOADER_GET_CLIENT_INFORMATION':
    return{...state, componentLoaderGetClientInformation:action.payload}

  case 'COMPONENT_LOADER_CREATE_USER':
    return{...state, componentLoaderCreateUser:action.payload}  
    
  case 'CURRENT_TABS_OF_FORM':
    return {...state, currentTabOfForm:action.payload} 

  case 'EXISTING_USER_WITH_PAN':
    return{...state, clientWithPan: action.payload, notificationData:'Client Already Exists',errorMsg:true }
    
  case 'CLIENT_EXISTS_WITH_SAME_PAN':
    return{...state ,clientWithPan: action.payload, notificationData:'Client Already Exists' , errorMsg:true}  
  case 'HIDE_NOTIFICATION_MSG':
    return{...state, notificationData:action.payload }        


  case 'GOT_AUTHORIZATION_TOKEN_FOR_CHAT': 
    return{...state, authorizationToken: action.payload}
  case 'FAILED_AUTHORIZATION_TOKEN_FOR_CHAT': 
    return {...state, authorizationToken: action.payload}   

  case 'SET_REPORTING_SUCCESSFULLY':
    return {...state, setReporting:{data:action.payload,apiStatus:0}, notification:'Successfully Applied', hideTable: true  }

  case 'FAILED_TO_REPORTING':
      return {...state, setReporting:{errorMsg:action.payload,apiStatus:-1}, notification:'Something Went Wrong ', errorMsg: true }

  case 'ENABLED_DISABLED_TXNS_SUCCESSFULLY':
    return {...state, enabledDisabledTxns:action.payload, enabledDisabledTxnsFlag:true}

  case 'FAILED_TO_ENABLE_DISABLE_TXNS':
      return {...state, enabledDisabledTxns:action.payload, enabledDisabledTxnsFlag: false}

  case 'SET_SELECTED_TAB': 
    return {...state, setSelectedTab: action.payload} 

  case 'COMPONENT_LOADER_GET_CLIENT_FINDER_DATA':
    return {...state, loaderClientFinder:action.payload } 

  case 'GOT_CLIENT_FINDER_DATA':
    return {...state, clientFinderData:action.payload}

  case 'FAILED_TO_GET_CLIENT_FINDER_DATA':
    return {...state, clientFinderData:action.payload}

  case 'SUCCESS_CLIENT_MODEL_MAPPING':
    return {...state, modelMappingClient:{data:action.payload,apiStatus:0} }

  case 'FAILED_CLIENT_MODEL_MAPPING':
    return {...state, modelMappingClient:{errorMsg:action.payload,apiStatus:-1} }
  
  case 'SUCCESS_MORNING_STAR':
    return{...state, morningStarAws:action.payload, notification : action.payload, notificationError : false }

  case 'FAILED_MORNING_STAR':
    return {...state, morningStarAws:action.payload, notification : action.payload, notificationError : true } 

  case 'SAVE_CLIENT_FINDER_VARIABLES':
    return { ...state, clientFinderVariables: action.payload }

  case 'CLIENT_FINDER_API_SUCCESS':
    return { ...state, clientFinderAPISuccess: action.payload }

  case 'NOT_SHOW_LOADER' :
      return {...state, notShowLoader:action.payload}

  case 'GOT_CLIENTS_LIST':
    return { ...state, clientList: action.payload }
  case 'FAILED_TO_GET_CLIENTS_LIST':
    return { ...state, clientList: null }
  case "CLIENT_FINDER_UPLOAD_LOADER":
    return{...state, clientFiderLoader : action.payload }
  case 'CLIENT_FINDER_UPLOAD_SUCCESS':
    return{...state, clientFinderImport:action.payload, notification : action.payload }

  case 'CLIENT_FINDER_UPLOAD_FAILED':
    return{...state, clientFinderImport:action.payload, notification : action.payload }

  case 'UPDATE_CLIENT_FEATURES_FOR_ALL':
    return { ...state, updateClientFeaturesForAllRoles: action.payload }

  case 'FAILED_CLIENT_FEATURES_FOR_ALL':
    return { ...state, updateClientFeaturesForAllRoles: action.payload }
  case 'GOT_CLIENTS_LIST_WITH_SEARCH_HISTORY':
    return { ...state, clientListWithSearchHistory: action.payload }
  case 'FAILED_CLIENTS_LIST_WITH_SEARCH_HISTORY':
    return { ...state, clientListWithSearchHistory: action.payload }
  case 'SUCCESSFULLY_UPLOAD_CLIENT_HISTORY':
    return { ...state, uploadClientHistory: action.payload }
  case 'FAILED_TO_UPLOAD_CLIENT_HISTORY':
    return { ...state, uploadClientHistory: action.payload }
  case 'SUCCESSFULLY_UPLOAD_RISK_IMPORT':
  case 'FAILED_TO_UPLOAD_RISK_IMPORT':
    return { ...state, riskFileImport: action.payload }

  case 'GET_TOPICS':
    return { ...state, getTopics: action.payload }
  case 'FAILED_GET_TOPICS':
    return { ...state, getTopics: action.payload }
  case 'GET_NEW_UPDATES':
    return { ...state, newUpdates: action.payload }
  case 'MERGE_NEW_UPDATES_DATA':
    return { ...state, mergeNewUpdates: action.payload }
  case 'FAILED_GET_NEW_UPDATES':
    return { ...state, newUpdates: action.payload }
  case 'WHATS_NEW_LOADER':
    return { ...state, whatsNewLoader: action.payload }

  case 'GET_TRASMISSION_HISTORY':
  case 'FAILED_TRASMISSION_HISTORY':
    return { ...state, transmissionHistory: action.payload }

  case 'GET_DELETE_TRASMISSION_HISTORY':
  case 'FAILED_DELETE_TRASMISSION_HISTORY':
    return { ...state, deleteTransmissionHistory: action.payload }
  default:
      new Error('No action type matched in Login reducer')
  }


 return state
}
