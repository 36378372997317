var intialState = {
    nseSettings : {},
    nseSettingList : {},
}

export default function accountSettings(state =intialState,action){
    switch(action.type){
        case 'GETTING_NSE_SETTINGS_DATA' :
            return {...state, nseSettings : {update: 'pending'} }
        case 'GOT_NSE_SETTINGS_DATA' :
            return {...state, nseSettings : {successMsg:action.payload, update:true}}
        case 'COMPONENT_LOADER_NSE_SETTINGS':
            return{...state , nseLoader :action.payload}
        case 'FAILED_NSE_SETTINGS_DATA' : 
            return {...state, nseSettings : {errorMsg:action.payload, update:false}}

        case 'GOT_NSE_SETTING_LIST_DATA' :
            return {...state, nseSettingList : action.payload}
        case 'FAILED_NSE_SETTING_LIST_DATA' : 
            return {...state, nseSettingList : {errorMsg : action.payload}}

        case 'GOT_USER_WITH_IFA_LIST' :
            return {...state, userWithIFAlist : action.payload}
        case 'GOT_SHADOW_USER_LIST' :
            return {...state, shadowUserList : action.payload}
        case 'COMPONENT_LOADER_GOT_IFA':
            return {...state , loaderIfa :action.payload}
        case 'FAILED_USER_WITH_IFA_LIST' : 
            return {...state, userWithIFAlist : {errorMsg : action.payload}}

        case 'SENT_ACTIVATION_MAIL':
            return { ...state, activationMail:action.payload, notification:'Mail Sent Successfully', notificationType: 'HIDE_NOTIFICATION'}
        case 'FAILED_ACTIVATION_MAIL':
            return {...state,failedActivationMail:action.payload, notificationError:action.payload}
        case 'COMPONENT_LOADER_SEND_ACTIVATION_MAIL':
            return {...state,loaderSendActivationMail:action.payload}

        case 'SUSPEND_OR_REACTIVATE_USERS_LOGIN':
        case 'FAILED_SUSPEND_OR_REACTIVATE_USERS_LOGIN':
            return { ...state, suspendOrReactivateUsersLogin: action.payload}

        case 'SET_USER_SETTING_DATA' :
            return {...state, userAccountData:action.payload}

        case 'SET_FLAG_TO_STOP_API' :
            return {...state, stopApiCall:null}

        case 'GOT_REPORTING_TO_LIST':
            return { ...state, reportingToList:action.payload}
        case 'FAILED_AREPORTING_TO_LIST':
            return {...state, failedReportingToList : {errorMsg : action.payload}}

        case 'GOT_REPORTING_TO_HIERARCHY_LIST':
            return { ...state, reportingToHierarchyList:action.payload}
        case 'COMPONENT_LOADER_GET_REPORTING_HIERARCHY':
            return {...state,loaderCheckUser:action.payload}
        case 'FAILED_REPORTING_TO_HIERARCHY_LIST':
            return {...state, failedReportingToHierarchyList : {errorMsg : action.payload}}

        case 'GOT_AVAILABLE_USER':
            return { ...state, availableUser:action.payload}
        case 'COMPONENT_LOADER_CHECK_AVAILABILITY':
            return {...state,loaderCheckUser :action.payload}
        case 'FAILED_AVAILABLE_USER':
            return {...state, failedavailableUser : {errorMsg : action.payload}}

        case 'CREATE_USER_SUCCESSFULLY':
            return { ...state, createUser:action.payload && action.payload.result, createUserSuccessfully: true, notification: action.payload &&  action.payload.message , notificationType: 'HIDE_NOTIFICATION' }
        case 'COMPONENT_LOADER_CREATE_USER':
            return{...state, loaderCheckUser:action.payload}
        case 'FAILED_CREATE_USER':
            return {...state, createUserSuccessfully: false, notificationError:action.payload, notificationType: 'HIDE_NOTIFICATION'}

        case 'GOT_SELECTED_USER_INFO':
            return { ...state, slectedUserInfo:action.payload, stopApiCall: true}
         case 'COMPONENT_LOADER_GET_USER_EDIT':
            return {...state,loaderCheckUser:action.payload}
        case 'FAILED_SELECTED_USER_INFO':
            return {...state, slectedUserInfo : {errorMsg : action.payload}}

        case 'DELETE_USER_SUCCESSFULLY':
            return { ...state, deleteUserInfo:action.payload,stopApiCall: true, notification:'Deleted Successfully', notificationType: 'HIDE_NOTIFICATION'}
        case 'DELETE_USER_FAILED':
            return {...state, deleteUserInfo : {errorMsg : action.payload},notificationType:'HIDE_NOTIFICATION',notificationError : action.payload }

        case 'HIDE_NOTIFICATION': 
            return {...state, notification: null, notificationError: null,nseSettings:{errorMsg:null}}

        case 'GOT_ARN_PREFENCES':
            return { ...state, ARNpreferenceList:action.payload}

        case 'COMPONENT_LOADER_ARN_PREFERENCES':
            return { ...state, loaderArn:action.payload}

         case 'GOT_BILLING_DETAILS':
            return { ...state, billingDetails:action.payload[0]||{}}

        case 'GOT_MAILBACK_DETAILS':
            return { ...state, arnMailbackDetails:action.payload[0]||{}}
        
        case 'SET_ARN_PREFERENCES':
            return { ...state, arnData: { ...state.arnData, openForm:2 } }
        case 'COMPONENT_LOADER_SET_ARN_PREFERENCES':
            return { ...state, loaderArn:action.payload,notification:action.notification}
        
        case 'SET_MAILBACK_DETAILS':
            return { ...state, arnData: { ...state.arnData, openForm:3 },notification:action.notification}
        case 'COMPONENT_LOADER_MAIL_BACK':
            return {...state,loaderMailback:action.payload}

         case 'SET_BILLING_DETAILS':
            return { ...state, arnData: { ...state.arnData, openForm:null },notification:action.notification}
        case 'COMPONENT_LOADER_BILLING':
            return {...state,loaderBilling:action.payload}

        case 'SET_ARN_DATA':
            let arnData = { ...state.arnData, ...action.payload }
            return { ...state, arnData }

        case 'GOT_AMC_ARN_MAPPING' :
            return {...state, amcArnList : action.payload}
        case 'FAILED_AMC_ARN_MAPPING' : 
            return {...state, amcArnList : action.payload }

        case 'COMPONENT_LOADER_AMC_ARN':
            return {...state,loaderAmcArn:action.payload}

        case 'CREATED_AMC_ARN_MAPPING' :
            return {...state, successAmcArn : action.payload, notification : action.payload, errorNotification : false }
        case 'UNSUCCESS_AMC_ARN_MAPPING' : 
            return {...state, successAmcArn : {errorMsg : action.payload}, notification : action.payload, errorNotification : true}

        case 'COMPONENT_LOADER_IFA_DETAILS':
            return {...state , loaderPortalSetting :action.payload}

        case 'GOT_IFA_DETAILS_DATA' :
            return {...state, ifaDetailsData : action.payload}
        case 'FAILED_IFA_DETAILS_DATA' : 
            return {...state, ifaDetailsData : action.payload }


        case 'NPS_AGENT_REGISTRATION_LOADER':
            return {...state , loaderForGetNpsDetails :action.payload}    
        case 'GOT_NPS_REGISTRATION_DETAILS' :
            return {...state, getNpsDetails : action.payload}
        case 'FAILED_NPS_REGISTRATION_DETAILS' : 
            return {...state, getNpsDetails : action.payload }

        case 'SUCCESS_NPS_REGISTRATION' :
            return {...state, npsRegistration : action.payload}
        case 'FAILED_NPS_REGISTRATION' : 
            return {...state, npsRegistration : action.payload }


        case 'GOT_IFA_REGISTRATION' :
            return {...state, ifaRegistration : action.payload}
        case 'FAILED_IFA_REGISTRATION' : 
            return {...state, ifaRegistration : action.payload }

        case 'GOT_RTA_PASSWORD' :
            return {...state, rtaZipPassword : action.payload}
        case 'FAILED_RTA_PASSWORD' : 
            return {...state, rtaZipPassword : action.payload }

        case 'GOT_LEVEL_INFO' :
            return {...state, levelInfo : action.payload}
        case 'FAILED_LEVEL_INFO' : 
            return {...state, levelInfo : action.payload }

        case 'UPDATE_LEVEL_INFO' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'UNSUCCESS_LEVEL_INFO' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'REMOVED_ZIP_PASSWORD' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'NOT_REMOVED_ZIP_PASSWORD' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'UPDATE_ZIP_PASSWORD' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'FAILED_ZIP_PASSWORD' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'UPDATE_IFA_DETAILS' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'FAILED_IFA_DETAILS' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'UPDATE_IFA_REGISTRATION' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'UNSUCCESS_IFA_REGISTRATION' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'COMPONENT_LOADER_TXN_SETTINGS':
            return {...state , loaderTxnsSetting :action.payload}

        case 'GOT_TXN_SETTING_DETAILS' :
            return {...state, txnsSettingData:action.payload}
        case 'FAILED_TXN_SETTING_DETAILS' : 
            return {...state, txnsSettingData:action.payload}

        case 'UPDATE_TXN_SETTING' :
            return {...state, notification:action.payload, errorNotification: false}
        case 'UNSUCCESS_TXN_SETTING' : 
            return {...state, notification:action.payload, errorNotification: true}

        case 'GOT_SMTP_DETAILS_DATA' :
            return {...state, smtpDetailsData:action.payload}
        case 'COMPONENT_LOADER_SMTP_DETAILS' : 
            return {...state, loaderSmtp:action.payload}
        case 'FAILED_SMTP_DETAILS_DATA' :
            return {...state, smtpDetailsData:action.payload}

        case 'GOT_UPDATE_SMTP_DETAILS_DATA' :
            return {...state, updatedSmtpData:action.payload , notificationData:action.payload}
        case 'COMPONENT_LOADER_UPDATE_SMTP_DETAILS' : 
            return {...state, loaderUpdateSmtp:action.payload}
        case 'FAILED_UPDATE_SMTP_DETAILS_DATA' :
            return {...state, updatedSmtpData:action.payload, notificationData:action.payload}

        case 'GOT_PREFERENCES_DATA' :
            return {...state, ifaPreferenceData : action.payload}
        case 'FAILED_PREFERENCES_DATA' :
            return {...state, ifaPreferenceData : action.payload }

        case 'CREATE_SUCCESS_PREFERENCE' :
            return {...state, updatedPreferencesData : action.payload, notification : action.payload, errorNotification : false }
        case 'FAILED_PREFERENCE' : 
            return {...state, updatedPreferencesData : {errorMsg : action.payload}, notification : action.payload, errorNotification : true}

        case 'CLEAR_NOTIFICATION' :
            return {...state, notificationData:action.payload, npsRegistration : action.payload}

        case 'GOT_AUTH_URL_SUCCESSFULLY':
            return {...state, googleAuthUrl: action.payload && action.payload.result , notificationData:action.payload }

        case 'FAILED_TO_GET_AUTH_URL':
            return {...state, googleAuthUrl: null , notificationData:action.payload }

        case 'UPDATED_GAPI_CREDENTIALS_SUCCESSFULLY':
            return {...state, notificationData: action.payload, updatedGApiCredentials:true }
        case 'FAILED_TO_UPDATE_GAPI_CREDENTIALS':
            return {...state, notificationData: action.payload }
        case "UPDATED_GAPI_CREDENTIALS" :
            return { ...state, updatedGApiCredentials: action.payload }

        case 'MAPPING_DELETED_SUCCESSFULLY' :
            return {...state, resetMappingAmcArn : action.payload, notification : action.payload, errorNotification : false }
        case 'FAILED_TO_DELETE_MAPPING' : 
            return {...state, resetMappingAmcArn : action.payload, notification : action.payload, errorNotification : true}

        case 'COMPONENT_LOADER_TABLE_TOP' : 
            return {...state, amcArnCommonLoader: action.payload}

        case 'COMPONENT_LOADER_ALERT_PREFERENCES':
            return { ...state, loaderAlertPreferences: action.payload }
        case 'GOT_ALERT_PREFERENCES_SUCCESSFULLY': 
            return { ...state, alertPreferences: action.payload && action.payload.result }
        case 'FAILED_TO_GET_ALERT_PREFERENCES': 
            return { ...state, alertPreferences: [] }
        case 'CREATED_ALERT_PREFERENCES_SUCCESSFULLY':
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_CREATE_ALERT_PREFERENCES':
            return { ...state, notificationData: action.payload }

        case 'DELETE_LOGO_IMAGE_SUCCESSFULLY':
            return { ...state, notification: action.payload, errorNotification : false }
        case 'FAILED_LOGO_IMAGE_SUCCESSFULLY':
            return { ...state, notification: action.payload, errorNotification : true }
        case 'COMPONENT_LOADER_DELETE_LOGO_IMAGE':
            return { ...state, loaderDeleteLogoImage: action.payload }

        case 'CREATE_SUCCESS_MOBILE_NOTIFICATION':
            return { ...state, mobileNotification: action.payload, notification : action.payload, errorNotification : false }
        case 'FAILED_MOBILE_NOTIFICATION':
            return { ...state, mobileNotification: action.payload, notification : action.payload, errorNotification : true }
            
        case 'COMPONENT_LOADER_PAYOUT_MASTER':
            return { ...state, loaderPayoutMaster: action.payload }

        case 'GOT_PAYOUT_RATE_MASTER_DATA':
            return { ...state, payoutRateMasterData: action.payload }
        case 'FAILED_PAYOUT_RATE_MASTER_DATA':
            return { ...state, payoutRateMasterData: action.payload }

        case 'SUCCESS_PAYOUT_RATE_MASTER_ACTION':
            return { ...state, notificationData: action.payload, errorNotification : false }
        case 'FAILED_PAYOUT_RATE_MASTER_ACTION':
            return { ...state, notificationData: action.payload, errorNotification : true }

        case 'UNASSIGNED_DEFAULT_RM':
            return { ...state, UnassignedDefaultRM:action.payload,notification: action.payload,notificationType: 'HIDE_NOTIFICATION',stopApiCall:true}
        case 'FAILED_UNASSIGNED_DEFAULT_RM':
            return {...state, UnassignedDefaultRM : {errorMsg : action.payload},notificationError: action.payload}
        
        case 'SUCCESS_PDF_CONFIGURATION_PREVIEW' :
            return {...state, pdfConfigPreview : action.payload, notification : action.payload.message, errorNotification : false }
        case 'FAILED_PDF_CONFIGURATION_PREVIEW' : 
            return {...state, pdfConfigPreview : action.payload, notification : action.payload, errorNotification : true}

        case 'UPDATE_SMS_DETAILS':
            return {...state, smsDetailsUpdatedData:action.payload}   
        case 'FAILED_UPDATE_SMS_DETAILS':
            return {...state, smsDetailsUpdatedeData:action.payload}     
        
        case 'GOT_SMS_DETAILS_DATA' :
            return {...state, smsDetailsData:action.payload}
        case 'FAILED_SMS_DETAILS_DATA' :
            return {...state, smsDetailsData:action.payload} 
            
        case 'GET_PMS_COMPANY_LIST' :
            return {...state, pmsCompanyList:action.payload} 
        case 'FAILED_GET_PMS_COMPANY_LIST' :
            return {...state, pmsCompanyList:action.payload} 
            
        case 'GET_PMS_CREDENTIALS_DATA' :
            return {...state, pmsCredentialsData:action.payload} 
        case 'FAILED_GET_PMS_CREDENTIALS_DATA' :
            return {...state, pmsCredentialsData:action.payload} 

        case 'GET_INTEGRATORS_DATA' :
            return {...state, integratorsData:action.payload} 
        case 'FAILED_INTEGRATORS_DATA' :
            return {...state, integratorsData:action.payload}    

        case 'GET_INTEGRATOR_DETAILED_DATA' :
            return {...state, integratorDetailedData:action.payload} 
        case 'FAILED_INTEGRATOR_DETAILED_DATA' :
            return {...state, integratorDetailedData:action.payload}
        case 'GET_IFA_INTEGRATORS_USERNAME':
            return {...state, ifaIntegratorsUsername:action.payload}
        case 'FAILED_IFA_INTEGRATORS_USERNAME' :
            return {...state, ifaIntegratorsUsername:action.payload}  
        case 'UPDATE_IFA_CREDENTIALS_DATA' :
            return {...state, ifaIntegratorsNotification:action.payload}  
        case 'FAILED_IFA_CREDENTIALS_DATA' :
            return {...state, ifaIntegratorsNotification:action.payload}  
        case 'UPDATE_PMS_CREDENTIALS_DATA' :
            return {...state, pmsSettings : action.payload}
        case 'FAILED_UPDATE_PMS_CREDENTIALS_DATA' :
            return {...state, pmsSettings : action.payload}   
         
        case 'GET_PRODUCTS' :
            return {...state, investwellProducts: action.payload}     
        case 'FAILED_PRODUCTS' :
            return {...state, investwellProducts: action.payload}            
                
        case 'GET_ROLES' :
            return {...state,getRolesData:action.payload}
        case 'FAILED_TO_GET_ROLES' :
            return {...state,getRolesData:action.payload}
            case 'COMPONENT_LOADER_GET_ROLES':
                return{...state , getRolesLoader :action.payload}

        case 'CREATE_AND_UPDATE_ROLES_NOTIFICATION':
            return {...state,createAndUpdateRoles:action.payload}
        case 'COMPONENT_LOADER_FOR_TABLE':
            return{...state, getTableLoader:action.payload}
        case "BULK_UPLOAD_USER_DATA_NOTIFICATION":
            return{...state, getUserUploadData : action.payload }
        case "BULK_UPLOAD_USER_DATA_LOADER":
            return{...state, userUploadLoader : action.payload }

        case 'GOT_CLIENTS_RM_WISE':
        case 'FAILED_CLIENTS_RM_WISE':
            return { ...state, clientsRmWise: action.payload }
        case 'GOT_INCENTIVE_DATA':
        case 'FAILED_INCENTIVE_DATA':
            return { ...state, brokerageIncentiveData: action.payload }
        case 'CREATE_UPDATE_OR_DELETE_INCENTIVE':
        case 'FAILED_CREATE_UPDATE_OR_DELETE_INCENTIVE':
            return { ...state, notificationData: action.payload }

        case 'GOT_ESCALATION_LIST_DATA':
        case 'FAILED_ESCALATION_LIST_DATA':
            return { ...state, escalationListData: action.payload}

        case 'CREATE_AND_UPDATE_ESCALATION_LIST_RESPONSE':
        case 'FAILED_TO_CREATE_AND_UPDATE_ESCALATION_LIST_RESPONSE':
            return { ...state, createAndUpdateEscalationResponse: action.payload}
        case 'CREATE_AND_UPDATE_ESCALATION_LOADER':
            return { ...state, createAndUpdateEscalationLoader: action.payload}


        case 'GET_ALL_CLIENT_TAGS':
        case 'FAILED_GET_ALL_CLIENT_TAGS':
            return { ...state, clientTagsData: action.payload }

        case 'CLIENT_TAG_ACTION_DATA':
        case 'FAILED_CLIENT_TAG_ACTION_DATA':
            return { ...state, clientTagActionResponse: action.payload }
        default:

            new Error('No action type matched in reducer')
    }

    return state;
}