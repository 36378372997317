    var intialState = {ifaSignUpData:{}, ifaRegistrationData:{}, arnRegistrationData:{}, zipPassData:{}, levelData:{}, userTableData : {}, ifaInfoData : {}, arnInfoData : {}
, rtaPassInfoData : {}, levelInitialData : {}, arnFormInfo:{} };

export default function adminDashboard(state=intialState,action){
	switch(action.type){	
        case 'GETTING_IFASIGNUP_DATA':
            return {...state, ifaSignUpData:{savingSignUp:true} }
        case 'GOT_IFASIGNUP_DATA':
                return {...state, ifaSignUpData:{data:action.payload,apiStatus:0, savedSignUp : true} }
        case 'COMPONENT_LOADER_IFA_FORM':
            return{...state,loaderIfa:action.payload ,notification:action.notification,activeTab:action.activeTab}
        case 'FAILED_IFASIGNUP_DATA':
            return {...state, ifaSignUpData:{errorMsg:action.payload,apiStatus:-1} }
        
        case 'GETTING_IFAREGISTRATION_DATA' :
            return {...state, ifaRegistrationData:{apiStatus:1, savingIFA:true} }
        case 'GOT_IFAREGISTRATION_DATA' :
            return {...state, ifaRegistrationData:{data:action.payload,apiStatus:0, savedIFA:true} }
        case 'FAILED_IFAREGISTRATION_DATA':
            return {...state,  ifaRegistrationData:{errorMsg:action.payload,apiStatus:-1}}

        case 'GETTING_ARN_DATA' :
            return { ...state, arnRegistrationData:{apiStatus:1} }
        case 'GOT_ARN_DATA' : 
            return { ...state, arnRegistrationData:{data:action.payload, apiStatus:0} }
        case 'FAILED_ARN_DATA' :
            return { ...state, arnRegistrationData:{errorMsg:action.payload, apiStatus:-1} }
        
        case 'GETTING_ZIPPASSWORD_DATA' :
            return { ...state, zipPassData:{apiStatus:1} }
        case 'GOT_ZIPPASSWORD_DATA' :
            return { ...state, zipPassData:{data:action.payload, apiStatus:0} }
        case 'FAILED_ZIPPASSWORD_DATA' : 
            return { ...state, zipPassData:{errorMsg:action.payload, apiStatus:-1} }

        case 'GETTING_LEVELINFO_DATA' : 
            return { ...state, levelData:{apiStatus:1} }
        case 'GOT_LEVELINFO_DATA' :
            return { ...state, levelData:{data:action.payload, apiStatus:0} }
        case 'FAILED_LEVELINFO_DATA' : 
            return { ...state, levelData:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_USERS_LIST_DATA':
            return {...state, getUserListForTxns:{} }
        case 'GOT_USERS_LIST_DATA':
                return {...state, getUserListForTxns:{data:action.payload,apiStatus:0} }
        case 'FAILED_USERS_LIST_DATA':
            return {...state, getUserListForTxns:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_TXN_WITH_UID':
            return {...state, getTxnsWithUid:{} }
        case 'GOT_TXN_WITH_UID':
                return {...state, getTxnsWithUid:{data:action.payload,apiStatus:0} }
        case 'COMPONENT_LOADER_TXN':
            return{...state,loaderDuplicateTxn:action.payload}
        case 'FAILED_TXN_WITH_UID':
            return {...state, getTxnsWithUid:{errorMsg:action.payload,apiStatus:-1} }

        case 'GETTING_IMPORT_REJECTON':
            return {...state,gettingImportRejection:true }
        case 'GOT_IMPORT_REJECTON':
                return {...state, importRejectionList:action.payload,gotImportRejection:true }
        case 'FAILED_IMPORT_REJECTON':
            return {...state, importRejectionList:{errorMsg:action.payload} }

        case 'RESOLVING_REJECTION':
            return {...state,importRejectionList:action.payload }
        case 'COMPONENT_LOADER_MIS_MATCH':
            return{...state,loaderMisMatch:action.payload}

        case 'GETTING_USERLIST_DATA' :
            return {...state, userTableData: {} }
        case 'COMPONENT_LOADER_USERLIST_DATA':
            return{...state ,loaderIfa :action.payload}
        case 'COMPONENT_LOADER_ADMIN_REPORT':
            return{...state ,loaderIfa :action.payload}
        case 'GOT_USERLIST_DATA' :
            return {...state, userTableData : {data : action.payload, apiStatus:0} }
        case 'FAILED_USERLIST_DATA' :
            return {...state, userTableData : {errorMsg: action.payload, apiStatus: -1}}
        
        case 'GETTING_IFAINFO_DATA' :
            return {...state, ifaInfoData: {} }
        case 'GOT_IFAINFO_DATA' :
            return {...state, ifaInfoData : {data : action.payload, apiStatus:0} }
        case 'FAILED_IFAINFO_DATA' :
            return {...state, ifaInfoData : {errorMsg: action.payload, apiStatus: -1}}

        case 'GETTING_ARNINFO_DATA' :
            return {...state, arnInfoData: {} }
        case 'GOT_ARNINFO_DATA' :
            return {...state, arnInfoData : {data : action.payload, apiStatus:0} }
        case 'FAILED_ARNINFO_DATA' :
            return {...state, arnInfoData : {errorMsg: action.payload, apiStatus: -1}}
        case 'GOT_ALL_ARN_DATA':
            return { ...state, allArnInfoData: action.payload }
        
        case 'GETTING_RTAINFO_DATA' :
            return {...state, rtaPassInfoData: {} }
        case 'GOT_RTAINFO_DATA' :
            return {...state, rtaPassInfoData : {data : action.payload, apiStatus:0} }
        case 'FAILED_RTAINFO_DATA' :
            return {...state, rtaPassInfoData : {errorMsg: action.payload, apiStatus: -1}}
        
        case 'GETTING_LEVELINITINFO_DATA' :
            return {...state, levelInitialData: {} }
        case 'GOT_LEVELINITINFO_DATA' :
            return {...state, levelInitialData : {data : action.payload, apiStatus:0} }
        case 'FAILED_LEVELINITINFO_DATA' :
            return {...state, levelInitialData : {errorMsg: action.payload, apiStatus: -1}}
        case 'TAB_STATE_MANAGEMENT':
            return {...state ,tabState: action.payload}
        case 'CLEAR_NOTIFICATION':
            return {...state , notification : null, notificationData:action.payload}

        case 'GOT_DUPLICARE_FOLIO_TXNS' :
            return {...state, duplicateFolioData : action.payload}
        case 'COMPONENT_LOADER_ASSETDATALIST' :
            return { ...state, loaderForDuplicateTxnsData : action.payload}
        case 'FAILED_DUPLICARE_FOLIO_TXNS' :
            return {...state, duplicateFolioData : action.payload }

        case 'GOT_LOGO_UPLOAD' :
            return {...state, logoUploadData : action.payload ,notification:true}
        case 'FAILED_LOGO_UPLOAD' :
            return { ...state, logoUploadData : action.payload,notification:false}
        case 'COMPONENT_LOADER_UPLOAD_LOGO' :
            return { ...state, loaderIfa : action.payload}

        case 'PROXY_DOWNLOAD' :
            return {...state, proxyDownloadData : action.payload}
        case 'FAILED_PROXY_DOWNLOAD' :
            return { ...state, proxyDownloadData : action.payload}
        
        case 'REMOVE_ZIPPASSWORD' :
            return { ...state, removeZipPassData:{data:action.payload, apiStatus:0} }
        case 'FAILED_REMOVE_ZIPPASSWORD' :
            return { ...state, removeZipPassData:{errorMsg:action.payload, apiStatus:-1} }

        case 'COMPONENT_LOADER_ARN_FORM':
            return {...state, deleteArnLoader:action.payload} 

        case 'ARNID_SECCESSFULLY_DELETED':
            return {...state, deleteArn:action.payload}
            
        case 'FAILED_TO_DELETE_ARNID':
            return {...state, deleteArn:action.payload}     

        case 'GOT_SMTP_DETAILS_DATA' :
            return {...state, smtpDetailsData:action.payload}
        case 'COMPONENT_LOADER_SMTP_DETAILS' : 
            return {...state, loaderSmtp:action.payload}
        case 'FAILED_SMTP_DETAILS_DATA' :
            return {...state, smtpDetailsData:action.payload}

        case 'GOT_UPDATE_SMTP_DETAILS_DATA' :
            return {...state, updatedSmtpData:action.payload , notificationData:action.payload}
        case 'COMPONENT_LOADER_UPDATE_SMTP_DETAILS' : 
            return {...state, loaderUpdateSmtp:action.payload}
        case 'FAILED_UPDATE_SMTP_DETAILS_DATA' :
            return {...state, updatedSmtpData:action.payload, notificationData:action.payload}

        case 'GOT_BROKERAGE_REJECTIONS_DATA':
                return {...state, brokerageRejection:action.payload }
        case 'FAILED_TO_GET_BROKERAGE_REJECTIONS':
            return {...state, brokerageRejection:action.payload }
        case 'COMPONENT_LOADER_BROKERAGE_REJECTIONS':
                return {...state, brokerageRejectionLoader:action.payload }

        case 'GOT_MISMATCH_SCHEMES':
                return {...state, mismatchSchemesData:action.payload }
        case 'FAILED_MISMATCH_SCHEMES':
            return {...state, mismatchSchemesData:action.payload }
        case 'COMPONENT_LOADER_MISMATCH_SCHEME':
                return {...state, mismatchSchemeLoader:action.payload }

        case 'SUCCESS_MAP_MISMATCH_SCHEMES':
                return {...state, mapMismatchSchemesData:action.payload }
        case 'FAILED_MAP_MISMATCH_SCHEMES':
            return {...state, mapMismatchSchemesData:action.payload }
        case 'COMPONENT_LOADER_MAPMISMATCH_SCHEME':
                return {...state, mapMismatchSchemeLoader:action.payload }

        case 'GOT_IFA_PREFERENCES_DATA' :
            return {...state, ifaPreferencesData : action.payload }
        case 'FAILED_IFA_PREFERENCES_DATA' :
            return {...state, ifaPreferencesData : {errorMsg: action.payload}}

        case 'COMPONENT_LOADER_PREFERENCES' : 
            return {...state, ifaPreferencesLoader:action.payload}
        case 'CREATE_SUCCESS_PREFERENCE' :
            return {...state, updatedPreferencesData:action.payload , notificationData:action.payload}
        case 'FAILED_PREFERENCE' :
            return {...state, updatedPreferencesData:action.payload, notificationData:action.payload}

        case 'GOT_IIN_DETAILS_BY_BID' :
            return {...state, ifaNotificationData : action.payload }
        case 'FAILED_TO_GET_IIN_DETAILS_BY_BID' :
            return {...state, ifaNotificationData : action.payload }

            case 'GOT_UCC_DETAILS_BY_BID' :
                return {...state, ifaNotificationDataUcc : action.payload }
            case 'FAILED_UCC_DETAILS_BY_BID' :
                return {...state, ifaNotificationDataUcc : action.payload }

        case 'GOT_SHOW_NSE_FIRST_FETCH_DATA' :
            return {...state, showNseFirstFetchButton : action.payload }
        case 'FAILED_TO_GET_SHOW_NSE_FIRST_FETCH_DATA' :
            return {...state, showNseFirstFetchButton : null }
            case 'GOT_SHOW_BSE_FIRST_FETCH_DATA' :
                return {...state, showBseFirstFetchButton : action.payload }
            case 'FAILED_SHOW_BSE_FIRST_FETCH_DATA' :
                return {...state, showBseFirstFetchButton : action.payload }

        case 'CLOSE_NOTIFICATION':
            return {...state, ifaNotificationData: null }

        case 'DELETE_LOGO_IMAGE_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, errorNotification : false }
        case 'FAILED_LOGO_IMAGE_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, errorNotification : true }
        case 'COMPONENT_LOADER_DELETE_LOGO_IMAGE':
            return { ...state, loaderDeleteLogoImage: action.payload }
        
        case 'COMPONENT_LOADER_VERIFY_CREDENTIALS' :

            return {...state, verifyCredentialsLoader : action.payload}

        case 'SUCCESSFULLY_VERIFY_CREDENTIALS':
           
            return { ...state, verifyCredentialsData : action.payload, ifaNotificationData: action.payload, errorNotification : false }

        case 'FAILED_VERIFY_CREDENTIALS':
      
        return { ...state, verifyCredentialsData : action.payload, ifaNotificationData: action.payload, errorNotification : true }

		case 'SUCCESSFULL_LEVELINFO' :
            return { ...state, notification:action.payload }
        case 'FAILED_LEVELINFO' : 
            return { ...state, notification:action.payload }

        case 'DELETE_FTP_IMAGE_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, errorNotification : false }
        case 'FAILED_DELETE_FTP_IMAGE':
            return { ...state, notificationData: action.payload, errorNotification : true }
        case 'COMPONENT_LOADER_DELETE_IMAGE':
            return { ...state, loaderDeleteImage: action.payload }

        case 'SUCCESS_CHECK_CUSTOM_URL' :
            return { ...state, customUrlData: action.payload }
        case 'FAILED_CHECK_CUSTOM_URL' :
            return { ...state, customUrlData: action.payload }
        case 'GOT_BID_FOR_IFA_ONBOARDING_DATA':
            return { ...state, bidForNewIfa: action.payload } 
        case 'FAILED_TO_GET_BID_FOR_IFA_ONBOARDING_DATA':
            return { ...state, bidForNewIfa: action.payload }       

        case 'GOT_AVAILABLE_ARN':
            return { ...state, availableArn: action.payload}
        case 'FAILED_AVAILABLE_ARN':
            return { ...state, availableArn: action.payload}   
        case 'GET_ADMIN_FEATURE_LIST':
        case 'FAILED_TO_GET_ADMIN_FEATURE_LIST':
            return { ...state, adminFeatureList: action.payload }      
        default:
      		new Error('No action type matched in reducer')
	}
	return state;
}