var intialState = {
}

export default function accountSettings(state =intialState,action){
    switch(action.type){
        
        case "COMPONENT_LOADER_FACTSHEET_DATA": 
            return { ...state, loaderFactSheetData: action.payload }

        case "GOT_FACTSHEET_DATA": 
            return { ...state, factsheetData: action.payload }

        case "FAILED_TO_GET_FACTSHEET_DATA":
            return { ...state, factsheetData: action.payload }



        case "COMPONENT_LOADER_NAV_GRAPH": 
            return { ...state, loaderNavGraph: action.payload }

        case "GOT_NAV_GRAPH_DATA": 
            return { ...state, navGraphData: action.payload }

        case "FAILED_TO_GET_NAV_GRAPH_DATA":
            return { ...state, navGraphData: action.payload }



        case "COMPONENT_LOADER_FACTSHEET_GRAPH": 
            return { ...state, loaderFactSheetGraph: action.payload }

        case "GOT_FACTSHEET_GRAPH_DATA": 
            return { ...state, factsheetGraphData: action.payload }

        case "FAILED_TO_GET_FACTSHEET_GRAPH_DATA":
            return { ...state, factsheetGraphData: action.payload }


        case "COMPONENT_LOADER_ROLLING_RETURN": 
            return { ...state, loaderRollingReturnGraph: action.payload }

        case "GOT_ROLLING_RETURN_DATA": 
            return { ...state, rollingReturnData: action.payload }

        case "FAILED_ROLLING_RETURN_DATA":
            return { ...state, rollingReturnData: action.payload }

        case "COMPONENT_LOADER_SCHEME_PEERS": 
            return { ...state, loaderSchemePeers: action.payload }

        case "GOT_SCHEME_PEERS_DATA": 
            return { ...state, schemePareersData: action.payload }

        case "FAILED_SCHEME_PEERS_DATA":
            return { ...state, schemePareersData: action.payload }

            ///////////////////////txnnnnnnn/////////////////////


        case "COMPONENT_LOADER_MFU_ORDER":
            return { ...state, mfuOrderLoader: action.payload }

        case "GOT_MFU_ORDER":
            return { ...state, mfuOrderData: action.payload }

        case "FAILED_MFU_ORDER":
            return { ...state, mfuOrderData: action.payload }

        case "PLACE_MFU_ORDER_SUCCESSFULLY":
            return { ...state, placeMFUOrder: action.payload }

        case "FAILED_TO_PLACE_MFU_ORDER":
            return { ...state, placeMFUOrder: action.payload }

        case "COMPONENT_NAV_DATE_LOADER":
            return { ...state, navDateLoader: action.payload }

        case "NAV_UPDATE_DATE":
            return { ...state, navUpdateDate: action.payload }

        case "FAILED_NAV_UPDATE_DATE":
            return { ...state, navUpdateDate: action.payload }

            
        default:
            new Error('No action type matched in reducer')
    }

    return state;
}