import axios from 'axios'

export const GetLevelUsersRepo = (data) => {
  return axios({
    method: 'GET',
    url:'/api/auth/getLevelUsers',
    params:data
  })
}
export const GetLevelUsersRepoSB=(data)=>{
  return axios({
    method:'GET',
    url:'/api/auth/getLevelUsers',
    params: data
  })
}

export const GetLevelUsersRepoRM=(data)=>{
  return axios({
    method:'GET',
    url:'/api/auth/getLevelUsers',
    params: data
  })
}

export const getLevelsRepo = (data) => {
  return axios({
    method: 'GET',
    url:'/api/user/getLevels',
    params:data
  })
}

export const getUserRepo = () => {
  return axios({
    method: 'GET',
    url:'/api/auth/getLoggedInUser'
  })
}

export const getUserInfoRepo = (data) => {
  return axios({
    method: 'GET',
    url:'/api/auth/getUser',
    params:data
  })
}

export const GetFamilyHeadDataRepo=(data)=> {
  return axios({
    method: 'GET',
    url:'/api/auth/getLevelUsersFiltered',
    params: data
  })
}

export const GetRelationshipManagerDataRepo=(data)=> {
  return axios({
    method: 'GET',
    url:'/api/auth/getLevelUsersFiltered',
    params: data
  })
}

export const GetSubBrokerDataRepo=(data)=> {
  return axios({
    method: 'GET',
    url:'/api/auth/getLevelUsersFiltered',
    params: data
  })
}

export const GetServiceRMDataRepo=(data)=> {
  return axios({
    method: 'GET',
    url:'/api/auth/getLevelUsersFiltered',
    params: data
  })
}

export const GetAllowedFeatureListRepo=(userType)=> {
  return axios({
    method: 'GET',
    url:`/api/${userType}/acl/getAllowedFeatureList`,
    params: {}
  })
}

export const GetFeaturePermissionsRepo=(data)=> {
  return axios({
    method: 'GET',
    url: '/api/broker/acl/getFeaturePermissions',
    params: data
  })
}

export const UpdatePermissionsRepo =(data)=> {
  return axios({
    method: 'POST',
    url: '/api/broker/acl/updatePermissions',
    data: data
  })
}

export const SSOLoginRepo =(data)=> {
  return axios({
    method: 'POST',
    url: '/api/auth/SSOLogin',
    data: data
  })
}

export const changePasswordFromInsideLoginRepo = (data) =>{
	return axios({
		method: 'POST',
	  url:'/api/auth/changePasswordInsideLogin',
	  data:data
	})
}
