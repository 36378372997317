var initialState = {}

export default function riskProfilerClient( state = initialState, action){
    switch( action.type ){

        case 'GOT_RISK_PROFILER_FORM_DATA' :
            return { ...state, riskProfilerFormData: action.payload }
            
        case 'FAILED_TO_GET_RISK_PROFILER_FORM_DATA' :
            return { ...state, riskProfilerFormData: [] }

        case 'COMPONENT_LOADER_RISK_PROFILER_CLIENT':
        	return { ...state, loaderRiskProfilerForm: action.payload }

        case 'GOT_MY_RISK_PROFILE':
            return { ...state, myRiskProfile: action.payload }

        case 'FAILED_TO_GET_MY_RISK_PROFILE':
            return { ...state, myRiskProfile: {} }

        case 'SUBMITTED_PROFILER_FORM_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, myRiskProfile: action.payload && action.payload.result }

        case 'FAILED_TO_SUBMIT_PROFILER_FORM':
            return { ...state, notificationData: action.payload }


        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }

        default:
        new Error('No action type matched in reducer')
    }
    return state
}