let initialState={
	tableLoader: {},
	listLoader: {}
}

export default function componentLoaders(state={},action){
	switch (action.type) {

	  	case 'COMPONENT_LOADER_TABLE':
	    	return { ...state, tableLoader: action.payload }

	  	case 'COMPONENT_LOADER_LIST':
	  		let listLoader = state.listLoader || {}
	  		listLoader[action.key] = action.value
	    	return { ...state, listLoader }

	    case 'LOADER_SEND_REPORT_ON_WHATSAPP':
            return { ...state, whatsAppLoader: action.payload }
            

	  	default:
	    	new Error('No action type matched')

	}
	return state;
}
