var intialState = {}

export default function bseInvestOnline(state = intialState, action) {
  switch (action.type) {

    case 'GOT_BSE_NEW_INVESTMENT_DATA':
      return { ...state, bseNewInvestment: action.payload }
    case 'COMPONENT_LOADER_BSE_NEW_INVESTMENT':
      return {...state,loaderNewInvestment:action.payload}
    case 'FAILED_BSE_NEW_INVESTMENT_DATA':
      return { ...state, bseNewInvestment: action.payload }


    case 'GOT_UCC_LIST':
      return { ...state, uccList: action.payload }
    case 'FAILED_TO_GET_UCC':
      return { ...state, uccList: action.payload }

    case 'GOT_BANK_LIST':
      return { ...state, uccBankListData: action.payload }
    case 'COMPONENT_LOADER_BANK_LIST':
      return {...state,loaderBankList:action.payload}
    case 'FAILED_BANK_LIST':
      return { ...state, uccBankListData: action.payload }

    case 'GOT_UCC_FOLIOS_LIST':
      return { ...state, uccFolioListData: action.payload }
    case 'COMPONENT_LOADER_UCC_FOLIOS_LIST':
      return {...state,loaderUccFolioList:action.payload}
    case 'FAILED_UCC_FOLIOS_LIST':
      return { ...state, uccFolioListData: action.payload }

    case 'GOT_PLACE_ORDER_SIP_DATA':
      return { ...state, placeOrderSipData: action.payload,notificationData:action.payload }
    case 'COMPONENT_LOADER_PLACE_ORDER_SIP':
      return {...state,loaderPlaceSipOrder:action.payload}
    case 'FAILED_PLACE_ORDER_SIP_DATA':
      return { ...state, placeOrderSipData: action.payload,notificationData:action.payload }

    case 'GOT_PLACE_ORDER_PURCHASE_DATA':
      return { ...state, placeOrderPurchaseData: action.payload ,notificationData:action.payload}
    case 'COMPONENT_LOADER_PLACE_ORDER_PURCHASE':
      return {...state,loaderPurchaseOrder:action.payload}
    case 'FAILED_PLACE_ORDER_PURCHASE_DATA':
      return { ...state, placeOrderPurchaseData: action.payload,notificationData:action.payload }

    case 'GOT_UCC_MANDATE_DATA':
      return { ...state, uccMandateData: action.payload }
    case 'COMPONENT_LOADER_UCC_MANDATE':
      return {...state,loaderUccMandate:action.payload}
    case 'FAILED_UCC_MANDATE_DATA':
      return { ...state, uccMandateData: action.payload }

    case 'GOT_SCHEMES_UNDER_FUND_DATA':
      return { ...state, schemesFundData: action.payload }
    case 'COMPONENT_LOADER_SCHEMES_UNDER_FUND':
      return {...state,loaderSchemesFund:action.payload}
    case 'FAILED_SCHEMES_UNDER_FUND_DATA':
      return { ...state, schemesFundData: action.payload }

    case 'GOT_BSE_PAYMENT_DATA':
      return { ...state, bsePaymentData: action.payload}
    case 'COMPONENT_LOADER_BSE_PAYMENT':
      return {...state,loaderBsePayment:action.payload}
    case 'FAILED_BSE_PAYMENT_DATA':
      return { ...state, bsePaymentData: action.payload }

    case 'CLEAR_NOTIFICATION':
      return { ...state, notificationData: action.payload }

    case 'BSE_PAYMENT_DATE':
      return { ...state, paymentDate: action.payload }

    case 'GOT_SWITCH_ORDER_DATA':
      return { ...state, switchOrderData: action.payload,notificationData:action.payload, isPopupClosed: true}
    case 'FAILED_SWITCH_ORDER':
      return { ...state, switchOrderData: action.payload,notificationData:action.payload }
    case "COMPONENT_LOADER_PLACE_ORDER_SWITCH":
      return {...state,loaderPlaceOrder:action.payload}


    case 'GOT_REDEMPTION_ORDER_DATA':
      return { ...state, switchOrderData: action.payload,notificationData:action.payload, isPopupClosed: true}
    case 'FAILED_REDEMPTION_ORDER':
      return { ...state, switchOrderData: action.payload,notificationData:action.payload }
    case 'COMPONENT_LOADER_PLACE_ORDER_REDEMPTION':
      return {...state,loaderPlaceOrder:action.payload}


    case 'GOT_SWP_ORDER_DATA':
      return { ...state, swpOrderData: action.payload,notificationData:action.payload, isPopupClosed: true}
    case 'FAILED_SWP_ORDER':
      return { ...state, swpOrderData: action.payload,notificationData:action.payload }
    case 'COMPONENT_LOADER_PLACE_ORDER_SWP':
      return {...state,loaderPlaceOrder:action.payload}

    case 'GOT_STP_ORDER_DATA':
      return { ...state, stpOrderData: action.payload,notificationData:action.payload, isPopupClosed: true}
    case 'FAILED_STP_ORDER':
      return { ...state, stpOrderData: action.payload,notificationData:action.payload }
    case 'COMPONENT_LOADER_PLACE_ORDER_STP':
      return {...state,loaderPlaceOrder:action.payload}

    case 'POPUP_CLOSED':
      return { ...state, isPopupClosed: action.payload }

    case 'RESET_UCC_MANDATE_LIST':
      return { ...state, resetUccMandateList: action.payload }
    case 'ALL_PLACE_ORDER_DETAILS':
      return {...state, allPlaceHolderData:action.payload}

    case 'CHOOSING_INVESTING_BSE_PROFILE':
      return { ...state, choosenProfile: action.payload }
    default:
      new Error('No action type matched in reducer')
  }
  return state
}

