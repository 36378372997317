export default function Filters(state = {selectedGroupBy :{},filters:{} },action){
	switch(action.type){
		case "SET_APPLICANT_UID" :
				return {...state,filters:{...state.filters, applicantUIds:action.payload }  };
		case "SET_START_DATE" :
				return {...state,filters:{...state.filters, startDate:action.payload } }
		case "SET_END_DATE" :
				return {...state,filters:{...state.filters, endDate:action.payload } }
		case "SET_GROUP_BY" :
				return {...state,selectedGroupBy:action.payload}
		case "SET_CURRENT_VIEW" :
				return {...state,pageName:action.payload}
		case "SET_GROUP_ON" :
				return {...state,groupOn:action.payload}

		case "CLEAR_FLAG" :
				return {...state,clearFlag:action.payload}

		default:
      new Error('No action type matched in Filter reducer')

	}
	return state;	
}