var intialState = {
    folioLookupData:{},
    folioDetailsData:{}
};
    
export default function folioLookupClient(state=intialState,action){
switch (action.type) {

  case 'GOT_FOLIO_LOOKUP_DATA':
    return {...state, folioLookupData:action.payload,apiStatus:0} 
  case 'COMPONENT_LOADER_FOLIO':
    return {...state,loaderFolio:action.payload}

  case 'FAILED_FOLIO_LOOKUP_DATA':
      return {...state, folioLookupData:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'GOT_FOLIO_DETAILS_DATA':
    return {...state, folioDetailsData:{data:action.payload,apiStatus:0} }

  case 'FAILED_FOLIO_DETAILS_DATA':
      return {...state, folioDetailsData:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'ADD_REMARKS_SUCCESSFULLY':
    return {...state, remarksStatus: action.payload, isReload: true, notification:action.payload }

  case 'REMARKS_FAILED':
      return {...state, remarksStatus: action.payload, isReload: true, notification:action.payload } 

  case 'HIDE_NOTIFICATION': 
    return {...state,  notification: action.payload }
    default:
      new Error('No action type matched in reducer')
  }
 
 return state
}
