export default function investOnlineReducer(state={},action){

	switch(action.type){
		case "GOT_VIDEO_KYC_STATUS_DATA":
			return {...state, videoKYCStatusData: action.payload}
		case "FAILED_TO_GET_VIDEO_KYC_STATUS_DATA":
			return {...state, videoKYCStatusData: action.payload}		

		case "ADD_BULK_ORDER_FILTERS":
			return { ...state, bulkOrderFilters: action.payload }

		case "COMPONENT_LOADER_FUNDS_LIST":
			return { ...state, loaderFundsList: action.payload }
		case "GOT_FUNDS_LIST":
			return { ...state, fundsList: action.payload }
		case "FAILED_TO_GET_FUNDS_LIST":
			return { ...state, fundsList: [] }

		case "COMPONENT_LOADER_FUND_SCHEMES":
			return { ...state, loaderFundSchemes: action.payload }
		case "GOT_FUND_SCHEMES":
			return { ...state, fundSchemes: action.payload }
		case "FAILED_TO_GET_FUND_SCHEMES":
			return { ...state, fundSchemes: [] }

		case "COMPONENT_LOADER_TARGET_SCHEMES":
			return { ...state, loaderTargetSchemes: action.payload }
		case "GOT_TARGET_SCHEMES":
			return { ...state, targetSchemes: action.payload }
		case "FAILED_TO_GET_TARGET_SCHEMES":
			return { ...state, targetSchemes: [] }

		case 'COMPONENT_LOADER_VALID_INVESTMENT_TYPES':
			return { ...state, loaderValidInvestmentTypes: action.payload }	
		case "GOT_VALID_INVESTMENT_TYPE":
			return { ...state, validInvestmentTypes: action.payload }
		case "FAILED_TO_GET_VALID_INVESTMENT_TYPE":
			return { ...state, validInvestmentTypes: action.payload }

		case 'COMPONENT_LOADER_DETAILED_SCHEMES':
			return { ...state, loaderDetailedSchemes: action.payload }	
		case "GOT_DETAILED_SCHEME":
			return { ...state, detailedScheme: action.payload }
		case "FAILED_TO_GET_DETAILED_SCHEME":
			return { ...state, detailedScheme: action.payload, notificationData: action.payload }

		case "PLACED_ORDER_SUCCESSFULLY":
			return { ...state, notificationData: action.payload }
		case "FAILED_TO_PLACE_ORDER":
			return { ...state, notificationData: action.payload }

		case "GOT_BULK_ORDERS_SUCCESSFULLY":
			return { ...state, bulkOrdersData: action.payload }
		case "FAILED_TO_GET_BULK_ORDERS":
			return { ...state, bulkOrdersData: action.payload }


		case "CLOSE_NOTIFICATION":
			return { ...state, notificationData: action.payload }
			
		case "GOT_CART_ORDERS_COUNT":
			return {...state, cartOrders: action.payload}
		case "FAILED_TO_GET_CART_ORDERS_COUNT":
			return {...state, cartOrders: action.payload}	

		case "COMPONENT_LOADER_GET_CLIENT":
			return { ...state, loaderGetClients: action.payload }
		case "GOT_CLIENTS_USING_NAME_SUCESSFULLY":
			return { ...state, clientsList: action.payload }
		case "FAILED_TO_GET_CLIENTS_USING_NAME":
			return { ...state, clientsList: null }

		case "GOT_NSE_LIST_SUCESSFULLY":
			return { ...state, nseList: action.payload }
		case "FAILED_TO_GET_NSE_LIST":
			return { ...state, nseList: null }

		case "GOT_UCC_LIST_SUCESSFULLY":
			return { ...state, uccList: action.payload }
		case "FAILED_TO_GET_UCC_LIST":
			return { ...state, uccList: null }

		case "GOT_CAN_LIST_SUCESSFULLY":
			return { ...state, canList: action.payload }
		case "FAILED_TO_GET_CAN_LIST":
			return { ...state, canList: null }

		case "COMPONENT_LOADER_TRANSFER_HOLDING":
			return { ...state, loaderTransferHolding: action.payload }
		case "GOT_TRANSFER_HOLDING_UNITS":
			return { ...state, notificationData: action.payload, transferHoldingResult: action.payload && action.payload.result }
		case "FAILED_TO_GET_TRANSFER_HOLDING_UNITS":
			return { ...state, notificationData: action.payload, transferHoldingResult: null }

		case "ADD_TRANSFER_HOLDING_OPTIONS":
			return { ...state, transferHoldingOptions: action.payload }

		case "GOT_NSE_CLIENTS_USING_IIN_OR_NAME":
			return { ...state, nseClientsUsingIINorName: action.payload }
		case "FAILED_TO_GET_NSE_CLIENTS_USING_IIN_OR_NAME":
			return { ...state, nseClientsUsingIINorName: null }

		case "GOT_SIP_REPORT_NSE":
			return { ...state, sipReportNSE: action.payload }
		case "FAILED_TO_GET_SIP_REPORT_NSE":
			return { ...state, sipReportNSE: null }

		case "CEASE_SIP_NSE_SUCESSFULLY":
			return { ...state, notificationData: action.payload }
		case "FAILED_CEASE_SIP_NSE":
			return { ...state, notificationData: action.payload }

		case "TOPUP_SIP_NSE_SUCESSFULLY":
			return { ...state, notificationData: action.payload }
		case "FAILED_TOPUP_SIP_NSE":
			return { ...state, notificationData: action.payload }

		case "PAUSE_SIP_NSE_SUCESSFULLY":
			return { ...state, notificationData: action.payload }
		case "FAILED_PAUSE_SIP_NSE":
			return { ...state, notificationData: action.payload }

		case "ADD_CEASE_SIP_FILTERS":
			return { ...state, ceaseSipFilters: action.payload }

		case 'GOT_NSE_ORDERS_IN_REAL_TIME':
      		return {...state,nseOrdersInRealTime:action.payload} 
    	case 'FAILED_NSE_ORDERS_IN_REAL_TIME':
			return {...state,notificationData:action.payload}
		case 'GOT_BSE_ORDERS_IN_REAL_TIME':
			return{...state, realTimeBseOrderData:action.payload}
		case 'FAILED_BSE_ORDERS_IN_REAL_TIME':
		return{...state, notificationData:action.payload}

		case 'GOT_KYC_COMMENTS_DATA':
		case 'FAILED_KYC_COMMENTS_DATA':
			return { ...state, notificationData:action.payload }

	}
	return state
}