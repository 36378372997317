export const FUNDALOCATION = [
  {label:'snapshot',value:1},
  {label:'portfolioSummary',value:2},
  {label:'return',value:3},
  {label:'holdings',value:4},
  {label:'netWorth',value:5}
]
export const REPORTS = [
  "Folio Query","Dividend","Capital Gain","MF Tally","FD","Insurance"
]

export const F_RETURN = [
	 {label:"Scheme",value:0,groupBy:'schid'},
	 {label:"Investor",value:8,groupBy:'appid'},
	 {label:"Folio",value:9,groupBy:'folioid'},
	 {label:"Sub Category",value:12,groupBy:'objectiveid'}
]

export const F_SUMMARY = [
	 {label:"Scheme",value:0},
	 {label:"Investor",value:8},
	 {label:"Folio",value:9},

]

export const ASSET= [
	 /*{label:"None",value:0},*/
	 {label:"Scheme",value:0},
     {label:"Micro Asset Class",value:1},
	 /*{label:"Fund / Company",value:2},*/
	 {label:"Fund",value:3},
	 /*{label:"Exit Load",value:4},*/
	 /*{label:"Sector",value:5},*/
	 /*{label:"Term",value:6},*/
	 /*{label:"Scheme Groups",value:7},*/
	 {label:"Investor",value:8},
	 /*{label:"Folio",value:9},*/
	 {label:"Product Type",value:10},
	 {label:"Asset",value:11}

]

export const CAP_GAINS_UNREALIZED = [
	{label:"Scheme",value:0},
	{label:"Investor",value:8},
	{label:"Folio",value:9},
]

export const CAP_GAINS_REALIZED = [
	{label:"Scheme",value:0},
	{label:"Investor",value:8},
	{label:"Folio",value:9},
]


export const FILE_TYPE = [
	 // {label:"All",value:null},
	 {label:"AUM",value:"AUM"},
     {label:"Transaction",value:"txn"},
	 {label:"FolioMaster",value:"folioMaster"},
	 {label:"SIP",value:"SIP"},
	 {label:"Brokerage",value:"brokerage"},
	 {label:"EUIN Invalid",value:"invalidEuin"},
]

export const LEFT_SIDEBAR = {
	'level11':"Asset Allocation",
	'level12':"Portfolio summary",
	'level13':"Portfolio returns",
	'level21':"Import History",
	'level22':"Top Schemes",
	'level23':"Investor Lookup",
	'level31':"Dashboard",
}

export const REGISTRAR = [
	 // {label:"All",value:null},
	 {label:"Cams",value:"C"},
     {label:"Karvy",value:"K"}
	 // {label:"Sundaram",value:"S"}
]

export const LEFT_SIDEBAR_MAIN_TAB = {
	'level1':"Overview",
	'level2':"Utilities",
	'level3':"Dashboard",
}

export const THEMES = [
	 {key:'Green Blue Theme',value:1, themeClass : 'greenBlueThemes', lightColorShade:'#00a0fc', darkColorShade:'#0cc5a2'},
	 {key:'Sky Pink Theme',value:2, themeClass : 'skyPinkThemes', lightColorShade:'#bc95c6', darkColorShade:'#2db8c6'},
	 {key:'Orange Shade Theme',value:3, themeClass : 'orangeShadeThemes', lightColorShade:'#f78d5b', darkColorShade:'#f27d73'},
	 {key:'Pink Orange Theme',value:4, themeClass : 'pinkOrgThemes', lightColorShade:'#dd9c87', darkColorShade:'#d27ea4'},
	 {key:'Sky Theme',value:5, themeClass : 'skyThemes', lightColorShade:'#7299e0', darkColorShade:'#40c2d8'},
	 {key:'Green Theme',value:6, themeClass : 'greenThemes', lightColorShade:'#0f9e82', darkColorShade:'#69dec3'},
	 {key:'Pink Theme',value:7, themeClass : 'pinkThemes', lightColorShade:'#9e81df', darkColorShade:'#d06dd8'},
	 {key:'Orange Theme',value:8, themeClass : 'orangeThemes', lightColorShade:'#f27d72', darkColorShade:'#f99057'},
	 {key:'Dark Blue Theme',value:9, themeClass : 'darkBlueThemes', lightColorShade:'#2dc8d3', darkColorShade:'#4c7de4'},
	 {key:'Dark Pink Theme',value:10, themeClass : 'darkPinkThemes', lightColorShade:'#a25f7b', darkColorShade:'#8a1d49'},
	 {key:'Custom Theme',value:11, themeClass : 'customThemes', lightColorShade:'#2dc8d3', darkColorShade:'#4c7de4'}
]



export const TOP_SCHEMES_PERIOD = [
	 {label:"1 Day",value:"1Day"},
     {label:"7 Days",value:"7Day"},
	 {label:"15 Days",value:"15Day"},
	 {label:"30 Days",value:"30Day"},
	 {label:"3 Month",value:"3Month"}
]

export const WATCHLIST_PERIOD = [
	{label:"1 Month",value:"30Day"},
	{label:"3 Month",value:"3Month"},
	{label:"6 Month",value:"6Month"}
]
                   
export const LOADER_TEXT = [
	{key: 'Best time to invest is now', value:1},
	{key: 'Crunching some numbers', value:2},
	{key: 'Breath in... Breath out...', value:3},
	{key: 'Ensuring Everything Works Perfectly', value:4},
	{key: 'What good shall I do this day!!', value:5},
	{key: "You're here! The day just got better", value:6},
	{key: 'Alright world, time to take you on!', value:7},
]

export const ROLLING_PERIOD = [
	{label:'1 Yr', value: 1},
	{label:'2 Yr', value: 2},
	{label:'3 Yr', value: 3},
	{label:'5 Yr', value: 5},
	{label:'7 Yr', value: 7},
	{label:'10 Yr', value:10}
]
export const SIP_FREQUENCY= [
	{label : 'DAILY', value : 'd'},
	{label : 'WEEKLY', value : 'w'},
	{label : 'MONTHLY', value : 'm'},
	{label : 'QUARTERLY', value : 'q'},
	{label : 'YEARLY', value : 'y'},
]

export const TASK_VIEW = [
	 {label : "All Tasks", value : "allTasks" },
	{label : "My Open Tasks", value : "myOpenTasks" },
	{label : "All Open Tasks", value : "allOpenTasks" },
	{label : "Today's Tasks", value : "todayTasks" },
	{label : "Overdue Tasks", value : "overdueTasks" },
	{label :  "All Closed Tasks" , value : "allClosedTasks" }
]
export const PRIORITY = [
	{label : 'Low', value : 'low'},
	{label : 'Medium', value : 'medium'},
	{label : 'High', value : 'high'}
]
export const ASSIGNTO = [
	{label : 'Myself', value : 1 },
	{label : 'Someone else', value : 2 }
]
export const TASKTYPE = [
	{label : 'External', value : 1},
	{label : 'Internal', value : 2 },
]

export const STATUS = [
	{label:"Open",value:1},
	{label:"Close",value:2},
]

export const COMPLIANCE_OPTIONS = [
	{label : "Aadhaar Link Missing" , value :"aadhaar"},
	{label : "Email ID Missing", value : "email" },
	{label : "EUIN Remediation", value : "euin" },
	{label : "Folios Without Nominee", value : "nominee" },
	{label : "IIN Communication Mismatch", value : "nse" },
	{label : "KYC Missing", value : "kyc" },
	{label : "PAN Missing", value : "pan" },
	{label : "UCC Communication mismatch", value : "bse" },
]
export const STATUS_FILE_IMPORT = [
	 {label:"Processing",value:"processing"},
     {label:"Processed",value:"processed"},
     {label:"Hold",value:"hold"},
     {label:"Failed",value:"failed"}
]
export const EMAIL_STATUS = [
	{label:"Processing",value:"processing"},
	{label:"Processed",value:"processed"},
	{label:"Queued",value:"queued"},
	{label:"Failed",value:"failed"}
]
export const FILTER_FILE_IMPORT = [
	 {label:"File Type",value:"fileType"},
	 {label:"Status",value:"status"},
     {label:"RTA",value:"rta"},
]          
export const ALLOCATION_CONST= [
	 {levelName:"Fund",levelNo:1001},
	 {levelName:"Scheme",levelNo:1002},
     {levelName:"Sub Category",levelNo:1004},
	 {levelName:"Investor",levelNo:100},
	 {levelName:"Holding",levelNo:1006},
	 {levelName:"Sector",levelNo:1007},
	 {levelName:"Category",levelNo:1003},
	 {levelName:"Equity Cap",levelNo:1010},
]  
export const ALLOCATION_SHARE_BOND_CONST= [
	 {levelName:"Scrip",levelNo:1002},
     {levelName:"Asset Class",levelNo:1003},
	 {levelName:"Investor",levelNo:100}
]                    
export const F_RETURN_SHARE_AND_BOND = [
	 {label:"Scrip",value:0,groupBy:'schid'},
	 {label:"Investor",value:8,groupBy:'appid'},
]
export const FOLIO_SUMMARY_MUTUAL_FUND = [
	 {levelName:"Folio",levelNo:1005},
	 {levelName:"Scheme",levelNo:1002},
	 {levelName:"Investor",levelNo:100},
	 {levelName:"Category",levelNo:1004},
]
export const FOLIO_SUMMARY_SHARE_BOND = [
	 {levelName:"Scrip",levelNo:1002},
	 {levelName:"Investor",levelNo:100},
]
export const TOP_SCHEMES_INCLUDES = [
	{label:'Ratio',keyValue:'ratio',value:1},
	{label:'Indices',keyValue:'iwellCode',value:1},
	{label:'ETF',keyValue:'etf',value:1},
	{label:'Direct Schemes',keyValue:'dirPlan',value:2},
	{label:'Close-ended Schemes',keyValue:'closeEnded',value:1},
]

export const COMPARISON_PERIOD = [
	{label:'1 Year', value: '1Year'},
	{label:'2 Year', value: '2Year'},
	{label:'3 Year', value: '3Year'},
	{label:'5 Year', value: '5Year'},
	{label:'10 Year', value:'10Year'}
]

export const REDEMPTION_GROUP= [
	 {levelName:"Scheme",levelNo:1002},
	 {levelName:"Investor",levelNo:100},
	 {levelName:"Transaction",levelNo:1008},
] 
export const HOLDING_PERIOD= [
 {label:"All",value:null},
 {label:"Long Term",value:'longTerm'},
 {label:"Short Term",value:'shortTerm'},
 {label: "Upto 1 year", value:'uptoOneYear'},
 {label: "More than 1 year",value: 1},
 {label: "More than 2 year",value: 2},
 {label: "More than 3 year",value: 3},
 {label: "More than 4 year",value: 4},
 {label: "More than 5 year",value: 'moreThanFiveYears'}
]
export const CAS_ARN_OPTION= [
 {label:"Include",value:'0'},
 {label:"Exclude",value:'1'},
 {label:"Show Only",value:'2'},
]
export const DATA_SOURCE_OPTION = [
	{label:"All",value:'0'},
	{label:"Managed Data",value:'1'},
	{label:"Outside Data",value:'2'},
]
export const F_VALUATION = [
	 {label:"Folio",value:9,groupBy:'folioid'},
	 {label:"Scheme",value:0,groupBy:'schid'},
	 {label:"Investor",value:8,groupBy:'appid'},
	 {label:"Sub Category",value:12,groupBy:'objectiveid'}
]

export const SELECTED_FILTER = [
	{label:"CATEGORY",},
	{label:"SUB_CATEGORY"},
	{label:"FUNDS"},
	{label:"FOLIO"},
]
export const CLIENT_OPTION = [
	{label:"Include",value:'0'},
	{label:"Exclude",value:'-1'},
	{label:"Show Only",value:'1'},
   ]
   
export const SUMMARY_DETAILS = [
	 {label:"Summary",value:1003,groupBy:1003},
	 {label:"Detail",value:1008,groupBy:1008},
]
export const BETA_VERSION =[
	{levelName:"Beta Version", levelNo :'betaVersion'},
]
export const PORTFOLIO_VAL_DATA_SOURCE_OPTION = [
	{label:"All",value:'0'},
	{label:"Managed Data",value:'1'},
	{label:"Outside Data*",value:'2'},
]
export const QUICK_LINK_TABS = [
	{label:"Invest Online" , customClass:"investOnline"},
	{label:"Watch Your Investments Grow" , customClass:"investmentsGrow"},
	{label:"Plan Your Goals" , customClass:"goals"},
	{label:"Check Capital Gains For IT filing", customClass:"itFiling"}
]

export default FUNDALOCATION;
