import SessionData from '../../utils/sessionData'
var intialState = {dashboarTabledData:{},user:{},fileImport:{},topSchemes:{}, capGainsRealizedata: {}, capGainsUnRealizedata: {}, factSheetDetails: {}, factSheetGraph: {} };
    intialState.fileImport = {}
    intialState.topSchemes = {}
    intialState.users = {}
    intialState.sessionData = {}
    //intialState.sessionTimeOut = {}
export default function dashboard(state=intialState,action){
switch (action.type) {
  case 'GETTING_FUND_DASHBORAD_DATA':
      return {...state, dashboarTabledData:{gotFundAllocationTotal:false,gettingFundAllocationTotal:true}}

  case 'GETTING_DASHBORAD_AMT':
      return {...state, fundAllocationAmt:{gotFundAllocationTotal:false,gettingFundAllocationTotal:true}}

  case 'GOT_FUND_DASHBORAD_DATA':
    /*if(action.payload&&action.payload.summary){
        action.payload.data.unshift(action.payload.summary)
    }*/
    return {...state, dashboarTabledData:{result:action.payload,data:action.payload.data,apiStatus:0,apiNameId:action.apiNameId,gotFundAllocationTotal:false} }

  case 'COMPONENT_LOADER_F_SCHEME':
    return {...state, loaderFolio:action.payload}

  case 'FUND_DASHBORAD_DATA_FAILED':
      return {...state, dashboarTabledData:{errorMsg:action.payload,apiStatus:-1} }

 
  case 'GETTING_SOA_DATA':
      return {...state, SOAdata:{apiStatus:1} }

  case 'GOT_SOA_DATA':
    return {...state, SOAdata:{data:action.payload,apiStatus:0,apiNameId:action.apiNameId} }

  case 'COMPONENT_LOADER_SOA':
    return {...state, loaderSOA:action.payload}

  case 'SOA_DATA_FAILED':
      return {...state, SOAdata:{errorMsg:action.payload,apiStatus:-1} }
  

  case 'GOT_FUND_DASHBORAD_AMT':
    return {...state, fundAllocationAmt:{amountData:action.payload.data,gotFundAllocationTotal:true,gettingFundAllocationTotal:false} }

  case 'COMPONENT_LOADER_DASHBOARD_DATA':
    return{...state, loaderFolio:action.payload}
    
  case 'FUND_DASHBORAD_AMT_FAILED':
      return {...state, fundAllocationAmt:{errorMsg:action.payload,apiStatus:-1} }

  case 'GETTING_FUND_ASSET_WISE':
      return {...state, dashboarTabledData:{apiStatus:1} }

  case 'GOT_FUND_ASSET_WISE':
    return {...state, dashboarTabledData:{data:action.payload.data,apiStatus:0,apiNameId:0} }

  case 'FUND_ASSET_WISE_FAILED':
      return {...state, dashboarTabledData:{errorMsg:action.payload,apiStatus:-1} }

  case 'GETTING_DATA_FILE_IMPORT':
      return {...state, fileImport:{apiStatus:1} }

  case 'GOT_DATA_FILE_IMPORT':
    return {...state, fileImport:{data:action.payload,apiStatus:0} }

  
  case 'COMPONENT_LOADER_FILE_IMPORT':
    return {...state, loaderFileImport:action.payload }

  case 'DATA_FILE_IMPORT_FAILED':
      return {...state, fileImport:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'GETTING_DATA_TOP_SCHEMES':
      return {...state, topSchemes:{apiStatus:1} }

  case 'GOT_DATA_TOP_SCHEMES':
    return {...state, topSchemes:{data:action.payload,apiStatus:0} }
    
  case 'COMPONENT_LOADER_TOP_SCHEMES':
    return {...state, loaderTopSchemes:action.payload}

  case 'DATA_FILE_TOP_SCHEMES':
      return {...state, topSchemes:{errorMsg:action.payload,apiStatus:-1
      } }

    case 'CONTINUE_IN':
      return {...state, sessionData:{apiStatus:1} }

  case 'CONTINUE_SUCCESS':
  SessionData.setItem('user',action.payload)
    return {...state, sessionData:{data:action.payload,apiStatus:0} }

  case 'CONTINUE_FAILED':
      return {...state, sessionData:{errorMsg:action.payload,apiStatus:-1
      } }

case 'SESSION_TIMEOUT_FLAG':
      return {...state, sessionTimeOut:action.payload }


case 'GETTING_CAP_GAINS_REALIZED':
  return {...state, dashboarTabledData:{apiStatus:1} }

case 'GOT_CAP_GAINS_REALIZED_DATA':
  return {...state, dashboarTabledData:{result:action.payload,data:action.payload.data,apiStatus:0,apiNameId:action.apiNameId} }

case 'FAILED_CAP_GAINS_REALIZED':
  return {...state, dashboarTabledData:{errorMsg:action.payload,apiStatus:-1} }


case 'GETTING_CAP_GAINS_UNREALIZED':
  return {...state, dashboarTabledData:{apiStatus:1} }

case 'GOT_CAP_GAINS_UNREALIZED_DATA':
  return {...state, dashboarTabledData:{result:action.payload,data:action.payload.data,apiStatus:0,apiNameId:action.apiNameId} }

case 'FAILED_CAP_GAINS_UNREALIZED':
  return {...state, dashboarTabledData:{errorMsg:action.payload,apiStatus:-1} }


case 'GOT_FACTSHEET_DATA':
  return {...state, factSheetDetails:{result:action.payload,data:action.payload.data} }
case 'COMPONENT_LOADER_FACT_SHEET':
      return{...state,loaderPopUp:action.payload}
case 'FAILED_FACTSHEET_DATA':
  return {...state, factSheetDetails:{errorMsg:action.payload} }


case 'GOT_FACTSHEET_GRAPH_DATA':
  return {...state, factSheetGraph:{result:action.payload,data:action.payload.data} }

case 'FAILED_FACTSHEET_GRAPH_DATA':
  return {...state, factSheetGraph:{errorMsg:action.payload} }

case 'GOT_FOLIO_RETURN_DRILL_DOWN_DATA':
    return{...state,returnDrillDownData:action.payload }

case 'FAILED_FOLIO_RETURN_DRILL_DOWN_DATA':
    return {...state,returnDrillDownData:action.payload}

case 'GOT_FOLIO_RETURN_SHARE_BOND_DATA':
    return {...state,returnDrillDownData:action.payload}
case 'FAILED_FOLIO_RETURN_SHARE_BOND_DATA':
    return {...state,returnDrillDownData:action.payload}
case 'COMPONENT_LOADER_RETURN_SHARE_BOND_DATA':
    return {...state,loaderFolio:action.payload}

case 'GOT_FOLIO_SUMMARY_MUTUAL_FUND_DATA':
    return {...state,summaryMutualFundData:action.payload}
case 'FAILED_FOLIO_SUMMARY_MUTUAL_FUND_DATA':
    return {...state,summaryMutualFundData:action.payload}
case 'COMPONENT_LOADER_SUMMARY_MUTUAL_FUND_DATA':
    return {...state,loaderFolio:action.payload}

case 'GOT_FOLIO_SUMMARY_SHARE_BOND_DATA':
    return {...state,summaryShareBondData:action.payload}
case 'FAILED_FOLIO_SUMMARY_SHARE_BOND_DATA':
    return {...state,summaryShareBondData:action.payload}
case 'COMPONENT_LOADER_SUMMARY_SHARE_BOND_DATA':
    return {...state,loaderFolio:action.payload}

case 'HIDE_NOTIFICATION':
      return {...state, SOAdata:action.payload,notification:action.payload }

case 'GOT_ALLOCATION_ANALYSIS_DATA':
      return {...state, allocationAnalysisMFData:action.payload }
case 'FAILED_ALLOCATION_ANALYSIS_DATA':
      return {...state, allocationAnalysisMFData:action.payload }
case 'COMPONENT_LOADER_ALLOCATION_ANALYSIS_DATA':
      return {...state, loaderAllocation:action.payload }

case 'GOT_ALLOCATION_ANALYSIS_SHARE_BOND_DATA':
      return {...state, allocationShareAndBondData:action.payload }
case 'FAILED_ALLOCATION_ANALYSIS_SHARE_BOND_DATA':
      return {...state, allocationShareAndBondData:action.payload }
case 'COMPONENT_LOADER_ALLOCATION_ANALYSIS_SHARE_BOND_DATA':
      return {...state, loaderAllocation:action.payload }

case 'GOT_FOLIO_RETURN_FIXED_DEPOSIT_DATA':
      return {...state, returnFixedDeposit:action.payload }
case 'FAILED_FOLIO_RETURN_FIXED_DEPOSIT_DATA':
      return {...state, returnFixedDeposit:action.payload }
case 'COMPONENT_LOADER_RETURN_FIXED_DEPOSIT_DATA':
      return {...state, loaderFixedDeposit:action.payload }

case 'GOT_FOLIO_RETURN_OTHER_ASSET_DATA':
      return {...state, returnOtherAsset:action.payload }
case 'FAILED_FOLIO_RETURN_OTHER_ASSET_DATA':
      return {...state, returnOtherAsset:action.payload }
case 'COMPONENT_LOADER_RETURN_OTHER_ASSET_DATA':
      return {...state, loaderOtherAsset:action.payload }

case 'GOT_FOLIO_SUMMARY_FIXED_ASSET_DATA':
      return {...state, returnFixedDeposit:action.payload }
case 'FAILED_FOLIO_SUMMARY_FIXED_ASSET_DATA':
      return {...state, returnFixedDeposit:action.payload }

      
case 'GOT_CURRENT_HOLDING_DATA':
      return {...state,currentHoldingData:action.payload}
case 'FAILED_CURRENT_HOLDING_DATA':
      return {...state, currentHoldingData:action.payload}
case 'COMPONENT_LOADER_CURRENT_HOLDING_TABLE': 
      return {...state,currentHoldingLoader:action.payload}

case 'GOT_PORTFOLIO_REBALANCE_DATA':
      return {...state,portfolioRebalance:action.payload}
case 'FAILED_PORTFOLIO_REBALANCE_DATA':
      return {...state, portfolioRebalance:action.payload}
case 'COMPONENT_LOADER_PORTFOLIO_REBALANCE_DATA':
      return {...state,portFolioRebalanceLoader:action.payload}

case 'GOT_PORTFOLIO_REBALANCE_PDF':
      return {...state,portFolioRebalanceToken:action.payload}
case 'FAILED_PORTFOLIO_REBALANCE_PDF':
      return {...state,portFolioRebalanceToken:action.payload}
case 'LOADER_FOR_PORTFOLIO_REBALANCE_PDF':
      return {...state,portFolioRebalancePdfLoader:action.payload} 

case 'COMPONENT_LOADER_SUMMARY_FIXED_ASSET_DATA':
      return {...state, loaderFixedDeposit:action.payload }
case 'GOT_DATA_FILE_REPROCESS':
return {...state , reprocess:action.payload , notification:action.payload, errorMsg:false} 
case 'FAILED_TO_REPROCESS_FILE':
return {...state, reprocess:action.payload ,notification:action.payload , errorMsg:true}     

case 'GOT_IMPORT_REJECTION_DATA':
      return {...state, importRejectionsData: action.payload}
case 'FAILED_IMPORT_REJECTION_DATA' :
      return {...state, importRejectionsData :action.payload}

case 'GOT_TOP_SCHEMES' : 
  return { ...state, topSchemeList : action.payload }
case 'FAILED_GETTING_TOP_SCHEMES' :
  return { ...state, topSchemeList : null }

case 'COMPONENT_LOADER_DBF_UPLOAD':
    return { ...state, loaderDbfUpload: action.payload }
case 'DBF_UPLOAD_SUCESSFUL':
    return { ...state, dbfUploadData: action.payload }
case 'FAILED_DBF_UPLOAD':
    return { ...state, dbfUploadData: action.payload }

/*case "SAVE_BID_FOR_FILE_IMPORT_HISTORY":
    return { ...state, bidForFileImportHistory: action.payload }*/

case 'PROFIT_AND_LOSS_MUTUAL_FUND_DATA':
  return {...state,profitAndLossMFData:action.payload}
case 'PROFIT_AND_LOSS_SHARE_BOND_DATA':
  return {...state,profitAndLossSBData:action.payload}

case 'REPORT_SUMMARY_LOADER':
    return {...state,reportSummaryLoader:action.payload}
case 'GOT_REPORT_SUMMARY_DETAILS_DATA':
    return {...state,reportSummaryDetails:action.payload}
case 'FAILED_REPORT_SUMMARY_DETAILS_DATA':
    return {...state,reportSummaryDetails:action.payload}

case 'GOT_PORTFOLIO_RETURN_MOBILE_WEB':
    return {...state,portfolioReturnMobileWeb:action.payload}
case 'FAILED_PORTFOLIO_RETURN_MOBILE_WEB':
    return {...state,portfolioReturnMobileWeb:action.payload}

case 'MOBILE_VIEW_DRILL_DOWN_COUNT' :
    return {...state,mobileViewDrillDownCount:action.payload}
  case 'GET_PS_FOR_ALL_ASSETS':
  case 'FAILED_TO_GET_PS_FOR_ALL_ASSETS':
    return { ...state, summaryAllAssets: action.payload }
default:
      new Error('No action type matched in Login reducer')
  }
 
 return state
}
