var intialState = {
}

export default function rollingReturns(state = {launchdate:{}}, action){
    switch( action.type ){

        
        case 'GETTING_ROOLING_SCHEMES_NAME' :
            return { ...state}
        case 'GOT_ROOLING_SCHEMES_NAME' :
            return { ...state, rollingSchemesName : action.payload}

        case 'COMPONENT_LOADER_GRAPH_SCHEMES' :
        return { ...state, loaderForGraphSchemes : action.payload}

        case 'FAILED_ROOLING_SCHEMES_NAME' :
            return { ...state, rollingSchemesName : {errorMsg : action.payload } }

        case 'GETTING_SCHEME_LAUNCH_DATE' : 
            return {...state}
        case 'GOT_SCHEME_LAUNCH_DATE' :
        state.launchdate[action.value] = action.payload
            return { ...state, rollingSchemeLaunchDate : state.launchdate }
        case 'FAILED_SCHEME_LAUNCH_DATE':
            return {...state , rollingSchemeLaunchDate : { errorMsg : action.payload }}
        case 'SCHEME_LAUNCH_DATE_STATUS' :
            return {...state, schemeLaunchDateStatus : action.payload}

        case 'GETTING_ROLLING_RETURN_RESULT' :
            return { ...state}
        case 'GOT_ROLLING_RETURN_RESULT' :
            return { ...state, rollingReturnResult : action.payload}
        case 'FAILED_ROLLING_RETURN_RESULT' :
            return { ...state, failGetRollingReturnResult : {errorMsg : action.payload } }

        case 'COMPONENT_LOADER_RETURN_RESULT' :
            return { ...state, loaderForGraphResult : action.payload}
        case 'GOT_ROLLING_REPORT_PDF' :
            return { ...state, gotRollingReportToken :  action.payload}
        case 'LOADER_FOR_ROLLING_REPORT_PDF' :
            return { ...state, rollingReportLoader :  action.payload}

        case 'FAILED_ROLLING_REPORT_PDF' :
            return { ...state, gotRollingReportToken : action.payload}


        default:
        new Error('No action type matched in reducer')

    }

    return state;
}