export default function payments(state={iinBasedMandate:{}, iinBasedBankObj:{}},action){	
	switch (action.type) {
		case 'GETTING_MANDATE_BY_IIN':
	    	return {...state,  gettingMandate:true }
		case 'GOT_MANDATE_BY_IIN':
        	state.iinBasedMandate[action.iinNo] = action.payload
	    	return {...state,iinMandateData:state.iinBasedMandate, gettingMandate:false,gotMandate:true } 
	    case 'FAILED_GETTING_MANDATE_BY_IIN':
	    	return {...state,failedGettingMandate:true,gettingMandate:false}
	    case 'GETTING_BANK_DETAILS_BY_IIN':
	    	return {...state,  gettingBankDetails:true }
		case 'GOT_BANK_DETAILS_BY_IIN':
			state.iinBasedBankObj[action.iinNo] = action.payload
	    	return {...state,iinBankDetailsData:state.iinBasedBankObj, gettingBankDetails:false,gotBankDetails:true } 
	    case 'FAILED_GETTING_BANK_DETAILS_BY_IIN':
	    	return {...state,failedGettingMandate:true,gettingBankDetails:false }
		case 'COMPONENT_LOADER_IINBANK_LIST':
			return { ...state, loaderIINBankList: action.payload}
	  default:
	    new Error('No action type matched')
	}
	return state;
}
