import React from 'react';
//import CommonWindowLoader from 'app/uiCollection/shared/commonWindowLoader'

const reloadPage = ()=> {
  window.location.reload()
}
export default function Loading(props) {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
    } else if (props.pastDelay) {
      return <div>Loading...</div>;
    } else {
      return null;
    }
  } else if (props.error) {
    return <div>Something went wrong. Kindly refresh the page by pressing CTRL+Shift+R. <button onClick={ ()=> reloadPage() }>Retry</button></div>;
  } else {
    return null;
  }
}
