//Search for ConstantName[i] when you change constant i. e. 
export const SUB_OPTION_FEATURE = {
	'feature1011':true,
	'feature1012':true,
	'feature1013':true,
	'feature1014':true,
	'feature1015':true,
	'feature1027':true,
	'feature1029':true,
	'feature1034':true,
	'feature1039':true,
	'feature1017':true,
	'feature1018':true,
	'feature1019':true,
	'feature1020':true,
	'feature1028':true,
	'feature1021':true,
	'feature1022':true,
	'feature1023':true,
	'feature1037':true,
	'feature1032':true,
	'feature1024':true,
	'feature1025':true,
	'feature1026':true,
	'feature1038':true,
	'feature1033':true,
	'feature1017':true,
	'feature1018':true,
	'feature1019':true,
	'feature1020':true,
	'feature1036':true,
	'feature1028':true
}
export const LEFT_SIDEBAR_TAB_OPTION ={
										'feature1002': {
											'feature1011':{label:"Portfolio Valuation",url:"#/client/portfolioValuation",value:1011},
								  			'feature1012':{label:"Portfolio Summary",url:"#/client/portFolioSummary/mutualFund",value:102},
								  			'feature1013':{label:"Asset Allocation",url:"#/client/assetAllocation",value:1013},
											'feature1014':{label:"Capital Gain Realized",url:"#/client/capitalGainRealized",value:106},
											'feature1015':{label:"Capital Gain Unrealized",url:"#/client/capitalGainUnRealized",value:105},
											'feature1027':{label:"Dividend Report",url:"#/client/dividendReport",value:1014},
											'feature1029':{label:"Profit And Loss",url:"#/client/profitAndLoss",value:1015},
								  			'feature1034':{label:"Portfolio Performance",url:"#/client/portfolioPerformance/mutualFund",value:1034},
											'feature1039':{label:"Portfolio Rebalancing",url:"#/client/portfolioRebalancing",value:1039},
											'feature1046':{label:"Portfolio Snapshot",url:"#/client/portfolioSnapshot",value:1046},
										},
										'feature1003': {},
										'investOnlineNse': { 
											'feature1017':{label: "New Investment", url: "#/client/newInvestment/allSchemes",value:601},
											'feature1018':{label: "Additional Transaction", url: "#/client/additionalInvestment",value:602},
											'feature1019':{label: "Cart", url: "#/client/cartItems",value:603},
											'feature1020':{label: "My Orders", url: "#/client/myOrders",value:604},
											'feature1028':{label: "Manage Profiles", url: "#/client/manageProfiles",value:605},
										},
										'feature1030': { 
											'feature1021':{label: "New Investment", url: "#/client/bseNewInvestment/allSchemes",value:1201},
											'feature1022':{label: "Additional Transaction", url: "#/client/bseAdditionalInvestment",value:1202},
											'feature1023':{label: "My Orders", url: "#/client/myOrders/BSE",value:1203},
											'feature1037':{label: "Fund Picks",url:"#/client/bseFundPicks",value:1205},	
											'feature1032':{label: "Manage Profiles", url: "#/client/bseManageProfiles/0",value:611},
										},
										'feature1031': { 
											'feature1024':{label: "New Investment", url: "#/client/mfuNewInvestment/allSchemes",value:1301},
											'feature1025':{label: "Additional Transaction", url: "#/client/mfuAdditionalInvestment",value:1302},
											'feature1026':{label: "My Orders", url: "#/client/myOrders/MFU",value:1303},
											'feature1038':{label: "Fund Picks",url:"#/client/mfuFundPicks",value:1305},	
											'feature1033':{label: "Manage Profiles", url: "#/client/mfuManageProfiles/0",value:1304},
										},
										'feature1009': {},
										'feature1005': {},
										'feature1006': {},
										'feature1004' : {},
										'feature1010' : {},
										'feature1008': { 
											'feature1017':{label: "New Investment", url: "#/client/newInvestment/allSchemes",value:601},
											'feature1018':{label: "Additional Transaction", url: "#/client/additionalInvestment",value:602},
											'feature1019':{label: "Cart", url: "#/client/cartItems",value:603},
											'feature1020':{label: "My Orders", url: "#/client/myOrders/NSE",value:604},
											'feature1036':{label: "Fund Picks",url:"#/client/nseFundPicks",value:606},	
											'feature1028':{label: "Manage Profiles", url: "#/client/manageProfiles/0",value:605},
										},
										// 'feature1040': { 
										// 	'feature1041':{label: "My Profiles", url: "#/client/nps/myProfiles",value:1041},
										// 	'feature1042':{label: "My Orders", url: "#/client/nps/myOrders",value:1042},
										// },
									};

										/*'investOnline':{label:'Invest Online(dev)',url:"javascript:void(0)",value:400},*/

export const LEFT_SIDEBAR_TAB_PANEL_LABEL ={
											'feature1001':{label:'Dashboard',url:"#/client/dashboard",value:500, isLink:true, iconClass:"Frequently-Visited-icons frqntly dashboardIcon" },
											'feature1002':{label:'Report',url:"javascript:void(0);",value:100, isLink:false, iconClass:"Transactional-Reports-icon",featureNo:'feature1002'},
											'feature1003':{label:'Transactions',url:"#/client/transactions/mutualFund",value:200,isLink:true, iconClass:"myTransactionIcons"},
											'feature1004':{label:'Insurance',url:"#/client/insurance/lifeInsurance",value:1000,isLink:true, iconClass:"insuranceIcons"},
											'feature1005':{label:"My SIP's",url:"#/client/mySystematicTransactions",value:800,isLink:true, iconClass:"smTnxs"},
											'feature1006':{label:'My Folios',url:"#/client/folioLookup",value:900,isLink:true, iconClass:"folioLookupIcon"},
											'feature1007':{label:'Goal Planner',url:"#/client/goal/goalList",value:300,isLink:true, iconClass:"goalPlanner"},
											'feature1008':{label:'Invest Online NSE',url:"javascript:void(0);",value:600, isLink:false,iconClass:"investIcons nseIcon",featureNo:'feature1008'},
											'feature1030':{label:'Invest Online BSE',url:"javascript:void(0);",value:1200, isLink:false,iconClass:"investIcons bseIcon",featureNo:'feature1030'},
											'feature1031':{label:'Invest Online MFU',url:"javascript:void(0);",value:1300, isLink:false,iconClass:"investIcons mfuIcon",featureNo:'feature1031'},
											// 'feature1040':{label:'Invest Online NPS',url:"javascript:void(0);",value:1040, isLink:false,iconClass:"investIcons"},
											'feature1009':{label:'My Documents',url:"#/client/myDocuments",value:700,isLink:true, iconClass:"documents"},
											'feature1010':{label:'Risk Profiling',url:"#/client/riskProfiling",value:1100,isLink:true, iconClass:"riskProfile"},
											'feature1044':{label:'Loan Against MF',url:'#/client/loanAgainstMf',value:1043,isLink:true,iconClass:'loanAgainstMfIcon'},
											'feature1045':{label:'Watchlist',url:'#/client/watchlist',value:1045,isLink:true,iconClass:'documents'},
											'feature1047':{label:'Import External Portfolio',value:1047,isLink:true, onlyForMobileApp:true}

}
export const LEFT_SIDEBAR_TAB_PANEL ={'folioQuery':1,'dividend':2,'capitalGain':3,'MFtally':4,'fd':5}

export const GOAL_PLANNER_TAB = {
						  goal:{label:'Goal Defination',value:1},
						  assingFolio:{label:'Assing Folio To Goal',value:2},
						  goalPlanner:{label:'Goal Planner',value:3},
						  }
export const PAGES_TITLES = [ {url:'client/overview',title:"Reports"},{url:'client/dashboard',title:"Dashboard"},
						{url:'client/goal',title:"User Goal"},{url:'client/goal/assingFolioToGoal',title:"Assing Folio To Goal"},
					  	{url:'client/transactions',title:"Transactions"},
					  	{url:'client/newInvestment',title:"Invest Online"},
					  	{url:'client/additionalInvestment',title:"Invest Online"},
					  	{url:'client/Cart',title:"Invest Online"},
					  	{url:'client/myDocuments',title:"My Documents"},
					  	{url:'client/mySystematicTransactions',title:"My Systematic Transactions"},
					  	{url:'client/folioLookup',title:"Folio Lookup"},
					  	{url:'client/Reports',title:"Reports"}
					  ]



export const NEW_INVESTMENT_FILTERS = [
	{label:"Fund",value:'fund', indexVal: 1},
	{label:"Category",value:'category', indexVal:2}
	]

export const FUNDS_LIST = { 
	'fund':
	 	{label:"fundName",value:'fundid'},
 	'category':
 		{label:"objective",value:'objective'},		
}

export const NEW_INVESTMENT_CATEGORY = [
	 {label:"Equity",value:'equity'},
     {label:"Debt",value:'debt'},
	 {label:"Hybrid",value:'hybrid'},
	 {label:"Gilt",value:'gilt'},
]

export const NEW_INVESTMENT_SUB_CATEGORY = { 'Equity' :[
	 {label:"All",value:null},
	 {label:"Small Cap",value:'smallCap'},
     {label:"Multi Cap",value:'multiCap'},
	 {label:"Large Cap",value:'largeCap'},
	 {label:"Mid Cap",value:'midCap'},
	 {label:"Sector",value:'sector'},
	 {label:"Global",value:'Global'},
     {label:"Tax Planning",value:'taxPlanning'},
	 {label:"Index",value:'index'},
	 {label:"Gold",value:'gold'}
],
	'Debt': [
	 {label:"All",value:null},
	 {label:"DBF",value:'DBF'},
     {label:"Income",value:'income'},
	 {label:"Credit Opp",value:'creditOpportunities'},
	 {label:"Short",value:'shortTerm'},
	 {label:"Liquid",value:'liquid'},
	 {label:"Ultrashort",value:'ultraShortTerm'},
     {label:"Arbitrage",value:'arbitrage'}
],
	'Hybrid': [
	 {label:"All",value:null},
	 {label:"Debt Oriented",value:'debtOriented'},
     {label:"Equity Oriented",value:'equityOriented'},
	 {label:"Monthly Income",value:'monthlyIncome'},
	 {label:"Equity Savings",value:'equitySaving'}
],
	'Gilt': [
	 {label:"All",value:null},
	 {label:"Gilt Short Term",value:'shortTerm'},
     {label:"Gilt Medium & Long Term",value:'midAndLongTerm'}
]
}

export const NEW_INVESTMENT_TXNS_TYPES = [
	 {label:"Purchase",value:'purchase', indexVal: 1, customVal : 'NRP'},
     {label:"SIP",value:'SIP', indexVal: 2, customVal : 'SIP'}
]

export const FREQUENCY_LIST = [
	 {label:"Quarterly",value:'quarterly', indexVal: 1},
     {label:"Monthly",value:'monthly', indexVal: 2},
     {label:"Weekly",value:'weekly', indexVal: 3}
]

export const FREQUENCY_LIST_NSE = [
	 {label:"Quarterly",value:'quarterly', indexVal: 1},
     {label:"Monthly",value:'monthly', indexVal: 2},
     {label:"Weekly",value:'weekly', indexVal: 3},
     {label:"Daily",value:'daily', indexVal: 4},
]

export const DIVIDEND_FREQUENCY_LIST = [
	 {label:"DAILY",value:'daily', indexVal: 1},
     {label:"WEEKLY",value:'weekly', indexVal: 2},
     {label:"FORTNIGHTLY",value:'fortnightly', indexVal: 3},
     {label:"MONTHLY",value:'monthly', indexVal: 4},
     {label:"QUARTERLY",value:'quarterly', indexVal: 5},
     {label:"HALF_YEARLY",value:'halfYearly', indexVal: 6},
     {label:"YEARLY",value:'yearly', indexVal: 7},
]

export const SCHEME_FREQUENCY = [
	{label:"label",value:'value'}
]

export const FIRST_INSTALLMENT = [{ levelName: "Pay First Installment", levelNo: 'payFirstInstallment' }]

export const ADDITIONAL_INVESTMENT_TXNS_TYPES = [
	 {label:"Purchase",value:'NRP', indexVal: 1},
     {label:"Switch",value:'SWO', indexVal: 2 , sourceSubTxnType:'SWO', targetSubTxnType:'SWI'},
     {label:"SIP",value:'SIP', indexVal: 3, newValue:'sip'},
     {label:"STP",value:'STP', indexVal: 4,newValue:'stp',sourceSubTxnType:'SWO', targetSubTxnType:'STP'},
     {label:"Redemption",value:'NRS', indexVal: 5},
     {label:"SWP",value:'SWP', indexVal: 6,newValue:'swp'}
]


export const ORDER_TXNS_TYPES = [
	{label:"Purchase",value:'NRP', indexVal: 1},
	{label:"Switch In",value:'SWI', indexVal: 2},
	{label:"Switch Out",value:'SWO', indexVal: 3},
	{label:"SIP",value:'SIP', indexVal: 4},
	{label:"STP",value:'STP', indexVal: 5},
	{label:"Sell",value:'NRS', indexVal: 6},
	{label:"SWP",value:'SWP', indexVal: 7},
	
	
]

export const PROFILE_TYPE = [
	{label:"Physical", value:'N'},
	{label:"Demat", value:'Y'}
]

export const BSE_ADDITIONAL_INVESTMENT_TXNS_TYPES = [
	 {label:"Purchase",value:'NRP', indexVal: 1},
     {label:"Switch",value:'SWO', indexVal: 2},
     {label:"SIP",value:'SIP', indexVal: 3},
     {label:"STP",value:'STP', indexVal: 4},
     {label:"Redemption",value:'NRS', indexVal: 5},
     {label:"SWP",value:'SWP', indexVal: 6}
]

export const TARGET_SCHEME_LIST = [
	{label:"name",value:'schemeGroupId'}
]

export const REDEEM_TYPES = {
	  AMOUNT: 'amount',
	  UNIT: 'units',
	  ALL_UNIT: 'allUnits'
}

export const PAYMENT_MODES = [
  { label: 'Net Banking ', value: 'online', image:'netBanking' },
  { label: 'Debit Mandate', value: 'debitMandate', image:'debitMandate'},
  { label: 'RTGS/NEFT', value: 'rtgs/neft', image:'neft'},
  { label: 'Cheque', value: 'cheque', image:'cheque'},
  { label: 'UPI', value: 'upi', image:'upi'},
  // { label: 'Demand Draft', value: 'demandDraft' },
]
export const PAYMENT_METHOD = [
	{ label: 'Link on Email	 ', value: 'linkOnEmail' },
	{ label: 'Immediate Payment', value: 'immediatePayment' },
]

export const PAYMENT_MODES_MFU = [
  { label: 'Net Banking ', value: 'online' },
  { label: 'Debit Mandate', value: 'debitMandate' },
  { label: 'RTGS', value: 'rtgs' },
  { label: 'NEFT', value: 'neft' },
]

export const SIP_MINING_TYPE_LIST = [
	 {label:"SIP",value:'SIP', indexVal: 1},
     {label:"STP",value:'STP', indexVal: 2},
     {label:"SWP",value:'SWP', indexVal: 3},
    ]
export const SWITCH_ON_OFF = [
	{label: 'slideLeft'},
	{label: 'slideRight'}
]
export const PDF_LIST = [
	{label:"Summary",value:'summary', indexVal:1 , iconClass:"summary"},
	{label:"Detailed",value:'detailed',indexVal:2 , iconClass:"detailed"}, 
	{label:"ITR Report",value:'itrReport',indexVal:3 , iconClass:"detailed"} 
	]

export const PORTFOLIO_RETURN_FIXED_DEPOSIT = [
	{label:"Fixed Sub Asset",value:'fixedSubAssetid'},
    {label:"Fixed Asset Transaction",value:'fixedAssetTxnid'},
]
 
export const NAV_DATE_FILTER_LIST = [
	{label:"This Month", value:'thisMonth'},
	{label:"Last One Month",value:'lastOneMonth'},
	{label:"Current FY",value:'currentFY'},
	{label:"Last FY", value:'lastFY'},
  {label:"Last One Year",value:'lastOneYear'},
  {label:"Since Inception",value:'sinceInception'}
]

export const CAPITAL_GAIN_REALIZED_REPORT_TYPES = [
	{label : "Summary" , value : "summary", showSendButton : true },
	{label : "Detailed", value:'detailed'}, 
	{label : "ITR Report", value:'itrReport'},
	{label : "Schedule 112A", value : "schedule112A" },
	{label : "Tax Package", value : "taxPackage" }
]

export const CAPITAL_GAIN_UNREALIZED_REPORT_TYPES = [
	{ label : "Capital Gain Unrealized" , value : "CGUR" , showSendButton : true}
]

export const PORTFOLIO_RETURN_REPORT_TYPES = [
	{label : "Summary" , value : "isSummary", showSendButton : true },
	{ label : "Portfolio Return (Till Date)" , value : "PR", },
]

export const PORTFOLIO_SUMMARY_REPORT_TYPES = [
	{ label : "Portfolio Summary (Since Inception)" , value : "PS", showSendButton : true }
]


export const PAYMENT_MODES_FOR_TRANSACT = [
  { label: 'Net Banking ', value: 'online', image:'netBanking' },
  { label: 'Use Same Mandate', value: 'debitMandate', image:'debitMandate' },
  { label: 'RTGS/NEFT', value: 'rtgs/neft', image:'neft' },
  { label: 'Cheque', value: 'cheque', image:'cheque' },
  { label: 'UPI', value: 'upi', image:'upi' },
]

export const SIP_GROUP = ['SIPWithFirstInstallment', 'SIPNew', 'SIPAdditional']

export const NEW_INVESTMENT_TABS = [
	{ label:'All Schemes', value:0 , type:'allSchemes',metatitleKey:'allSchemes'},
	{ label:'NFO Schemes', value:1, type:'nfoSchemes',metatitleKey:'nfoSchemes'},
]

export const RISK_PROFILER_METER_VALUES = {
	'conservative': { label: "Conservative", value: 1},
	'moderately conservative': { label: "Moderately Conservative", value: 2},
	'moderate': { label: "Moderate", value: 3},
	'moderately aggressive': { label: "Moderately Aggressive", value: 4},
	'aggressive': { label: "Aggressive", value: 5}
}

export const CAPITAL_GAIN_VIEW_TYPE = [
	{ label : "On Screen" , value : "onScreen",method:'view' },
	{ label : "PDF" , value : "pdf" , method:'export' },
	{ label : "Excel" , value : "xls", isAllowed: true ,method :'export'},
	{ label : "Email" , value : "email" ,method :'export'},
]

export const PORTFOLIO_SUMMARY_VIEW_TYPE=[
	{ label: 'On Screen', value: 'onScreen', method: 'view' },
	{ label: 'PDF', value: 'pdf',method:'export'},
	{ label: 'Excel', value: 'xls',  isAllowed: true,method:'export'},
	{ label: 'Excel (Unformatted)', value: 'xlsUnformatted', isAllowed: true,method:'export'},
	{ label: 'Email', value: 'email',method:'export'}
]
export const REPORT_TYPE=[
	{ label: 'Category', value: 'category', reportValue : '1003' },
	{ label: 'Sub-Category', value: 'subCategory', reportValue : '1004'},
	{ label: 'Transaction', value: 'transaction', reportValue : '1008'},
	{ label: 'Client Wise', value: 'clientWise', reportValue : '100'},
]
export const CAS_ARN_OPTION = [
	{ label: 'Include', value: 0 },
	{ label: 'Exclude', value: 1 },
	{ label: 'Show Only', value: 2 }
] 

export const HIDE_FROM_FEATURE_LIST = {
	feature1001: true,
	feature1020: true,
	feature1023: true,
	feature1026: true,
}


export const DIVIDEND_REPORT_VIEW_TYPE = [
	{ label : "On Screen" , value : "onScreen" , method:'view'},
	{ label : "PDF" , value : "pdf",method:'export' },
	{ label : "Excel" , value : "xls", isAllowed: true,method:'export'},
]

export const DIVIDEND_REPORT_GROUP_BY = [
	{ label : "Scheme/Scrip" , value : 1002 },
	{ label : "Folio" , value : 1005 },
	{ label : "Investor" , value : 100 },
]
export const DIVIDEND_REPORT_ASSET_CLASS = [
	{label:'Mutual Fund',value:'mutualFund',type:'MF'},
	{label:'Share & Bond',value:'shareAndBond',type:'EQ'}
]

export const ALLOCATION_SECTION_PORTFOLIO_SUMMARY =[
	{label:"Investor", value :'applicant'},
	{label:"Fund", value :'fund'},
	{label:"Scheme", value :'scheme'},
	{label:"MF Equity Market Cap", value :'mfEquityMarketCap'},
]

export const INCLUDE_COLUMNS_PORTFOLIO_SUMMARY = [
	{ label: "Switch In", value: 'switchIn' },
	{ label: "Switch Out", value: 'switchOut' },
	{ label: "Dividend Payout", value: 'dividend' },
	{ label: "Dividend Reinvest", value: 'dividendReinvestment' },
	{ label: "Balance Units", value: 'currentUnits' , onlyForOnScreen : true },
	{ label: "Purchase Outstanding", value: 'remainingBalUnitsPurchaseValue' },
	{ label: "Absolute return", value: 'absoluteReturn' },
	{ label: "Unrealized Gain", value: 'unrealizedGain' },
	{ label: "Realized Gain", value: 'realizedGain' },
	{ label: "XIRR", value: 'XIRR' },
]
export const PS_INCLUDE_COLUMNS_FOR_ALL_ASSETS = [
	{ label: 'Opening Balance', value: 'openingBalance' },
	{ label: 'Purchase', value: 'purchaseValue' },
	{ label: 'Switch In', value: 'switchIn' },
	{ label: 'Dividend Reinvest', value: 'dividendReinvestment' },
	{ label: 'Redemption', value: 'redemptionAmount' },
	{ label: 'Switch Out', value: 'switchOut' },
	{ label: 'Current Value', value: 'currentValue' },
	{ label: 'Div Payout/Interest', value: 'dividend' },
	{ label: 'Total Gain', value: 'gain' },
	{ label: 'Absolute return', value: 'absoluteReturn' },
	{ label: 'XIRR', value: 'XIRR' },
]

export const DATA_OPTIONS_PORTFOLIO_SUMMARY =[
	{ label:"Active Assets only", value :'activeAssetsOnly' },
	{ label:"Investment snapshot", value :'investmentSnapshot' },
	{ label:"SIP Summary", value :'sipSummary' },
	{ label:"Insurance list", value :'insurance' },
	{ label:"Folio Comment", value :'folioComments' },
]
 
export const BSE_PAYMENT_MODE = [
	{ label: 'Mandate', value: 'mandate' },
	{ label: 'Net Banking', value: 'Net Banking' },
	{ label: 'NEFT/RTGS', value: 'rtgs/neft'},
	{ label: 'UPI', value: 'UPI' },
]

export const CAPITAL_GAIN_TYPE = [
	{ label: 'Short Term Debt', value: 'debtShortTerm' },
	{ label: 'Long Term Debt', value: 'debtLongTerm'},
	{ label: 'Short Term Equity', value: 'equityShortTerm' },
	{ label: 'Long Term Equity', value: 'equityLongTerm' },
]
export const DATA_OPTIONS_CAPITAL_GAIN_REALISED = [
	{ label: 'Portfolio Valuation', value: 'portfolioValuation' },
	
]
export const PERIOD_TYPE = [
	{ label: 'Monthly', value: 'M', },
	{ label: 'Quarterly', value: 'Q',},
	{ label: ' Yearly', value: 'Y',},
]

export const PLACE_ORDER_DISCLAIMER = [
	{ label: 'By clicking on Place Order, I confirm that I have read all the Scheme Information Documents.' }
]

export const PORTFOLIO_PERFORMANCE_VIEW_TYPE=[
	{ label: 'On Screen', value: 'onScreen',method:'view' },
	{ label: 'PDF', value: 'pdf',method: 'export'},
	{ label: 'Excel', value: 'xls',  isAllowed: true,method: 'export'},
]

export const TRANSACT_COMMON_LIST = [
	{label: 'Broker Login', url:'#', customClass:'userIcon lightBlueBg', value:1,
		slogan : 'Order is placed after you login with your broker and confirm it',
	},
	{label: 'Funds for the order', url:'#', customClass:'rupeesIcon lightYellowBg', value:2,
		slogan : 'Funds for the order are used from your trading account.',
	},
	{label: 'Transaction Success', url:'#', customClass:' transactionSuccess lightSkyBg', value:3,
		slogan : 'Stocks are credited to/ debited from your demat account.',
	}
]

export const MULTIPLE_GROUP_BY_PORTFOLIO_VALUATION = [
	{ label: '1.  Investor, Category, Folio', value: '1003' },
	{ label: '2.  Investor, Category, Folio, Transaction', value: '1010', transaction:true},
	{ label: '3.  Investor, Category', value: '1011'},
	{ label: '4.  Investor, Sub Category, Folio', value: '1004'},
	{ label: '5.  Investor, Sub Category, Folio, Transaction', value: '1008', transaction:true},
	{ label: '6.  Investor, Sub Category', value: '1012'},
	{ label: '7.  Investor, Transaction', value: '100', transaction:true},
	{ label: '8.  Investor', value: '1013'},
	{ label: '9.  Category', value: '1014'},
	{ label: '10.  Sub Category', value: '1015'},
]
export const CLIENT_FINDER_SOURCE_MAPPING = [
	{label:"Api",value:"api"},
	{label:"BSE Rejected UCC Mapping",value:"unmappedUCC"},
	{label:"CAN Creation",value:"mfu"},
	{label:"CAS Import",value:"cas"},
	{label:"Clients Bulk Upload",value:"clientsBulkImport"},
	{label:"Clients Menu",value:"investorLookup"},
	{label:"Demerge Client",value:"folioLookup"},
	{label:"FD Bulk Upload",value:"importFixedAssets"},
	{label:"IIN Creation",value:"nse"},
	{label:"Insurance Bulk Upload",value:"importInsurance"},
	{label:"Migration From Desktop",value:"clientMigration"},
	{label:"MF Upload",value:"mfTxnsFile"},
	{label:"NSE Sync",value:"bulkUploadNse"},
	{label:"Onboarding",value:"onboarding"},
	{label:"RTA Folio Master Import",value:"importFolioMaster"},
	{label:"RTA Transactions Import",value:"importTxns"},
	{label:"UCC Creation",value:"bse"}
]

export const CHEQUE_DEPOSIT_MODE = [
	{ label: 'Deposit at the service center', value: 'MFSS' },
	{ label: 'Deposit at the CMS Branch', value: 'CMS' },
  ]

export const NPS_ACCOUNT_TYPE =[
	{ label: 'Tier 1', value: 1, fldLabel : 'One', paramValue : 1 },
	{ label: 'Tier 1 & Tier 2', value: 2, fldLabel : 'Two', paramValue : 3},
]
export const INVESTMENT_OPTIONS =[
	{ label: 'Active', value: 'V' },
	{ label: 'Auto', value: 'auto'},
]
export const LIFE_CYCLE_FUND_OPTIONS =[
	{ label: 'Conservative (LC25)', value: 'L' },
	{ label: 'Moderate (LC50)', value: 'A'},
	{ label: 'Aggresive (LC75)', value: 'H'},
]

export const PRAN_CARD_NAME =[
	{ label: 'Father Name', value: 'F' },
	{ label: 'Mother Name', value: 'M'},
]

export const RESIDENT_CONFORMATION =[
	{ label: 'Yes', value: 'Y' },
	{ label: 'No', value: 'N'},
]

export const ACCOUNT_TYPE =[
	{ label: 'Savings', value: 'SAVINGS' },
	{ label: 'Cuurent', value: 'CURRENT'},
]

export const INVESTONLINE_DOCUMENT_TYPE =[
	{label:'Photograph',value:'PH'},
	{label:'Signature',value:'SG'},
	{label:'Tier 1 Bank proof',value:'T1CHQ'},
	{label:'PAN',value:'PAN'},
	{label:'Address Proof',value:'ADCR'},
	{label:'Identity Proof',value:'IDPRF'},
	{label:'Tier 2 Bank proof',value:'T2CHQ'},
]

export const NOMINEES_NPS =[
	{label:'Father',value:'1'},
	{label:'Mother',value:'2'},
	{label:'Sister',value:'3'},
	{label:'Brother',value:'4'},
	{label:'Spouse',value:'5'},
	//{label:'Other',value:'12'},
]
export const TIER_SAME_NOMINEE =[
	{levelName:"Same as Tier 1 nominee details", levelNo :'isNominee', type : 'isNominee'},
]
export const TIER_SAME_BANK_DETAILS =[
	{levelName:"Same as Tier 1 bank details", levelNo :'isBank', type : 'isBank'},
]
export const ASSET_ALLOCATION_CATEGORY = [
	{label:"Overview", value:"overview"},
	{label:"Scheme", value:"scheme"},
	{label:"Holding", value:"holdings"}
]
export const SUB_ASSET_ALLOCATION_CATEGORY = [
	{label:"Asset", value:"asset"},
	{label:"Objective", value:"objective"},
	{label:"Fund", value:"fund"}
]
export const HOLDINGS_SUB_CATEGORY = [
	{label:"By Scrip" , value:"scrip"},
	{label:"By Sector" , value:"sector"},
	{label:"By Rating", value:"rating"},
	{label:"By Paper", value:"paper"}
]
export const SCHEME_TYPE_NSE_CART = [
	{label:"Growth",value:'G', indexVal: 1},
	{label:"Dividend Payout",value:'DP', indexVal: 2},
	{label:"Dividend Reinvestment",value:'DR', indexVal: 3},
]
export const NSE_FREQUENCY = [
	{label:'Daily', value:'D'},
	{label:'Business Day', value:'BZ'},
	{label:'Once a Week', value:'OW'},
	{label:'Specific Dates', value:'WD'},
	{label:'Monthly', value:'OM'},
	{label:'Quarterly', value:'Q'},
	{label:'Half-yearly', value:'H'},
	{label:'Yearly', value:'Y'},
	{label:'Twice a Week', value:'TW'}
]
export const WEEKDAYS_MAPPING = [
	{label:'Monday', value:'02',paramValue:2},
	{label:'Tuesday',value:'03',paramValue:3},
	{label:'Wednesday',value:'04',paramValue:4},
	{label:'Thursday',value:'05',paramValue:5},
	{label:'Friday',value:'06',paramValue:6}
]

export const NSE_CHEQUE_CLEARANCE = [
	{ label: 'NSE Clearing New Mutual Fund Platform' },
	{ label: 'HDFC Bank Ltd'}
]

export const PORTFOLIO_SNAPSSHOT_OPTIONS = [
	{ levelName: 'Given Period', levelNo: 'GP'},
	{ levelName: 'Since Inception', levelNo: 'SI'}
  ]

export const WEEKDAYS_MAPPING_BSE = {
	'MON':{ label: 'Monday', value: 'MON', datePickerIndex: 1 },
	'TUE':{ label: 'Tuesday', value: 'TUE', datePickerIndex: 2 },
	'WED':{ label: 'Wednesday', value: 'WED', datePickerIndex: 3 },
	'THU':{ label: 'Thursday', value: 'THU', datePickerIndex: 4 },
	'FRI':{ label: 'Friday', value: 'FRI', datePickerIndex: 5 }
}

export const CATEGORY_TYPE=[
	{ label: "Equity", value: "Equity"},
	{ label: "Debt", value: "Debt"},
	{ label: "Gold", value: "Gold"},
	{ label: "Other", value: "Other"}
]

export const BSE_SIP_FREQUENCY_LIST = [
	{label:"Quarterly",value:'quarterly', indexVal: 1},
	{label:"Monthly",value:'monthly', indexVal: 2}
]