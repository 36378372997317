export default function topSchemesReducer( state={}, action ){
    switch(action.type){

        case "GOT_CATEGORIES_SUCCESSFULLY":
            return { ...state, categoryList: action.payload }
        case "FAILED_TO_GET_CATEGORIES" : 
        	return { ...state, categoryList: null }

        case "GOT_OBJECTIVES_SUCCESSFULLY":
            return { ...state, objectiveList: action.payload }
        case "FAILED_TO_GET_OBJECTIVES" : 
            return { ...state, objectiveList: null }

        case 'GOT_TOP_SCHEME_CONFIGURATION':
            return { ...state, topSchemeConfiguration: action.payload }
        case 'FAILED_TO_GET_TOP_SCHEME_CONFIGURATION':
            return { ...state, topSchemeConfiguration: null }

        case 'ADD_TOP_SCHEMES_FILTERS':
            return { ...state, topSchemesFilters: action.payload }

        case 'GOT_TOP_SCHEMES_SUCCESSFULLY':
            return { ...state, topSchemesData: action.payload }
        case 'FAILED_TO_GET_TOP_SCHEMES':
            return { ...state, topSchemesData: null }
        
        case 'SET_FAVOURITE_SCHEME':
            return { ...state, notificationData: action.payload, errorNotification : false }
        case 'FAILED_TO_SET_FAVOURITE_SCHEME':
            return { ...state, notificationData: action.payload, errorNotification : true }
        case 'CLEAR_NOTIFICATION' :
            return {...state, notificationData : action.payload , notification: action.payload}

        case 'GOT_FUND_SCREENS_DATA' :
            return { ...state, fundScreensData: action.payload }
        case 'FAILED_FUND_SCREENS_DATA':
            return { ...state, fundScreensData: action.payload }

        case 'DELETE_FUND_SCREEN' :
            return { ...state, notification: action.payload }
        case 'FAILED_DELETE_FUND_SCREEN':
            return { ...state, notification: action.payload }

        case 'CREATE_UPDATE_SCREEN_DATA':
            return { ...state, notification: action.payload }
        case 'FAILED_CREATE_UPDATE_SCREEN_DATA' :
            return { ...state, notification: action.payload }

        case 'COMPONENT_LOADER_FUND_SCREENER' :
            return { ...state, fundScreenerLoader: action.payload }
        default:
            new Error('No action type matched')
            
    }
    return state;
}
