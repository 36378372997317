import SessionData from '../../utils/sessionData'
var intialState = {
    myTransactionsData: {},
    arnsList: {},
    fundsData: {},
    schemesData: {},
    foliosData: {},
    myTxnReversalsData : {}
};

    
export default function dashboard(state=intialState,action){
  switch (action.type) {

    case 'GETTING_TRANSACTIONS_DATA':
      return {...state, myTransactionsData:{apiStatus:1} }

    case 'GOT_TRANSACTIONS_DATA':
      return {...state, myTransactionsData:{data:action.payload,apiStatus:0} }

    case 'COMPONENT_FOR_LOADER_TRANSACTIONS':
      return {...state,loaderTransactions:action.payload}

    case 'FAILED_TRANSACTIONS_DATA':
        return {...state, myTransactionsData:{data:intialState.myTransactionsData.result,errorMsg:action.payload,apiStatus:-1
      } }

    case 'GETTING_ARNS_LIST':
      return {...state, arnsList:{apiStatus:1} }

    case 'GOT_ARNS_LIST':
      return {...state, arnsList:{data:action.payload,apiStatus:0} }

    case 'FAILED_ARNS_LIST':
      return {...state, arnsList:{data:intialState.arnsList.result,errorMsg:action.payload,apiStatus:-1
      } }  
    
        case 'GOT_TRANSACTIONS_MAIL_SENT_DATA':
          return {...state, notificationData:action.payload }
    
        case 'FAILED_TRANSACTIONS_MAIL_SENT_DATA':
              return {...state, notificationData:action.payload   }
    
    case 'GETTING_FUNDS_DATA':
      return {...state, fundsData:{apiStatus:1} }

    case 'GOT_FUNDS_DATA':
      return {...state, fundsData:{data:action.payload,apiStatus:0} }
    
    case 'COMPONENT_FOR_LOADER_GET_FUNDS':
      return{...state,loaderGetFunds:action.payload}
    
    case 'FAILED_FUNDS_DATA':
      return {...state, fundsData:{data:intialState.fundsData.result,errorMsg:action.payload,apiStatus:-1
      } }

    case 'GETTING_SCHEMES_DATA':
      return {...state, schemesData:{apiStatus:1} }

    case 'GOT_SCHEMES_DATA':
      return {...state, schemesData:{data:action.payload,apiStatus:0} }

    case 'FAILED_SCHEMES_DATA':
      return {...state, schemesData:{data:intialState.schemesData.result,errorMsg:action.payload,apiStatus:-1
      } }

    case 'GETTING_FOLIOS_DATA':
      return {...state, foliosData:{apiStatus:1} }

    case 'GOT_FOLIOS_DATA':
      return {...state, foliosData:{data:action.payload,apiStatus:0} }

    case 'FAILED_FOLIOS_DATA':
        return {...state, foliosData:{data:intialState.foliosData.result,errorMsg:action.payload,apiStatus:-1
      } }

    case 'GOT_SHARE_AND_BOND':
      return {...state, shareBondData:action.payload}
    
    case 'HIDE_NOTIFICATION':
      return{...state , shareBondData:null,deleteTxn:null, failedShareBondData: null}
    
    case 'COMPONENT_FOR_LOADER_TRANSACTIONS':
      return {...state ,loaderTransactions :action.payload}

    case 'FAILED_SHARE_AND_BOND':
      return {...state ,failedShareBondData :action.payload}

    case 'GOT_SHARE_SCHEMES_DATA':
      return {...state, shareSchemesData:action.payload}
  
    case 'COMPONENT_FOR_LOADER_ADD_TRANSACTIONS':
      return {...state, loaderForPopup:action.payload}
    
    case 'FAILED_SHARE_SCHEMES_DATA':
      return {...state ,failedShareSchemesData :action.payload}

    case 'GOT_SHARE_BOND_SCRIP_DATA':
      return {...state, shareBondScripData:action.payload}
    case 'FAILED_SHARE_BOND_SCRIP_DATA':
      return {...state ,failedShareBondScripData :action.payload}
    
    case 'CLEAR_SCHEMES' :
      return {...state ,shareSchemesData:null }
    
    case 'GOT_SHARE_SCHEMES_TXNS_DATA':
      return {...state, shareSchemesTxnsData:action.payload}
    
    case 'FAILED_SHARE_SCHEMES_TXNS_DATA':
      return {...state ,failedShareSchemesTxnsData :action.payload}

    case 'GOT_FD_COMPANY_DATA':
      return {...state, fdCompanyData:action.payload }

    case 'FAILED_FD_COMPANY_DATA':
      return {...state, fdCompanyDataError:action.payload }

    case 'GOT_FD_SCHEMES_DATA':
      return {...state, fdSchemesData:action.payload }

    case 'FAILED_FD_SCHEMES_DATA':
      return {...state, fdSchemesDataError:action.payload }

    case 'INSERT_FD_DATA':
      return {...state, notificationStatus:action.payload && action.payload.message,  errorNotification: false }

    case 'FAILED_FD_INSERT_DATA':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    case 'GOT_SHARE_BOND_DELETE_TXNS_DATA':
      return {...state, deleteTxn:action.payload }

    case 'COMPONENT_FOR_LOADER_FD':
      return{...state,loaderFD:action.payload}

    case 'CLEAR_NOTIFICATION':
      return {...state, notificationStatus: action.payload,notificationData:action.payload}

    case 'GOT_FD_ASSET_DATA':
      return {...state, fdTableData:action.payload}

    case 'COMPONENT_FOR_LOADER_FD_TABLE_DATA':
      return {...state, loaderFDTableData:action.payload}

    case 'FAILED_FD_ASSET_DATA':
      return {...state, errorInFdTableData:action.payload}

    case 'FD_DELETE_SUCCESSFULLY':
      return {...state, notificationStatus:action.payload && action.payload.message, errorNotification: false }

    case 'FAILED_TO_DELETE_FD':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    case 'FAILED_SHARE_BOND_DELETE_TXNS_DATA':
      return {...state, deleteTxn:action.payload }

    case 'GOT_CREATE_FIXED_SUB_ASSET_DATA':
      return {...state, createSubAssetData:action.payload, notificationData:action.payload}
    case 'COMPONENT_LOADER_CREATE_FIXED_SUB_ASSET':
      return {...state, loaderCreateSubAsset:action.payload}
    case 'FAILED_CREATE_FIXED_SUB_ASSET_DATA':
      return {...state, createSubAssetData:action.payload, notificationData:action.payload}


    case 'COMPONENT_FOR_LOADER_OTHER_ASSETS_TABLE_DATA':
      return {...state, loaderOtherAssetTable:action.payload}

    case 'GOT_OTHER_ASSETS_DATA':
      return {...state, otherAssetTableData:action.payload}

    case 'FAILED_OTHER_ASSETS_DATA':
      return {...state, otherAssetTableData: null,errorInOtherAssetTableData:action.payload}

    case 'CREATE_OTHER_ASSETS':
      return {...state, notificationStatus:action.payload,  errorNotification: false }

    case 'FAILED_CREATE_OTHER_ASSETS':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    
    case 'CREATE_ASSET_TXNS':
      return {...state, notificationStatus:action.payload,  errorNotification: false }

    case 'FAILED_ASSET_TXNS':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    
    case 'ASSET_TXNS_DELETE_SUCCESSFULLY':
      return {...state, notificationStatus:action.payload, errorNotification: false }

    case 'FAILED_TO_DELETE_ASSET_TXNS':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    
    case 'COMPONENT_FOR_LOADER_ASSET_TXNS_TABLE':
      return {...state, loaderAssetTxnTable:action.payload}

    case 'GOT_ASSET_TXNS_DATA':
      return {...state, assetTxnsTableData:action.payload}

    case 'FAILED_ASSET_TXNS_DATA':
      return {...state, assetTxnsTableData:null, errorInAssetTxnTableData:action.payload}


    case 'CREATE_ASSET_PRICE':
      return {...state, notificationStatus:action.payload,  errorNotification: false }

    case 'FAILED_ASSET_PRICE':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    case 'ASSET_PRICE_DELETE_SUCCESSFULLY':
      return {...state, notificationStatus:action.payload, errorNotification: false }

    case 'FAILED_TO_DELETE_ASSET_PRICE':
      return {...state, notificationStatus:action.payload, errorNotification: true }


    case 'COMPONENT_FOR_LOADER_ASSET_PRICE_TABLE_DATA':
      return {...state, loaderAssetPriceTable:action.payload}

    case 'GOT_ASSET_PRICE_DATA':
      return {...state, assetPriceTableData:action.payload}

    case 'FAILED_ASSET_PRICE_DATA':
      return {...state, assetPriceTableData:null, errorInAssetPriceTableData:action.payload} 

    case 'CHECK_DUPLICATE_ASSET':
      return {...state, checkDuplicateAsset:action.payload}

    case 'FAILED_CHECK_DUPLICATE_ASSET':
      return {...state, errorCheckDuplicateAsset:action.payload} 

    case 'OTHER_ASSET_DELETE_SUCCESSFULLY':
      return {...state, notificationStatus:action.payload, errorNotification: false }

    case 'FAILED_TO_DELETE_OTHER_ASSET':
      return {...state, notificationStatus:action.payload, errorNotification: true }

    case 'GOT_ASSET_HEAD_DETAILS_SUCCESSFULLY':
      return {...state, assetHeadDetails: action.payload }
    case 'FAILED_TO_GET_ASSET_HEAD_DETAILS':
      return {...state, assetHeadDetails: null}

    case 'EDIT_ASSET_HEAD_SUCCESSFULLY':
      return {...state, notificationStatus:action.payload, errorNotification: false }
    case 'FAILED_TO_EDIT_ASSET_HEAD':
      return {...state, notificationStatus:action.payload, errorNotification: true }
      
    case 'GOT_ARNS_DATA':
      return {...state, arnsData:action.payload}

    case 'FAILED_ARNS_DATA':
      return {...state, errorArnsData:action.payload }

    case 'COMPONENT_FOR_LOADER_ARNS_LIST': 
      return {...state, arnsDataLoader: action.payload}


    case 'GOT_CREATE_FD_TICKET_DATA':
      return {...state, notificationStatus:action.payload && action.payload.message,  errorNotification: false }

    case 'COMPONENT_FOR_LOADER_CREATE_FDTICKET':
      return {...state, loaderCreateFDTicket:action.payload}

    case 'FAILED_CREATE_FD_TICKET_DATA':
      return {...state, notificationStatus:action.payload, errorNotification: true }


    case 'GOT_TXNREVERSAL_DATA':
      return {...state, myTxnReversalsData:{data:action.payload,apiStatus:0} }

    case 'COMPONENT_FOR_LOADER_TXNREVERSALS':
      return {...state,loaderTxnReversals:action.payload}

    case 'FAILED_TXNREVERSAL_DATA':
      return {...state, myTxnReversalsData:{data:intialState.myTxnReversalsData.result,errorMsg:action.payload,apiStatus:-1
      } }

    case "GOT_BULK_UPLOAD_SHARE_AND_BOND_DATA" : 
      return { ...state, bulkUploadShareAndBond : action.payload }

    case "BULK_UPLOAD_SHARE_AND_BOND_FAILED" :
      return { ...state, bulkUploadShareAndBond : action.payload  }

    case "IMPORT_OTHER_ASSET_TXNS_SUCCESSFUL" : 
      return {...state, importOtherAsset : action.payload }

    case "FAILED_IMPORT_OTHER_ASSET_TXNS" :
      return {...state, importOtherAsset : action.payload }

    case "LOADER_BULK_UPLOAD_OTHER_ASSET" :
      return {...state, loaderBulkUploadOtherAssets : action.payload }

    case "IMPORT_CURRENT_VALUE_SUCCESSFUL" : 
      return {...state, importOtherAsset : action.payload }

    case "FAILED_IMPORT_CURRENT_VALUE" : 
      return {...state, importOtherAsset : action.payload }

    case "GOT_BULK_UPLOAD_FD_DATA" : 
      return { ...state, bulkUploadFD : action.payload }

    case "BULK_UPLOAD_FD_FAILED" :
      return { ...state, bulkUploadFD : action.payload  }

    case "LOADER_BULK_UPLOAD_FD":
      return { ...state, loaderBulkUploadFD : action.payload  }

    case "COMPONENT_LOADER_SHARE_BROKER_LIST" : 
      return { ...state, loaderShareBrokerList: action.payload }

    case "GOT_SHARE_BROKER_LIST" : 
      return { ...state, shareBrokerList : action.payload }

    case "FAILED_GETTING_SHARE_BROKER_LIST" :
      return { ...state, shareBrokerList : { message : action.payload, status: -1} }

    case "IMPORTING_CONTRACT_NOTE" : 
      return { ...state, loaderContractNote: action.payload }

    case "IMPORT_CONTRACT_SUCCESSFUL" : 
      return { ...state, contractNoteData: action.payload }

    case "FAILED_TO_IMPORT_CONTRACT" :
      return { ...state, contractNoteData: action.payload }

    case 'GOT_MY_SCHEMES_DATA':
      return {...state, mySchemesData:action.payload }

    case 'FAILED_MY_SCHEMES_DATA':
      return {...state, mySchemesData:action.payload }

    case 'COMPONENT_FOR_LOADER_MY_SCHEMES':
      return{...state,loaderMySchemes:action.payload}

    case "LOADER_SHARED_BOND" : 
      return { ...state, loaderSharedBond: action.payload }

    case "DELETE_TXN_REVERSAL_SUCCESSFULLY":
      return { ...state, notificationData: action.payload }

    case "FAILED_TO_DELETE_TXN_REVERSAL":
      return { ...state, notificationData: action.payload }

    case "SAVE_SELECTED_ROWS" : 
      return { ...state, selectedReversalRows: action.payload }

    case "ADD_MUTUAL_FUND_VARIABLES":
      return { ...state, mutualFundVariables: action.payload }

    case "ADD_MUTUAL_FUND_FORM_VALUES":
      return { ...state, mutualFundFormValues: action.payload }

    case "LOADER_MUTUAL_FUND_TXNS":
      return { ...state, loaderMFTxns: action.payload }

    case "INSERTED_MF_TXN_SUCCESSFULLY":
      return { ...state, insertMFTxnData: action.payload, notificationData: action.payload }
    case "FAILED_TO_INSERT_MF_TXN":
      return { ...state, insertMFTxnData: action.payload, notificationData: action.payload }

    case "DELETED_MF_TXN_SUCCESSFULLY":
      return { ...state, deleteMFTxnData: action.payload, notificationData: action.payload }
    case "FAILED_TO_DELETE_MF_TXN":
      return { ...state, deleteMFTxnData: action.payload, notificationData: action.payload }

    case "GOT_MF_TXNS_DATA":
      return { ...state, mfTxnsData: action.payload }
    case "FAILED_TO_GET_MF_TXNS_DATA":
      return { ...state, mfTxnsData: null, notificationData: action.payload }

    case "GOT_NAV_SUCCESSFULLY":
      return { ...state, navData: action.payload }
    case "FAILED_TO_GET_NAV":
      return { ...state, navData: null }
    
    case 'GOT_SCHEMES_NAME_FOR_FD':
        return {...state,getSchemesforFD:action.payload}
    case 'FAILED_SCHEMES_NAME_FOR_FD':
        return {...state,getSchemesforFD:action.payload}

    case 'GET_PMS_SOA_FILE':
        return {...state, soaFileName:action.payload }
    case 'FAILED_PMS_SOA_FILE':
        return {...state, soaFileName:action.payload }
    
    case 'HIDE_BULK_UPLOAD_GO_BACK_CLICK':
        return {...state, hideBulkUploadGoBackBtn:action.payload }
        
    default:
      new Error('No action type matched')
  }
  return state
}
