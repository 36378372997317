var intialState = {
    folioLookupData:{},
    folioDetailsData:{},
    checkBoxStatus: {},
    assignExistingFolio: {},
    assignNewFolio: {}
};
    
export default function dashboard(state=intialState,action){
switch (action.type) {

  
  case 'GETTING_FOLIO_LOOKUP_DATA':
    return {...state, folioLookupData:{apiStatus:1} }

  case 'GOT_FOLIO_LOOKUP_DATA':
    return {...state, folioLookupData:action.payload,apiStatus:0}

  case 'COMPONENT_LOADER_FOLIO_LOOKUP':
    return {...state, loaderFolioLookup:action.payload}

  case 'FAILED_FOLIO_LOOKUP_DATA':
      return {...state, folioLookupData:{errorMsg:action.payload,apiStatus:-1
      } }


  case 'GETTING_FOLIO_DETAILS_DATA':
    return {...state, folioDetailsData:{apiStatus:1} }

  case 'GOT_FOLIO_DETAILS_DATA':
    return {...state, folioDetailsData:{data:action.payload,apiStatus:0} }

  case 'COMPONENT_LOADER_FOLIO_LOOKUP_POPUP':
    return {...state, loaderFolio:action.payload}

  case 'FAILED_FOLIO_DETAILS_DATA':
      return {...state, folioDetailsData:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'CHECKBOX_BUTTON_CHECKED':
      return {...state, checkBoxStatus:action.payload }

  case 'GETTING_ASSIGN_EXISTING_FOLIO':
    return {...state, assignExistingFolio:{apiStatus:1} }

  case 'GOT_ASSIGN_EXISTING_FOLIO':
    return {...state, assignExistingFolio:{data:action.payload,apiStatus:0} }

  case 'COMPONENT_LOADER_EXISTING_FOLIO_POPUP':
    return{...state,loaderFolio:action.payload}
  case 'FAILED_ASSIGN_EXISTING_FOLIO':
      return {...state, assignExistingFolio:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'GETTING_ASSIGN_NEW_FOLIO':
    return {...state, assignNewFolio:{apiStatus:1} }

  case 'GOT_ASSIGN_NEW_FOLIO':
    return {...state, assignNewFolio:{data:action.payload,apiStatus:0} }

  case 'COMPONENT_LOADER_NEW_FOLIO_POPUP':
    return{...state,loaderFolio:action.payload}
  case 'FAILED_ASSIGN_NEW_FOLIO':
      return {...state, assignNewFolio:{errorMsg:action.payload,apiStatus:-1
      } }

  case 'GOT_CLIENT_FAMILY_HEAD_DATA':
    return {...state, getclientFamilyHeadData:action.payload }

  case 'FAILED_CLIENT_FAMILY_HEAD_DATA':
    return {...state, getclientFamilyHeadData:action.payload }

  case 'GOT_UPDATE_FOLIOS_DATA':
    return {...state,updateFolioData:action.payload , notificationData:action.payload}
  case 'COMPONENT_LOADER_UPDATE_FOLIOS':
    return {...state, loaderUpdateFolios:action.payload }
  case 'FAILED_UPDATE_FOLIOS_DATA':
    return {...state, updateFolioData:action.payload , notificationData:action.payload}
  
  case 'DELETE_FOLIO_EXCHANGE_MAPPING':
    return  {...state, notificationData:action.payload}
  case 'FAILED_DELETE_FOLIO_EXCHANGE_MAPPING':
    return  {...state, notificationData:action.payload}

  case 'COMPONENT_LOADER_UCC_IIN_LIST':
    return {...state, loaderUccIINList:action.payload}
  case 'GOT_IIN_LIST_DATA_FOLIO_LOOKUP':
    return {...state,IINListData:action.payload}
  case 'FAILED_IIN_LIST_DATA_FOLIO_LOOKUP':
    return {...state, IINListData:action.payload}
  case 'GOT_UCC_LIST_DATA_FOLIO_LOOKUP':
    return {...state,uccListData:action.payload}
  case 'FAILED_UCC_LIST_DATA_FOLIO_LOOKUP':
    return {...state, uccListData:action.payload}
  case 'GOT_CAN_LIST_DATA_FOLIO_LOOKUP':
    return {...state, canListData:action.payload}
  case 'FAILED_CAN_LIST_DATA_FOLIO_LOOKUP':
    return {...state, canListData:action.payload}

  case 'CLEAR_NOTIFICATION':
    return{...state,notificationData:action.payload}

  case 'TABLE_HEADING_DATA':
    return{...state,tableHeadingData:action.payload}

  case 'COMPONENT_LOADER_ALL_CLIENT':
    return {...state, loaderAllClients:action.payload }

  case 'ADD_FOLIOLOOKUP_FILTERS': 
    return{...state,folioLookupFilters: action.payload}


  case 'GOT_ASSIGN_NEW_FOLIO':
    return {...state, assignNewFolio:action.payload, notificationData:action.payload }

  case 'FAILED_ASSIGN_NEW_FOLIO':
      return {...state, assignNewFolio:action.payload, notificationData:action.payload }


  case 'DELETE_MANUAL_TXNS_SUCCESSFULLY':
    return {...state, deleteManualTxns:action.payload, notificationData:action.payload }

  case 'FAILED_DELETE_MANUAL_TXNS':
      return {...state, deleteManualTxns:action.payload, notificationData:action.payload }


    
    default:
      new Error('No action type matched in reducer')
  }
 
 return state
}
