export default  function goalPlanner(state={userGoalList:[],userFolioList:[]},action){
	switch(action.type){
		case 'SELECTED_GOAL_ID' :
			return {...state,goalid:action.payload}
		case 'GETTING_USER_GOAL' :
			return {...state,userGoalStatus:action.payload,gotGoal:false}
		case 'GOT_USER_GOAL' :
			return {...state,userGoalList:action.payload,gotGoal:true}
		case 'COMPONENT_LOADER_GET_GOAL':
			return {...state,loaderGoalPlanner:action.payload}
		case 'FAILED_GETTING_USER_GOAL' :
			return {...state,userGoalErr:action.payload}
		
		case 'GETTING_USER_FOLIO' :
			return {...state,userGoalStatus:action.payload,gotGoalDetails:false}
		case 'GOT_USER_FOLIO' :
			return {...state,userFolioList:action.payload,gotGoalDetails:true}
		case 'COMPONENT_LOADER_GET_FOLIO':
			return {...state,loaderGoalFolios:action.payload}
		case 'FAILED_GETTING_USER_FOLIO' :
			return {...state,userGoalErr:action.payload}
		case 'GETTING_GOAL_CATEGORY' :
			return {...state,userGoalStatus:action.payload}
		case 'GOT_GOAL_CATEGORY' :
			return {...state,goalCategory:action.payload}
		case 'FAILED_GETTING_GOAL_CATEGORY' :
			return {...state,savedGoal:true}
		case 'GETTING_GOAL_FOLIO_ALLOCATION' :
			return {...state,userGoalStatus:null}
		case 'GOT_GOAL_FOLIO_ALLOCATION' :
			return {...state,goalFolioAllocation:action.payload}
		case 'FAILED_GETTING_GOAL_FOLIO_ALLOCATION' :
			return {...state,savedGoal:true}
		
		case 'SAVED_GOAL' :
			return {...state,goalSaved:true}
		case 'COMPONENT_LOADER_SAVED_GOAL':
			return {...state,loaderGoalPlanner:action.payload}
		case 'SAVING_GOAL' :
			return {...state,goalSaved:false}
		case 'FAILED_SAVE_GOAL' :
			return {...state,savedGoalErr:action.payload}

		case 'GOT_DELETE_GOAL_DATA' :
			return {...state,deleteGoalData:action.payload, errorFlag: false}
		case 'COMPONENT_LOADER_DELETE_GOAL':
			return {...state,loaderDeleteGoal:action.payload}
		case 'FAILED_DELETE_GOAL_DATA' :
			return {...state,deleteGoalData:action.payload, notificationData:action.payload &&action.payload.message, errorFlag: true}
		
		case 'SAVING_GOAL_ALLOCATION' :
			return {...state,goalAllocationSaved:false}
		case 'SAVED_GOAL_ALLOCATION' :
			return {...state,goalAllocationSaved:action.payload, isAllocationUpdated: true, errorFlag: false }
		case 'FAILED_SAVE_GOAL_ALLOCATION' :
			return {...state,savedGoalAllocationErr:action.payload, isAllocationUpdated: false, notificationData:action.payload &&action.payload.message, errorFlag: true}
		case 'SUCCESS_SAVED_GOAL_ALLOCATION' :
			return{...state, goalAllocatedData:action.payload}
		case 'COMPONENT_LOADER_ALLOCATION':
			return { ...state, componentLoaderAllocation: action.payload }

		case 'GOAL_ALLOCATION' :
			return {...state,isAllocationUpdated:action.payload }

		case 'GOT_CLIENTS_GOALS_DATA' :
			return {...state,goalListData:action.payload}
		case 'COMPONENT_LOADER_GOALS_DATA' :
			return {...state,loaderGetGoalsData:action.payload}
		case 'FAILED_CLIENTS_GOALS_DATA' :
			return {...state,goalListData:action.payload}

		case 'GOT_GOAL_MAPPER_INFO_DATA' :
			return {...state,goalMapperInfoData:action.payload}
		case 'COMPONENT_LOADER_GOALS_MAPPER_DATA' :
			return {...state,loaderGoalsGraph:action.payload}
		case 'FAILED_GOAL_MAPPER_INFO_DATA' :
			return {...state,goalMapperInfoData:action.payload}

		case 'GOT_CLIENT_GOALS_DETAILS' :
			return {...state,clientGoalDetails:action.payload}
		case 'COMPONENT_LOADER_CLIENT_GOALS_DETAILS' :
			return {...state,loaderClientGoalsDetails:action.payload}
		case 'FAILED_CLIENT_GOALS_DETAILS' :
			return {...state,clientGoalDetails:action.payload}

		case 'GOT_CREATE_GOAL_PLAN_DATA' :
			return {...state,createGoalPlanData:action.payload, errorFlag: false}
		case 'COMPONENT_LOADER_CREATE_GOAL_PLAN' :
			return {...state,loaderCreateGoalPlan:action.payload}
		case 'FAILED_CREATE_GOAL_PLAN_DATA' :
			return {...state,createGoalPlanData:action.payload, notificationData:action.payload &&action.payload.message, errorFlag: true}

		case 'GOT_SAVE_OR_UPDATE_GOAL_DATA' :
			return {...state,savedGoalData:action.payload, errorFlag: false}
		case 'COMPONENT_LOADER_SAVE_OR_UPDATE_GOAL' :
			return {...state,loaderSavedGoal:action.payload}
		case 'FAILED_SAVE_OR_UPDATE_GOAL_DATA' :
			return {...state,savedGoalData:action.payload, notificationData:action.payload &&action.payload.message, errorFlag: true}
		
		case 'CLEAR_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'LOGIN_USER_TYPE':
			return{...state,userType:action.payload}

		case 'SELECTED_GOAL_PLANNER_TAB':
			return{...state,goalPlannerTab:action.payload}


		case 'SELECTED_GOAL_TYPE' :
		    return {...state, goalType: action.payload  }

		case 'SELECTED_GOAL_CATEGORY' :
		    return {...state, selectedCategory: action.payload}


		case 'GOT_FOLIO_FOR_GOAL':
			return{...state,gotFoliosForGoal:action.payload}

		case 'FAILED_FOLIO_FOR_GOAL':
			return{...state,gotFoliosForGoal:action.payload}

		case 'GOT_SB_GOAL':
			return{...state,gotSBGoals:action.payload}

		case 'FAILED_SB_GOAL':
			return{...state,gotSBGoals:action.payload}

		case 'GOT_FA_GOAL':
			return{...state,gotFAGoals:action.payload}

		case 'FAILED_FA_GOAL':
			return{...state,gotFAGoals:action.payload}

		case 'GOT_OA_GOAL':
			return{...state,gotOAGoals:action.payload}

		case 'FAILED_OA_GOAL':
			return{...state,gotOAGoals:action.payload}

		case 'GOT_INSURANCE_GOAL':
			return{...state,gotInsuranceGoals:action.payload}

		case 'FAILED_INSURANCE_GOAL':
			return{...state,gotInsuranceGoals:action.payload}

		case 'SELECTED_GOAL_DATA':
			return{...state,selectedGoal:action.payload, isAllocationUpdated: true}

		case 'RELOAD_TABLE':
			return{...state,isReloadTable:action.payload}

		case 'NOTIFICATION_MSG':
			return{...state, notificationData:action.payload && action.payload.message}

		case 'GOT_ASSET_GOAL_ALLOCATION' :
			return { ...state, assetGoalAllocation: action.payload }
		case 'FAILED_TO_GET_ASSET_GOAL_ALLOCATION' :
			return { ...state, assetGoalAllocation: null }

		case 'GOAL_STATUS' :
			return { ...state, goalStatusData: action.payload }
		case 'FAILED_GOAL_STATUS' :
			return { ...state, goalStatusData: null }

		default :
			return state;
	}
}