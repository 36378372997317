import React, {Component} from 'react'
import {connect} from 'react-redux';
import { change } from 'redux-form'

const ReCaptcha = props => {
	function onCaptchaSubmit(token){
		props.dispatch({ type: "SAVE_RECAPTCHA_TOKEN", payload: token }); 
			store.dispatch({ type: "SHOW_RECAPTCHA_POPUP", payload: -1 }); 

		// Object.keys(props.form|| {}).map((val)=>{
		// 	props.dispatch(change(val,'g-recaptcha-response',token)); 
		// })
    }
    window.onCaptchaSubmit=onCaptchaSubmit;
	return(
		<div class="popUpArea posRelative">
            <div class="popContainer">
		        <div className="g-recaptcha" data-sitekey="6LejFs4pAAAAAJsiJhxX5WyrpoHk8Evwq5CYm6u4" data-callback="onCaptchaSubmit" ></div>	
            </div>
        </div>
	)
}

const mapStateToprops = (state,props)=>{
    return {
        form: state.form
    }
}

const mapDispatchToProp = (dispatch) => {
 	return {dispatch:dispatch}
}

export default connect(mapStateToprops,mapDispatchToProp)(ReCaptcha);
