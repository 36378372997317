var initialState = {}

export default function emailLogs( state = initialState, action){

	switch(action.type){

		case 'COMPONENT_LOADER_EMAIL_LOGS' : 
			return {...state,loaderEmailLogs:action.payload}
		case 'GOT_EMAIL_LOGS_DATA' : 
			return {...state,emailLogsData:action.payload}
		case 'FAILED_EMAIL_LOGS_DATA' : 
			return {...state,emailLogsData:action.payload}

		case 'LOGIN_HISTORY_DATA' : 
			return {...state,loginHistoryData:action.payload}
		case 'FAILED_HISTORY_DATA' : 
			return {...state,loginHistoryData:action.payload}

		default:
        new Error('No action type matched in reducer')
	}

	return state
}