var initialState = {}

export default function taskManager( state = initialState, action){
    switch( action.type ){

        case 'COMPONENT_LOADER_PORTFOLIO_SCREENER_DATA' :
            return { ...state, loaderPortfolioScreener : action.payload }
        case 'GOT_PORTFOLIO_SCREENER_DATA' :
            return {...state, portfolioScreenerData : action.payload }
        case 'FAILED_TO_GET_PORTFOLIO_SCREENER_DATA' :
            return {...state, portfolioScreenerData : { status : -1, message: action.payload } }
        case 'SUCCESSFULLY_DOWNLOAD_UNREALISED_REPORT':
        case 'FAILED_TO_DOWNLOAD_UNREALISED_REPORT':
            return { ...state, capitalGainUnRealised: action.payload }
        case "ADD_PORTFOLIO_SCREENER_FILTERS":
            return { ...state, portfolioScreenerFilters: action.payload  }

        default:
        new Error('No action type matched in reducer')
    }
    return state
}