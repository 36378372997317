let initialState = {}
 
export default function casImportReducer(state=initialState,action){
    
    switch(action.type){ 
     
        case 'IMPORTED_CAS_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, successStepNo: action.stepNo, importData: action.payload&&action.payload.result }
        case 'FAILED_TO_IMPORT_CAS':
            return { ...state, notificationData: action.payload }
        case 'COMPONENT_LOADER_UPLOAD_CAS':
            return { ...state, loaderUploadCas: action.payload, loaderCasUploadLogs: action.payload }
        case 'CLEAR_SUCCESSFUL_STEP':
            return { ...state, successStepNo: null }

        case 'COMPONENT_LOADER_CAS_UPLOAD_LOGS':
            return { ...state, loaderCasUploadLogs: action.payload }
        case 'GOT_CAS_UPLOAD_LOGS_SUCCESSFULLY':
            return { ...state, casImportLogs: action.payload }
        case 'FAILED_TO_GET_CAS_UPLOAD_LOGS':
            return { ...state, casImportLogs: [] }

        case 'COMPONENT_LOADER_CAS_DATA':
            return { ...state, loaderCasUploadData: action.payload }
        case 'GOT_CAS_DATA_SUCCESSFULLY':
            return { ...state, casData: action.payload }
        case 'FAILED_TO_GET_CAS_DATA':
            return { ...state, casData: [] }

        case 'DELETED_CAS_TXNS_SUCCESSFULLY':
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_DELETE_CAS_TXNS':
            return { ...state, notificationData: action.payload }

        case 'GOT_SCHEMES_NAME_MULTIPLE':
        case 'FAILED_SCHEMES_NAME_MULTIPLE':
            return { ...state, [`schemeData${action.calledFrom}`]: action.payload}
        case 'COMPONENT_LOADER_SCHEMES_MULTIPLE':
            return { ...state, [`schemeLoader${action.calledFrom}`]: action.payload }

        case 'IMPORTED_REJECTIONS_SUCCESSFULLY':
            return { ...state, notificationData: action.payload, successStepNo: 1, importData: action.payload&&action.payload.result }
        case 'FAILED_TO_IMPORT_REJECTIONS':
            return { ...state, notificationData: action.payload }
        case 'COMPONENT_LOADER_CAS_IMPORT_REJECTIONS':
            return { ...state, loaderUploadCas: action.payload, loaderCasUploadLogs: action.payload }

        case 'GOT_SCHEMES_NAME' :
            return { ...state, getSchemesData : action.payload}
        case 'COMPONENT_LOADER_SCHEMES' :
            return { ...state, loaderForSchemes : action.payload}
        case 'FAILED_SCHEMES_NAME' :
            return { ...state, getSchemesData : {errorMsg : action.payload } }

        case 'GOT_MF_CENTRAL_DATA':
            return{...state, mfCentralData : action.payload, notificationData: action.payload}
        case 'FAILED_MF_CENTRAL_DATA':
            return{...state, mfCentralData : action.payload, notificationData: action.payload}

        case 'COMPONENT_LOADER_MF_CENTRAL_DATA':
            return{...state , mfCentralLoader : action.payload}

        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }
           
        default:
            new Error('No action type matched in reducer')
    }

    return state; 
} 