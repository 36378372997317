var initialState = {}

export default function transactionsViewReducer( state = initialState, action){

	switch(action.type){

		case "ADD_TRANSACTION_VIEW_FILTERS":
	      	return { ...state, transactionsViewFilters: action.payload }

		case 'COMPONENT_LOADER_TRANSACTIONS_PURCHASE' : 
			return { ...state, loaderPurchaseTxns: action.payload }
		case 'GOT_TRANSACTIONS_DATA_PURCHASE' : 
			return { ...state, purchaseTransactions: action.payload }
		case 'FAILED_TRANSACTIONS_DATA_PURCHASE' : 
			return { ...state, purchaseTransactions: [] }

		case 'COMPONENT_LOADER_TRANSACTIONS_SELL' : 
			return { ...state, loaderSellTxns: action.payload }
		case 'GOT_TRANSACTIONS_DATA_SELL' : 
			return { ...state, sellTransactions: action.payload }
		case 'FAILED_TRANSACTIONS_DATA_SELL' : 
			return { ...state, sellTransactions: [] }

		case 'GOT_SHARE_SCHEMES_TXNS_DATA_PURCHASE' : 
			return { ...state, purchaseTransactions: action.payload }
		case 'FAILED_SHARE_SCHEMES_TXNS_DATA_PURCHASE' : 
			return { ...state, purchaseTransactions: [] }

		case 'GOT_SHARE_SCHEMES_TXNS_DATA_SELL' : 
			return { ...state, sellTransactions: action.payload }
		case 'FAILED_SHARE_SCHEMES_TXNS_DATA_SELL' : 
			return { ...state, sellTransactions: [] }

		case 'GOT_FD_TXNS_DATA' : 
			return { ...state, fdTxnsData: action.payload }
		case 'FAILED_FD_TXNS_DATA' : 
			return { ...state, fdTxnsData: [] }

		case 'GOT_OTHER_ASSET_TXNS_DATA' : 
			return { ...state, otherAssetTxns: action.payload }
		case 'FAILED_OTHER_ASSET_TXNS_DATA' : 
			return { ...state, otherAssetTxns: [] }

		case 'CLEAR_TRANSACTIONS_DATA' :
			return { ...state, purchaseTransactions: [], sellTransactions: [], otherAssetTxns: [], fdTxnsData: []} 

		default:
        new Error('No action type matched in reducer')
	}

	return state
}