var intialState = {clientSummary:{},clientSummaryData:{},fundAssetAllocation:{},purchaseTxns:{},sellTxns:{},purchaseTxnsGraph:{}};
export default function clientDashboard(state=intialState,action){
	switch(action.type){
		/*case 'GETTING_CLIENT_SUMMARY':
			return{...state,clientSummary:"Getting Client Summary"}
		case 'GOT_CLIENT_SUMMARY':
			return{...state,clientSummary:action.payload}
		case 'COMPONENT_LOADER_DASHBOARD_CLIENT':
			return {...state,clientLoaderDashboardTable:action.payload}
		case 'FAILED_CLIENT_SUMMARY':
			return{...state,clientSummary:"Unable To fetch Client Summary"}*/

		case 'GOT_CLIENT_DASHBOARD_SUMMARY':
			return{...state,clientSummaryData:action.payload}
		case 'COMPONENT_LOADER_CLIENT_DASHBOARD':
			return {...state,clientLoaderDashboardTable:action.payload}
		case 'FAILED_CLIENT_DASHBOARD_SUMMARY':
			return{...state,clientSummaryData:action.payload}
  
		case 'GETTING_FUND_ASSET_ALLOCATION':
			return{...state,fundAssetAllocation:"Getting Client Summary"}
		case 'GOT_FUND_ASSET_ALLOCATION':
			return{...state,fundAssetAllocation:action.payload}
		case 'COMPONENT_LOADER_DASHBOARD_FUND':
			return {...state,fundLoaderDashboardTable:action.payload}
		case 'FAILED_FUND_ASSET_ALLOCATION':
			return{...state,fundAssetAllocation:"Unable To fetch Client Summary"}
		case 'GOT_PURCHASE_TXNS':
			return{...state,purchaseTxns:action.payload}
		case 'COMPONENT_LOADER_DASHBOARD_PURCHASE':
			return {...state,loaderDashboardTable:action.payload}
		case 'FAILED_PURCHASE_TXNS':
			return{...state,purchaseTxns:"Unable To fetch Client Summary"}
		
		case 'GETTING_SELL_TXNS':
			return{...state,sellTxns:"Getting Client Summary"}
		case 'GOT_SELL_TXNS':
			return{...state,sellTxns:action.payload}
		case 'COMPONENT_LOADER_DASHBOARD_SELL':
			return {...state,loaderDashboardTable:action.payload}
		case 'FAILED_SELL_TXNS':
			return{...state,sellTxns:"Unable To fetch Client Summary"}
		
		case 'GETTING_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:"Getting Client Summary"}
		case 'GOT_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:action.payload}
		case 'COMPONENT_LOADER_DASHBOARD_PGRAPH':
			return {...state,graphLoaderDashboardTable:action.payload}
		case 'FAILED_PURCHASE_TXNS_GRAPH':
			return{...state,purchaseTxnsGraph:"Unable To fetch Client Summary"}

		case 'COMPONENT_LOADER_GET_CLIENT_INFORMATION':
			return { ...state, loaderProfilePopup: action.payload }
		case 'GOT_CLIENT_INFORMATION':
			return { ...state, clientInformation: action.payload }
		case 'FAILED_TO_GET_CLIENT_INFORMATION':
			return { ...state, clientInformation: null }

		case 'UPDATED_CLIENT_SUCCESSFULLY':
			return { ...state, notificationData: action.payload }
		case 'FAILED_TO_UPDATE_CLIENT':
			return { ...state, notificationData: action.payload }
		case 'CLOSE_NOTIFICATION':
			return { ...state, notificationData: null }

		case 'COMPONENT_LOADER_ALL_ASSETS':
			return { ...state, allAssetsLoader: action.payload }
		case 'ALL_ASSETS':
			return { ...state, allAssetsData : action.payload , apiStatus:true}
		case 'FAILED_ALL_ASSETS':
			return { ...state, allAssetsData : action.payload }
		case 'GOT_CLIENT_SCHEMES':
			return { ...state, clientSchemes : action.payload }
		case 'FAILED_TO_GOT_CLIENT_SCHEMES':
			return { ...state, clientSchemes : action.payload }

		case 'SUCCESS_NPS_PAN_VALIDATION':
			return { ...state, npsPanValidation: action.payload }
		case 'FAILED_NPS_PAN_VALIDATION':
			return { ...state, npsPanValidation: action.payload }

		case 'COMPONENT_LOADER_FOR_INVESTMENT':
			return { ...state, loaderForInvestment: action.payload }

		case 'CALCULATE_TOTAL_CONTRIBUTION':
			return { ...state, calculateTotalContribution: action.payload }
		case 'FAILED_CALCULATE_TOTAL_CONTRIBUTION':
			return { ...state, calculateTotalContribution: action.payload }

		case 'SUCCESS_PRAN_CREATION':
			return { ...state, notificationData: action.payload }
		case 'FAILED_PRAN_CREATION':
			return { ...state, notificationData: action.payload }
		case 'GOT_ASSET_ALLOCATION_DATA':
			return { ...state, assetAllocationData: action.payload }
		case 'FAILED_ASSET_ALLOCATION_DATA':
			return { ...state, assetAllocationData: action.payload }	
		case 'GOT_HOLDING_ASSET_ALLOCATION_DATA':
			return { ...state, holdingAssetAllocationData: action.payload }
		case 'FAILED_HOLDING_ASSET_ALLOCATION_DATA':
			return { ...state, holdingAssetAllocationData: action.payload }
		case 'COMPONENT_LOADER_IS_ALLOWED_TRANSACTIONS':
			return { ...state, isAllowedTxnLoader: action.payload }
		case 'GOT_IS_ALLOWED_TRANSACTION':
		case 'FAILED_GOT_IS_ALLOWED_TRANSACTION':
			return { ...state, isTxnFlagAllowed: action.payload }
		case 'REDIRECTION_ON_SELECTED_PAGE' :
			return {...state, selectedPageRedirectionData:action.payload}
		default:
      		new Error('No action type matched in Filter reducer')
	}
	return state;
}