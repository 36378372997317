var intialState = {};
export default function adminOrders(state=intialState,action){
	switch(action.type){
        case 'COMPONENT_LOADER__FOR_ORDERS':
            return {...state, ordersDataLoader: action.payload }
        case 'GOT_ORDERS':
             return {...state, ordersData: action.payload }
        case 'FAILED_TO_GET_ORDERS':
            return {...state, ordersData: action.payload }
            
		default:
      		new Error('No action type matched in reducer')
	}
	return state;
}
