export default function kycOnBoardingReducer(state = {}, action) {

    switch (action.type) {
        case 'LOADER_FOR_KYC_FORM':
            return { ...state, kycOnboardLoader: action.payload }
        case 'SUCCESS_TO_SING_UP':
        case 'FAILED_TO_SING_UP':
            return { ...state, kycOnboardSignUp: action.payload, kycNotification : action.payload }
        case 'SUCCESS_TO_VALIDATE_OTP':
        case 'FAILED_TO_VALIDATE_OTP':
            return { ...state, kycOtpValidate: action.payload , kycNotification : action.payload }
        case 'SUCCESS_TO_UPDATE_CLIENT_ON_BOARDING':
            return { ...state, clientOnboarding: action.payload , kycNotification : action.payload, getAuthorizationToken : action.getAuthorizationToken }
        case 'FAILED_TO_UPDATE_CLIENT_ON_BOARDING':
            return { ...state, clientOnboarding: action.payload , kycNotification : action.payload }
        case 'SUCCESS_TO_GET_SIGNZY_DETAILS':
        case 'FAILED_TO_GET_SIGNZY_DETAILS':
            return { ...state, getSignzyDetails: action.payload , kycNotification : action.payload }
        case 'PAN_KYC_CHECK_LOADER':
            return { ...state, panKycCheckLoader : action.payload}
        case 'SUCCESS_TO_GET_PAN_KYC':
        case 'FAILED_TO_GET_PAN_KYC':
            return { ...state, panKycStatus: action.payload }
        case 'GOT_COUNTRY_DETAILS':
        case 'FAILED_COUNTRY_DETAILS':
            return { ...state, exchangeCountryList: action.payload}
        case 'GOT_STATE_DETAILS':
        case 'FAILED_STATE_DETAILS':
            return { ...state, exchangeStateList: action.payload }
        case 'SUCCESS_TO_DOWNLOAD_IMAGE':
        case 'FAILED_TO_DOWNLOAD_IMAGE':
            return { ...state, kycDownloadImg: action.payload,kycNotification : action.payload }
        case 'SUCCESS_TO_UPLOAD_IMAGE':
        case 'FAILED_TO_UPLOAD_IMAGE':
            return { ...state, kycUploadImg: action.payload }
        case 'GOT_BANK_LIST':
        case 'FAILED_BANK_LIST':
            return { ...state, exchangeBankList: action.payload }
        case 'GOT_BANK_DETAILS':
        case 'FAILED_BANK_DETAILS':
            return { ...state, bankDetails : action.payload }
        case 'GOT_BANK_ACCOUNT_TYPE':
        case 'FAILED_BANK_ACCOUNT_TYPE':
            return { ...state, accountyTypeList: action.payload }
        case 'GOT_ON_BOARDING_DEATILS':
            return { ...state, getOnBoardingDetails : action.payload , exchange : action.exchange}
        case 'FAILED_ON_BOARDING_DEATILS':
            return { ...state, getOnBoardingDetails : action.payload }
        case 'LOADER_FOR_GET_ON_BOARDING':
            return { ...state, loaderForGetOnBoarding : action.payload }
        case 'UPLOAD_KYC_LOADER':
            return { ...state, uploadLoader : action.payload }
        case 'DOWNLOAD_KYC_LOADER':
            return { ...state, downloadLoader : action.payload }
        case 'LOADER_FOR_GET_SIGNZY_DETAILS':
            return {...state, signzyLoader : action.payload}
        case 'INDEX_OF_DOWNLOAD':
            return { ...state, indexOfUpload : action.payload }
        case 'CLEAR_KYC_NOTIFICATIONS':
            return { ...state, kycNotification : action.payload }
        case 'EDIT_KYC_ONBOARDING_FORM':
            return { ...state, editKycOnBoardingForm : action.payload }
        case 'LOADER_FOR_KYC_PENDING_TABLE':
            return {...state, loderForPendingTable : action.payload}
        case 'GOT_KYC_PENDING_TABLE_DATA':
        case 'FAILED_KYC_PENDING_TABLE_DATA':
        return {...state, kycPendingTableData : action.payload}
        case 'LOADER_FOR_KYC_UPDATE_FORM':
            return {...state, kycUpdateFormLoader : action.payload}
        case 'SUCESSFULLY_UPDATED_KYC_FORM':
        case 'FAILED_TO_UPDATED_KYC_FORM':
        return {...state, kycUpdateFormData : action.payload,kycNotification : action.payload}
    }
    return state
}