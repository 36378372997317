import axios from 'axios'

export const loginRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/login',
    data:data
  })
}
export const signupRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/signup',
    data:data
  })
}

export const logoutRepo = () => {
  return axios({
    method: 'GET',
    url:'/api/auth/logout',
  })
}

export const getUserThemeRepo = (data) => {
  return axios({
    method: 'GET',
    url: '/api/op/getBrokerPublicInfo',
    params:data
  })
}
export const continueLoginRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/stillAlive',
    data:data
  })
}

export const passResetMailRepo = (data) => {
  return axios({
    method : 'POST',
    url : '/api/auth/sendPasswordResetMail',
    data : data
  })
}

export const checkTokenRepo = (data) => {
  return axios({
    method : 'GET',
    url : '/api/auth/checkTokenValidity',
    params : data
  })
}

export const resetPasswordRepo = (dada) =>{
  return axios({
    method : 'POST',
    url: '/api/auth/resetPassword',
    data :  dada
  })
}

export const adminToBrokerLoginRepo = (data) => {
  return axios({
    method: 'POST',
    url: '/api/auth/adminToBrokerLogin',
    data: data
  })
}

export const getAdminToBrokerLoginHistoryRepo = (data) => {
  return axios({
    method: 'GET',
    url: '/api/admin/getAdminToBrokerLoginHistory',
    params: data
  })
}

export const loginWithGoogleRepo = (data, headerParams) => {
  return axios({
    method: 'POST',
    url: '/api/auth/googleSignIn',
    data: data,
    headers: headerParams
   })
}
export const sendOTPRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/sendOTP',
    data:data
  })
}
export const sendMobileOTPRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/loginMobileOTP',
    data:data
  })
}

export const confirmOTPRepo = (data) => {
  return axios({
    method: 'POST',
    url:'/api/auth/authenticateOTP',
    data:data
  })
}

export const GetOTPExpiryRepo=(data)=> {
  return axios({
    method: 'GET',
    url:'/api/auth/getOtpExpiryTime',
    params: data
  })
}
export const checkMaintainance = (data) =>{
  return axios({
    method : 'GET',
    url : '/api/auth/checkSystemAvailability',
    params : data
  })
}
export const subscribeApiRepo = (data) => {
  return axios({
    method : 'POST',
    url : '/api/op/ceaseCommunication',
    data : data
  })
}