import React from 'react'
import {PAGINATION_SIZE} from 'app/constants/shared/commonConst'

export const stringifyFilter = (data,pagination, removestringify)=>{
	if (!pagination) {
		if(!data.pageSize){
	    	data.pageSize = PAGINATION_SIZE && PAGINATION_SIZE.defaultSize;
	   	}
	   	if(!data.currentPage){
	     	data.currentPage = 1;
	   	}
   	}
	if(data.filters && typeof data.filters != 'string'){
	  let filters=[];
	  let index = 0;
	    for(var key in data.filters){
	    	if(data.filters[key] || data.filters[key] == ''  ){
	      		filters[index++] = {[key]:data.filters[key]}
	  		}		
	    }
	  data.filters = removestringify ? filters : JSON.stringify(filters);
	}
	return data
}
export const removeNullParam = (data)=>{
	if(!data.pageSize){
    	data.pageSize = PAGINATION_SIZE && PAGINATION_SIZE.defaultSize;
   	}
   	if(!data.currentPage){
     	data.currentPage = 1;
   	}
    for(var key in data){
    	if(!data[key]){
      		delete data[key]
  		}		
	}
	return data
}
export const filtersInArray = (data)=>{
	if(data.filters && typeof data.filters != 'string'){
	  let filters=[];
	  let index = 0;
	    for(var key in data.filters){
	    	if(data.filters[key] || data.filters[key] == ''  ){
	      		filters[index++] = {[key]:data.filters[key]}
	  		}		
	    }
	  data.filters = filters;
	}
	return data
}

export const nameWithPan = (array) => {
	if (!(array && array.length))
		return
	return array && array.map((obj) => {
		obj.nameWithPan = `${obj.name} ${obj.pan ? ` / ${obj.pan}` : ''}`
		return obj
	})
}
export const stringifyArray = (data,key)=>{
	data[key] = JSON.stringify(data[key])
	return data
}
export const trimObjectValues = (obj) => {
	if (typeof obj !== 'object' || obj === null) return obj;

	const trimmedObj = {};
	for (const [key, value] of Object.entries(obj)) {
		if(Array.isArray(value)){
			trimmedObj[key] = value.map((obj) => trimObjectValues(obj))
		}
		else{
			trimmedObj[key] = getTrimSingleValue(value)
		}
	}
	return trimmedObj;
  };
export const getTrimSingleValue = (value) => {
	return (typeof value === 'string') ? value.trim().replace(/\s+/g, ' ') : value;
}
 