export default function onBoardingReducer(state, action) {
    switch (action.type) {
        case "CHANGE_STEP_NO":
            return { ...state, stepNo: action.payload };

        case 'SAVE_NSE_ID':
            return { ...state, nseid: action.payload };

        case "ADD_PERSONAL_INFO_VARIABLES":
            return { ...state, personalInfoVariables: action.payload };

        case "ADD_ADDRESS_FORM_VARIABLES":
            return { ...state, addressFormVariables: action.payload };

        case "ADD_BANK_DETAILS_VARIABLES":
            return { ...state, bankDetailVariables: action.payload };

        case 'ADD_FATCA_FORM_VARIABLES':
            return { ...state, fatcaFormVariables: action.payload };

        case 'ADD_SUBMIT_DOCUMENT_VARIABLES':
            return { ...state, submitDocumentVariables: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING':
            return { ...state, loaderOnBoardingComponent: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING_NSE':
            return { ...state, loaderOnBoardingComponentNSE: action.payload };
    
        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }

        case 'CREATED_IIN_SUCCESSFULLY':
            return { ...state, iinCreationData: action.payload && action.payload.result, notificationData: action.payload };
        case 'FAILED_TO_CREATE_IIN':
            return { ...state, iinCreationData: null, notificationData: action.payload };

        case 'GOT_IINS_BY_APPID':
            return { ...state, nseDetailsByAppid: action.payload }
        case 'FAILED_TO_GET_IINS_BY_APPID':
            return { ...state, nseDetailsByAppid: null }

        case 'GOT_IIN_TEMP_DATA':
            return { ...state, iinTempData: action.payload }
        case 'FAILED_TO_GET_IIN_TEMP_DATA':
            return { ...state, iinTempData: null } 

        case 'GOT_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: action.payload && action.payload.result, notificationData: action.payload }
        case 'FAILED_TO_GET_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: null, notificationData: action.payload } 

        case 'COMPONENT_LOADER_COUNTRY_MASTER':
            return { ...state, loaderCountries: action.payload }
        case 'GOT_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: action.payload };
        case 'FAILED_TO_GET_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: null };

        case 'GOT_CLIENT_INFORMATION_SUCCESSFULLY':
            return { ...state, clientInformation: action.payload };
        case 'FAILED_TO_GET_CLIENT_INFORMATION':
            return { ...state, clientInformation: null };

        case 'CHECKED_KYC_SUCCESSFULLY':
            return { ...state, checkKycData: action.payload  };
        case 'FAILED_TO_CHECK_KYC':
            return { ...state, checkKycData: null };


        case 'GOT_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: action.payload }
        case 'FAILED_TO_GET_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: null };

        case 'COMPONENT_LOADER_BANKS_MASTER':
            return { ...state, loaderBanks: action.payload }
        case 'GOT_BANKS_MASTER_DATA':
            return { ...state, banksMaster: action.payload }
        case 'FAILED_TO_GET_BANKS_MASTER_DATA':
            return { ...state, banksMaster: null };

        case 'GOT_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: action.payload }
        case 'FAILED_TO_GET_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: null };

        case 'COMPONENT_LOADER_STATE_MASTER':
            return { ...state, loaderStates: action.payload }
        case 'GOT_STATE_MASTER_DATA':
            return { ...state, stateMaster: action.payload }
        case 'FAILED_TO_GET_STATE_MASTER_DATA':
            return { ...state, stateMaster: null };

        case 'COMPONENT_LOADER_APPLICABLE_INCOMES':
            return { ...state, loaderApplicableIncomes: action.payload }
        case 'GOT_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: action.payload }
        case 'FAILED_TO_GET_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: null };

        case 'GOT_IIN_HOLDERS_AND_BANKS':
            return { ...state, holdersAndBanks: action.payload }
        case 'FAILED_TO_GET_IIN_HOLDERS_AND_BANKS':
            return { ...state, holdersAndBanks: null };



        case 'COMPONENT_LOADER_SUBMIT_DOCUMENT':
            return { ...state, loaderSubmitDocument: action.payload }

        case 'UPLOADED_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }


        case 'SUBMIT_NSE_CREATION_DOCUMENT_SUCCESSFUL':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
        case 'FAILED_TO_SUBMIT_NSE_CREATION_DOCUMENT':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }

        case 'UPLOAD_CHEQUES_SUCCESSFULLY':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_CHEQUES':
            return { ...state, submitDocumentData: action.payload, notificationData: action.payload }
            

        case 'CHECKED_NSE_FATCA_SUCCESSFULLY':
            return { ...state, checkFatcaData: action.payload }
        case 'FAILED_TO_CHECK_NSE_FATCA':
            return { ...state, checkFatcaData: null }


        case 'SUBMITTED_NSE_FATCA_SUCCESSFULLY':
            return { ...state, nseFatcaResponse: action.payload, notificationData: action.payload }
        case 'FAILED_TO_SUBMIT_NSE_FATCA':
            return { ...state, nseFatcaResponse: null, notificationData: action.payload }

        case 'GOT_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}
        case 'FAILED_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}

        case 'ONBOARDING_EDIT_FATCA_DETAILS_DATA_NSE':
            return{...state,editFatcaDetails:action.payload}


        case 'CLEAR_ONBOARDING_STORE':
            return {}

        default:
            return { ...state }
    }
}