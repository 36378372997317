var intialState = {
    transmissionOut:{},
    transmissionIn: {},
    consolidationOut: {},
    consolidationIn: {},
    rejectionConsolidation: {},
    rejectionTransmission: {}
}
export default function adminDashboard(state=intialState,action){
	switch(action.type){	
        case 'GETTING_TRANSMISSION_OUT_DATA':
            return {...state,}
        case 'GOT_TRANSMISSION_OUT_DATA':
                return {...state, transmissionOut:action.payload }
        case 'COMPONENT_LOADER_TRANSMISSION_DATA':
            return{...state,loaderHandleException:action.payload}
        case 'FAILED_TRANSMISSION_OUT_DATA':
            return {...state, transmissionOut:{errorMsg:action.payload} }

        case 'GETTING_TRANSMISSION_IN_DATA':
            return {...state,}
        case 'GOT_TRANSMISSION_IN_DATA':
                return {...state, transmissionIn:action.payload }
        case 'FAILED_TRANSMISSION_IN_DATA':
            return {...state, transmissionIn:{errorMsg:action.payload} }

        case 'GETTING_CONSOLIDATION_OUT_DATA':
            return {...state,}
        case 'GOT_CONSOLIDATION_OUT_DATA':
                return {...state, consolidationOut:action.payload }
        case 'COMPONENT_LOADER_CONSOLIDATION_DATA':
            return{...state,loaderHandleException:action.payload}
        case 'FAILED_CONSOLIDATION_OUT_DATA':
            return {...state, consolidationOut:{errorMsg:action.payload} }

        case 'GETTING_CONSOLIDATION_IN_DATA':
            return {...state,}
        case 'GOT_CONSOLIDATION_IN_DATA':
                return {...state, consolidationIn:action.payload }
        case 'FAILED_CONSOLIDATION_IN_DATA':
            return {...state, consolidationIn:{errorMsg:action.payload} }

        case 'GETTING_REJECTION_TRANSMISSION_DATA':
            return {...state, apiStatus: 1}
        case 'GOT_REJECTION_TRANSMISSION_DATA':
                return {...state, rejectionTransmission:{data: action.payload, apiStatus: 0} }
        case 'COMPONENT_LOADER_REJECTION_TRANSMISSION_DATA':
            return{...state,loaderHandleException:action.payload}
        case 'FAILED_REJECTION_TRANSMISSION_DATA':
            return {...state, rejectionTransmission:{errorMsg:action.payload, apiStatus:-1} }
        case 'REJECTION_TRANSMISSION_DATA_HANDLE':
            return {...state, rejectionTransmission: null }

        case 'GET_TRASMISSION_HISTORY':
        case 'FAILED_TRASMISSION_HISTORY':
            return { ...state, transmissionHistory: action.payload}

        case 'GET_DELETE_TRASMISSION_HISTORY':
        case 'FAILED_DELETE_TRASMISSION_HISTORY':
            return { ...state, deleteTransmissionHistory: action.payload}
    
        case 'GET_TRANS_CONSOL_DATA' : 
            return {...state, getTransConsolData : action.payload}

        case 'GETTING_REJECTION_CONSOLIDATION_DATA':
            return {...state,apiStatus: 1}
        case 'GOT_REJECTION_CONSOLIDATION_DATA':
                return {...state, rejectionConsolidation:{data: action.payload, apiStatus: 0} }
        case 'COMPONENT_LOADER_REJECTION_CONSOLIDATION_DATA':
            return{...state,loaderHandleException:action.payload}
        case 'FAILED_REJECTION_CONSOLIDATION_DATA':
            return {...state, rejectionConsolidation:{errorMsg:action.payload, apiStatus:-1} }

        case 'GETTING_BROKERS_WITH_TRANSMISSION':
            return {...state, apiStatus: 1 }
            break
        case 'COMPONENT_LOADER_BROKERS_WITH_TRANSMISSION':
            return{...state,loaderHandleException:action.payload}
        case 'GOT_BROKERS_WITH_TRANSMISSION':
            return {
                ...state, 
                brokersWithTransmissions: {
                    data:action.payload.map(broker => ({label: broker.domain, value: broker.bid})), 
                    apiStatus:0
                } 
            }
            break
        case 'FAILED_BROKERS_WITH_TRANSMISSION':
            return {...state, brokersWithTransmissions:{errorMsg:action.payload, apiStatus:-1} }
            break
		default:
      		new Error('No action type matched in reducer')
	}
	return state;
}