var intialState = {}    
    
export default function clientReducer(state=intialState,action){

    switch (action.type) {

        case 'GOT_FAMILY_HEAD_EMAIL' :
            return {...state, familyHeadEmail : action.payload }

        case 'FAILED_GETTING_FAMILY_HEAD_EMAIL' :
            return {...state, familyHeadEmail : action.payload }

        case 'SENT_REPORT_EMAIL' :
            return {...state, notificationStatus : action.payload , errorNotification : false }

        case 'FAILED_SENDING_REPORT_EMAIL' :
            return {...state, notificationStatus : action.payload , errorNotification : true }

        case 'FAILED_SENDING_REPORT_WHATSAPP':
            return { ...state, notificationStatus: action.payload, errorNotification: true }

        case 'COMPONENT_LOADER_GET_FAMILYHEAD_EMAIL' :
            return {...state, loaderSendReport : action.payload }

        case 'CLEAR_NOTIFICATION' : 
            return {...state, notificationStatus : null , errorNotification : false}

        case 'GOT_ARNS' :
            return {...state, arnsList:action.payload && action.payload.data }

        case 'FAILED_TO_GET_ARNS' :
            return {...state, arnsList:action.payload }

        case 'LOADER_GET_ARNS' :
            return {...state, loaderSendReport : action.payload }

        case 'GOT_CATEGORY_LIST_DATA':
            return { ...state, categoryList: action.payload }
        case 'COMPONENT_LOADER_CATEGORY_LIST':
            return { ...state, loaderCategoryList: action.payload }
        case 'FAILED_CATEGORY_LIST_DATA':
            return { ...state, categoryList: [] }

        case 'GOT_MY_SCHEMES_DATA':
            return {...state, schemeList: action.payload }
        case 'FAILED_MY_SCHEMES_DATA':
            return {...state, schemeList: {} }
        case 'COMPONENT_FOR_LOADER_MY_SCHEMES':
            return{...state, loaderMySchemes: action.payload}

        case 'ADD_PORTFOLIO_SUMMARY_FILTERS':
            return { ...state, portfolioSummaryFilters: action.payload }

        case 'SAVED_REPORT_PREFERENCES_SUCCESSFULLY':
            return { ...state,  notificationStatus : action.payload , errorNotification : false }
        case 'FAILED_TO_SAVE_REPORT_PREFERENCES':
            return { ...state,  notificationStatus : action.payload , errorNotification : true  }
       
        case 'GOT_REPORT_PREFERENCES':
            return { ...state,  reportPreferences : action.payload  }
        case 'FAILED_TO_GET_REPORT_PREFERENCES':
            return { ...state,  reportPreferences : null }

        case 'COMPONENT_LOADER_REPORT_PREFERENCES':
		case 'PV_LOADER_FOR_POST_CALL':
            return { ...state, loaderReportPreferences: action.payload } 

        case 'GOT_SCHEMES':
            return { ...state,  schemesList : action.payload  }
        case 'FAILED_TO_GET_SCHEMES':
            return { ...state,  schemesList : null }

        case 'GOT_TO_SCHEMES':
            return { ...state,  toSchemesList : action.payload  }
        case 'FAILED_TO_GET_TO_SCHEMES':
            return { ...state,  toSchemesList : null }


        case 'ADD_PROFIT_AND_LOSS_FILTERS':
            return { ...state, profitAndLossFilters: action.payload }

        case 'GOT_PORTFOLIO_SNAPSHOT_DATA':
        case 'FAILED_PORTFOLIO_SNAPSHOT_DATA':
            return { ...state, portfolioSnapshotData: action.payload }
        case 'GOT_SINCE_INCEPTION_DATA':
        case 'FAILED_SINCE_INCEPTION_DATA':
            return { ...state, sinceInceptionData: action.payload }
        case 'TABLE_LOADER_PORTFOLIO_SNAPSHOT':
            return { ...state, portfolioSnapshotTableLoader: action.payload }
        case 'TABLE_LOADER_SINCE_INCEPTION':
            return { ...state, sinceInceptionTableLoader: action.payload }
        case 'GOT_FOLIO_DATA_LIST':
            return { ...state,  foliosData : action.payload  }
        case 'FAILED_FOLIO_DATA_LIST':
            return { ...state,  foliosData : null }

        case 'GOT_CATEGORY_DATA_LIST':
            return { ...state,  categoryData : action.payload  }
        case 'FAILED_CATEGORY_DATA_LIST':
            return { ...state,  categoryData : null }

        case 'GOT_SUB_CATEGORY_DATA_LIST':
            return { ...state,  subCategoryData : action.payload  }
        case 'FAILED_SUB_CATEGORY_DATA_LIST':
            return { ...state,  subCategoryData : null }

        case 'GOT_FUNDS_DATA_LIST':
            return { ...state,  fundDataList : action.payload  }
        case 'FAILED_FUNDS_DATA_LIST':
            return { ...state,  fundDataList : null }

        case 'GOT_CLIENT_WATCHLIST_DATA':
        case 'FAILED_CLIENT_WATCHLIST_DATA':
            return { ...state, clientWatchlistData: action.payload }

        case 'POST_PV_REPORT_PDF':
        case 'FAILED_POST_PV_REPORT_PDF':
            return { ...state, postPVReportPdf: action.payload }

        case 'POST_PV_REPORT_MF':
        case 'FAILED_POST_PV_REPORT_MF':
            return { ...state, postPVReportMf: action.payload }

        case 'POST_PV_REPORT_XLS':
        case 'FAILED_POST_PV_REPORT_XLS':
            return { ...state, postPVReportXls: action.payload }

        case 'POST_PV_REPORT_DETAILED_XLS':
        case 'FAILED_POST_PV_REPORT_DETAILED_XLS':
            return { ...state, postPVReportDetailedXls: action.payload }

        case 'GOT_COMBINED_CASHFLOW_DATA':
        case 'FAILED_COMBINED_CASHFLOW_DATA':
            return { ...state, combinedCashFlowData: action.payload }
        case 'TABLE_LOADER_CASH_FLOW_SNAPSHOT':
            return { ...state, cashFlowTableLoader: action.payload }
        default:
            return state;
    }

    return state;
}
