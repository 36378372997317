var initialState = {}

export default function riskProfilingReducer( state = initialState, action){
    switch( action.type ){

        case 'GOT_RISK_PROFILE_SUBMISSIONS' :
            return { ...state, submissionsData: action.payload }
        case 'FAILED_TO_GET_RISK_PROFILE_SUBMISSIONS' :
            return { ...state, submissionsData: [] }

        case 'COMPONENT_LOADER_RISK_PROFILER_FORM':
        	return { ...state, loaderRiskProfilerForm: action.payload }

        case 'GOT_RISK_PROFILER_DATA' :
            return { ...state, riskProfilerData: action.payload }
        case 'FAILED_TO_GET_RISK_PROFILER_DATA' :
            return { ...state, riskProfilerData: {} }

        case 'CREATED_OR_UPDATED_RISK_PROFILER_SUCCESSFULLY' :
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_CREATE_OR_UPDATE_RISK_PROFILER' :
            return { ...state, notificationData: action.payload }

        case 'GOT_RISK_PROFILE_PENDIND_EXPIRED_DATA':
            return {...state, pendingExpiredData: action.payload }
        case 'FAILED_RISK_PROFILE_PENDIND_EXPIRED_DATA':
        return {...state, pendingExpiredData: action.payload }

        case 'GOT_SUBMISSION_DATA_LOGS':
            return {...state, submissionLogData: action.payload }
        case 'FAILED_SUBMISSION_DATA_LOGS':
        return {...state, submissionLogData: action.payload }

        case 'GOT_RISK_PROFILE_SUMMARY':
            return {...state, riskProfileSummary: action.payload }
        case 'FAILED_RISK_PROFILE_SUMMARY':
        return {...state, riskProfileSummary: action.payload }

        case 'GOT_RISK_CATEGORY_LIST':
            return {...state, riskCategoryList: action.payload }
        case 'FAILED_RISK_CATEGORY_LIST':
        return {...state, riskCategoryList: action.payload }

        case 'SUCCESS_SEND_RISK_PROFILE_INVITE':
            return {...state, notificationData : action.payload }
        case 'FAILED_SEND_RISK_PROFILE_INVITE':
            return {...state, notificationData: action.payload }

        case 'GOT_RISK_PROFILE_QUESTIONS':
        return {...state, riskProfileQuestions : action.payload}
        case 'FAILED_RISK_PROFILE_QUESTIONS':
        return {...state, riskProfileQuestions : action.payload}
        case 'RISK_PROFILE_QUESTIONS_LOADER':
        return {...state, loaderForQuestions : action.payload}

        case 'SUCCESSFULLY_SUBMIT_RISK_PROFILE_DATA':
        return {...state, submitedRiskProfile : action.payload}
        case 'FAILED_SUBMIT_RISK_PROFILE_DATA':
        return {...state, submitedRiskProfile : action.payload}
        case 'COMPONENT_LOADER_FOR_SUBMIT_PROFILER_FORM':
        return {...state, loaderForSubmitProfile : action.payload}

        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }

        case 'RISK_PROFILE_SUMMARY_LOADER':
            return {...state, riskProfileSummaryLoader: action.payload}
        case 'RISK_CATEGORY_LOADER':
            return {...state, riskCategoryLoader: action.payload}
        
        default:
        new Error('No action type matched in reducer')
    }
    return state
}