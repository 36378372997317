var initialState = {}

export default function SOAJob( state = initialState, action){

	switch(action.type){

		case 'COMPONENT_LOADER_SOA_JOB_LIST' : 
			return {...state,loaderSOAJobTable:action.payload}
		case 'GOT_SOA_JOB_LIST' : 
			return {...state,SOAJobDataList:action.payload}
		case 'FAILED_SOA_JOB_LIST' : 
			return {...state,SOAJobDataList:action.payload}

		case 'COMPONENT_LOADER_SOA_REQUEST' : 
			return {...state,loaderCreateSOARequest:action.payload}
		case 'REQUEST_CREATE_SOA_SUCCESSFULLY' : 
			return {...state,createSOARequest:action.payload, notificationInfo:{message: action.payload.message, notificationError: false} }
		case 'FAILED_SOA_REQUEST' : 
			return {...state,createSOARequest:action.payload, notificationInfo:{message: action.payload, notificationError: true}}
		
		case 'HIDE_SOA_NOTIFICATION' : 
			return {...state,notificationInfo:action.payload}

		case 'GOT_DOWNLOAD_KARVY_TEMP_SOA_DATA' : 
			return {...state,downloadKarvyTempData:action.payload}
		case 'COMPONENT_LOADER_KERVY_TEMP_SOA_REQUEST': 
			return {...state,loaderDownloadKarvyTemp:action.payload}
		case 'FAILED_DOWNLOAD_KARVY_TEMP_SOA_DATA' : 
			return {...state,downloadKarvyTempData:action.payload}

		case 'GOT_CAMS_CAPTCHA_DATA' : 
			return {...state,gotCamsCaptchaData:action.payload}
		case 'COMPONENT_LOADER_CAMS_CAPTCHA': 
			return {...state,loaderCamsCaptcha:action.payload}
		case 'FAILED_CAMS_CAPTCHA_DATA' : 
			return {...state,gotCamsCaptchaData:action.payload}

		case 'GOT_DOWNLOAD_CAMS_SOA_DATA' : 
			return {...state,downloadCamsData:action.payload}
		case 'COMPONENT_LOADER_CAMS_SOA_REQUEST': 
			return {...state,loaderDownloadCams:action.payload}
		case 'FAILED_DOWNLOAD_CAMS_SOA_DATA' : 
			return {...state,downloadCamsData:action.payload}

		case 'GET_REPORT_JOBS': 
			return {...state, getReportJobs:action.payload}
		case 'FAILED_TO_GET_REPORT_JOBS' : 
			return {...state, getReportJobs:action.payload}

		case 'IS_REQUEST_SOA':
			return{...state,isRequestSOA:action.payload}

		case 'SHOW_HIDE_NOTIFICATION':
			return{...state,notificationData:action.payload}


		default:
        new Error('No action type matched in reducer')
	}

	return state
}