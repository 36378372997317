var initialState = {}

export default function taskManager( state = initialState, action){
    switch( action.type ){
        case 'CLEAR_NOTIFICATION':
            return {...state, notificationStatus: action.payload}

        case 'GOT_TASK_LIST_DATA' :
            return { ...state, taskDataList : action.payload }
        case 'FAILED_TASK_LIST_DATA' :
            return {...state, taskDataError : action.payload }
        
        case 'COMPONENT_LOADER_FOR_TASK_LIST' :
            return { ...state, loaderForTaskList : action.payload }
        
        case 'TASK_CREATED_SUCCESSFULLY' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_TASK_CREATION' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'TASK_DELETED_SUCCESSFULLY' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_DELETE_TASK' :
            return {...state, notificationStatus:action.payload, errorNotification: true }
        
        case 'GOT_REPORTING_LEVELS' :
            return { ...state, reportingLevels : action.payload }
        case 'FAILED_REPORTING_LEVELS' :
            return {...state, reportingError : action.payload }
        
        case 'GOT_LEVEL_USERS' :
            return { ...state, levelUsers : action.payload }
        case 'FAILED_LEVEL_USERS' :
            return {...state, levelUsersError : action.payload }

        case 'GOT_CLIENT_UNDER_USER' :
            return { ...state, clientUnderUser : action.payload }
        case 'FAILED_CLIENT_UNDER_USER' :
            return {...state, clientUnderUserError : action.payload }

        case 'GOT_RM_OF_CLIENT_SUCCESSFULLY' :
            return { ...state, rmOfClient : action.payload }
        case 'FAILED_TO_GET_RM_OF_CLIENT' :
            return { ...state, rmOfClient : [] }

        case 'COMPONENT_LOADER_TASK_FORM':
            return { loaderTaskForm: action.payload }

        default:
        new Error('No action type matched in reducer')
    }
    return state
}