var initialState = {}
export default  function complianceReport( state = initialState, action ){
    switch(action.type){
    case 'CLEAR_NOTIFICATION':
        return {...state, notificationStatus: action.payload}

    case 'GOT_COMPLIANCE_REPORT_LIST' :
        return { ...state, complianceReportList : action.payload }
    case 'FAILED_COMPLIANCE_REPORT_LIST' :
        return {...state, complianceReportList : action.payload }
    
    case 'COMPONENT_LOADER_FOR_COMPLIANCE_LIST' :
        return { ...state, loaderForComplianceList : action.payload }
    
    case 'COMPLIANCE_UPDATED_SUCCESSFULLY' : 
    case  'SUCCESS_COMMUNICATION' :
    case 'SUCCESS_TAGS_IN_CLIENTS':
    case 'GOT_REFRESHED_COMPLIANCE_DATA':
    case 'COMMON_NOTIFICATION_ACTION_SUCCESS':
    case 'ASSIGN_TAGS_TO_CLIENTS':
        return {...state, notificationStatus:action.payload, errorNotification: false }
    case 'FAILED_COMPLIANCE_UPDATE' : 
    case 'FAILED_COMMUNICATION' :
    case 'FAILED_TAGS_IN_CLIENTS':
    case 'FAILED_REFRESHED_COMPLIANCE_DATA':
    case 'COMMON_NOTIFICATION_ACTION_FAILED':
    case 'FAILED_ASSIGN_TAGS_TO_CLIENTS':
        return {...state, notificationStatus:action.payload, errorNotification: true }
    case 'GOT_UPDATED_CAMS_KARVY':
        return {...state, complianceCamsKarvyData : action.payload }
    case 'FAILED_UPDATED_CAMS_KARVY':
        return {...state, complianceCamsKarvyData : action.payload }
        default:
        new Error('No action type matched in reducer')
    }
    return state
}