import React, { Fragment, useState } from 'react'
import Lottie from 'react-lottie';
const AnimationLoader = (props) => {
  const [animationData, setAnimationData] = useState(null)
  import(`app/media/animations/${props.animationName || 'analysisLoader'}.json`).then((module) => {
    setAnimationData(module.default)
  })
  const options = {
    animationData: animationData,
    loop: props.isLoop || true,
    autoplay: props.isAutoplay || true
  };
  return (
    <Fragment>
      {animationData ?
        <Lottie height={props.loaderHeight} width={props.loaderWidth} options={options} />
        : <div>Loading ...</div>}
    </Fragment>
  )
}

export default AnimationLoader