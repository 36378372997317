export default function onboardingMFUReducer(state, action) {
    switch (action.type) {
        case "CHANGE_STEP_NO":
            return { ...state, stepNo: action.payload };

        case 'SAVE_MFU_ID':
            return { ...state, mfuid: action.payload };

        case "ADD_PERSONAL_INFO_VARIABLES":
            return { ...state, personalInfoVariables: action.payload };

        case "ADD_INVESTOR_REGISTRATION_FORM_VARIABLES":
            return { ...state, investorRegistrationVariables: action.payload };

        case "ADD_BANK_DETAILS_VARIABLES":
            return { ...state, bankDetailVariables: action.payload };

        case 'ADD_FATCA_FORM_VARIABLES':
            return { ...state, fatcaFormVariables: action.payload };

        case 'ADD_SUBMIT_DOCUMENT_VARIABLES':
            return { ...state, submitDocumentVariables: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING':
            return { ...state, loaderOnBoardingComponent: action.payload };

        case 'COMPONENT_LOADER_ONBOARDING_MFU':
                return { ...state, loaderOnBoardingComponentMFU: action.payload };
    
        case 'CLOSE_NOTIFICATION':
            return { ...state, notificationData: null }

        case 'CREATED_IIN_SUCCESSFULLY':
            return { ...state, iinCreationData: action.payload && action.payload.result, notificationData: action.payload };
        case 'FAILED_TO_CREATE_IIN':
            return { ...state, iinCreationData: null, notificationData: action.payload };

        case 'GOT_CANS_BY_APPID':
            return { ...state, mfuDetailsByAppid: action.payload }
        case 'FAILED_TO_GET_CANS_BY_APPID':
            return { ...state, mfuDetailsByAppid: null }

        case 'GOT_CAN_TEMP_DATA':
            return { ...state, canTempData: action.payload }
        case 'FAILED_TO_GET_CAN_TEMP_DATA':
            return { ...state, canTempData: null } 

        case 'GOT_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: action.payload && action.payload.result, notificationData: action.payload }
        case 'FAILED_TO_GET_VIDEO_KYC_DETAILS':
            return { ...state, videoKycDetails: null, notificationData: action.payload } 

        case 'COMPONENT_LOADER_COUNTRY_MASTER':
            return { ...state, loaderCountries: action.payload }
        case 'GOT_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: action.payload };
        case 'FAILED_TO_GET_COUNTRY_MASTER_DATA':
            return { ...state, countryMaster: null };

        case 'GOT_CLIENT_INFORMATION_SUCCESSFULLY':
            return { ...state, clientInformation: action.payload };
        case 'FAILED_TO_GET_CLIENT_INFORMATION':
            return { ...state, clientInformation: null };

        case 'CHECKED_KYC_SUCCESSFULLY':
            return { ...state, checkKycData: action.payload  };
        case 'FAILED_TO_CHECK_KYC':
            return { ...state, checkKycData: null };


        case 'GOT_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: action.payload }
        case 'FAILED_TO_GET_CITY_FROM_PINCODE':
            return { ...state, cityFromPincode: null };

        case 'COMPONENT_LOADER_BANKS_MASTER':
            return { ...state, loaderBanks: action.payload }
        case 'GOT_BANKS_MASTER_DATA':
            return { ...state, banksMaster: action.payload }
        case 'FAILED_TO_GET_BANKS_MASTER_DATA':
            return { ...state, banksMaster: null };

        case 'GOT_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: action.payload }
        case 'FAILED_TO_GET_BANKS_DETAILS_FROM_IFSC':
            return { ...state, bankDetailsFromIFSC: null };

        case 'COMPONENT_LOADER_STATE_MASTER':
            return { ...state, loaderStates: action.payload }
        case 'GOT_STATE_MASTER_DATA':
            return { ...state, stateMaster: action.payload }
        case 'FAILED_TO_GET_STATE_MASTER_DATA':
            return { ...state, stateMaster: null };

        case 'COMPONENT_LOADER_APPLICABLE_INCOMES':
            return { ...state, loaderApplicableIncomes: action.payload }
        case 'GOT_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: action.payload }
        case 'FAILED_TO_GET_APPLICABLE_INCOME_DATA':
            return { ...state, applicableIncomeList: null };

        case 'GOT_CAN_VERIFICATION_DOCUMENTS':
            return { ...state, canVerificationDocuments: action.payload }
        case 'FAILED_TO_GET_CAN_VERIFICATION_DOCUMENTS':
            return { ...state, canVerificationDocuments: null };



        case 'COMPONENT_LOADER_SUBMIT_DOCUMENT':
            return { ...state, loaderSubmitDocument: action.payload }

        case 'UPLOADED_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_NSE_SUBMIT_DOCUMENT_FORM':
            return { ...state, notificationData: action.payload }


        case 'UPLOADED_CAN_VERIFICATION_DOCUMENT':
            return { ...state, uploadedCanDocument: true, notificationData: action.payload }
        case 'FAILED_TO_UPLOAD_CAN_VERIFICATION_DOCUMENT':
            return { ...state, uploadedCanDocument: false, notificationData: action.payload }


        case 'COMPLETED_CAN_SUCCESSFUL':
            return { ...state, submitDocumentData: action.payload , notificationData: action.payload }
        case 'FAILED_TO_COMPLETE_CAN':
            return { ...state, submitDocumentData: null, notificationData: action.payload }

        case 'GOT_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}
        case 'FAILED_VIDEO_KYC_LINK_WHATSAPP':
            return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}

        case 'CLEAR_ONBOARDING_STORE':
            return {}

        default:
            return { ...state }
    }
}