
export default function createCredential(state={},action){
    switch(action.type){
        case 'COMPONENT_LOADER_FOR_TABLE':
            return {...state, tableLoader: action.payload}
        case 'GOT_AGGREGATOR_USERS':
            return{...state, getAggregateUsers: action.payload}
        case 'FAILED_AGGREGATOR_USERS':
            return {...state, getAggregateUsers: action.payload}
        case 'GOT_SUB_USERS':
            return{...state, getSubUsers: action.payload}
        case 'FAILED_SUB_USERS':
            return {...state, getSubUsers: action.payload}
        case 'SHOW_HIDE_CREDENTIAL_NOTIFICATION':
            return{...state, notificationData: action.payload}
        default:
            new Error('No action type matched in reducer')
    }
    return state;

}