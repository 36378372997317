var intialState = {
    gotRelationShipMngr: {},
    gotSubBroker: {},
    userListByLevel: {},
   
};
    /*intialState.userListByLevel = {
        "data": [
            {
                "levelNo": "1",
                "username": "22raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "uid": 17,
                "reportingid": 17,
                "reportingLevelid": 1

            },
            {
                "levelNo": "1",
                "username": "23raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "uid": 17,
                "reportingid": 17,
                "reportingLevelid": 1
            },
            {
                "levelNo": "1",
                "username": "24raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "uid": 17,
                "reportingid": 17,
                "reportingLevelid": 1
            }
        ],
        "totalAmt": 164276556.92000002
    }*/

    intialState.levels = {
        "data": [
            {
                "levelNo": "1",
                "levelName": "1raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "leveId": 17,

            },
            {
                "levelNo": "2",
                "levelName": "2raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "leveId": 17,
            },
            {
                "levelNo": "3",
                "levelName": "3raj@earth.in",
                "name": "Amarinder Singh Bedi",
                "leveId": 17,
            }
        ],
        "totalAmt": 164276556.92000002
    }

export default function dashboard(state=intialState,action){
switch (action.type) {
  case 'GETTING_LEVEL_USERS':
      return {...state, userListByLevel:{apiStatus:1} }

  case 'GOT_LEVEL_USERS':
    return {...state, userListByLevel:{data:action.payload && action.payload.result && action.payload.result.data,apiStatus:0} }

  case 'COMPONENT_LOADER_GET_LEVEL_USER':
    return {...state,loaderToggle:action.payload }

  case 'GOT_RELATIONSHIP_MNGR':
    return {...state, gotRelationShipMngr:{data:action.payload && action.payload.result && action.payload.result.data,apiStatus:0} }

  case 'GOT_SUB_BROKER':
    return {...state, gotSubBroker:{data:action.payload && action.payload.result && action.payload.result.data,apiStatus:0} }

 case 'COMPONENT_LOADER_SUB_BROKER':
    return {...state,loaderToggle:action.payload }


  case 'LEVEL_USERS_FAILED':
      return {...state, userListByLevel:{data:intialState.userListByLevel.data,errorMsg:action.payload,apiStatus:-1
      } }
 
 case 'GETTING_LEVELS':
    return {...state, levels:{apiStatus:1} }

  case 'GOT_LEVELS':
    return {...state, levels:{data:action.payload.data,apiStatus:0} }

  case 'LEVELS_FAILED':
      return {...state, levels:{data:intialState.levels.data,errorMsg:action.payload,apiStatus:-1
      } }
  case 'GETTING_LEVEL_USERS_RELATIONSHIP_MANAGER':
    return{...state, relationShipManager:action.payload } 

  case 'GETTING_LEVEL_USERS_SUB_BROKER':
    return{...state, subBroker:action.payload }   

  case 'GOT_LEVEL_USERS_SUB_BROKER':
    return{...state, subBroker:{data:action.payload && action.payload.result && action.payload.result.data} }

  case 'GOT_LEVEL_USERS_RELATIONSHIP_MANAGER':
    return{...state, relationShipManager:{data: action.payload && action.payload.result && action.payload.result.data}}

  case 'FAILED_TO_GET_LEVEL_USERS_SUB_BROKER':
    return {...state, subBroker:{data: action.payload} }  

  case 'FAILED_TO_GET_LEVEL_USERS_RELATIONSHIP_MANAGER':
    return {...state, relationShipManager:{data: action.payload}}

  case 'COMPONENT_LOADER_GET_LEVEL_USER_SB':
    return {...state, loaderToggle:action.payload }

  case 'COMPONENT_LOADER_GET_LEVEL_USER_RM':
    return{...state, loaderToggle:action.payload}

  case 'COMPONENT_LOADER_GET_RELATIONSHIP_MANAGER_DATA':
    return {...state,loaderToggle:action.payload} 

   case 'GETTING_FAMILY_HEAD_DATA':
    return {...state,familyHeadData:action.payload}   

  case 'GET_FAMILY_HEAD_DATA':
    return {...state,familyHeadData:action.payload} 

  case 'FAILED_TO_GET_FAMILY_HEAD_DATA':
    return {...state,familyHeadData:action.payload}

  case 'COMPONENT_LOADER_GET_RELATIONSHIP_MANAGER_DATA':
     return {...state,loaderToggle:action.payload}

  case 'GETTING_RELATIONSHIP_MANAGER_DATA':
     return {...state,relationShipManagerData:action.payload}   

  case 'GET_RELATIONSHIP_MANAGER_DATA':
    return{...state,relationShipManagerData:action.payload}

  case 'FAILED_TO_GET_RELATIONSHIP_MANAGER_DATA':
    return {...state,relationShipManagerData:action.payload } 

  case 'COMPONENT_LOADER_GET_SUB_BROKER_DATA':
    return {...state,loaderToggle:action.payload} 

  case 'GETTING_SUB_BROKER_DATA':  
    return {...state,subBrokerData:action.payload}   

  case 'GET_SUB_BROKER_DATA':
    return {...state,subBrokerData:action.payload}
        
  case 'FAILED_TO_GET_SUB_BROKER_DATA':
    return {...state,subBrokerData:action.payload}

  case 'GET_SERVICE_RM_DATA':
    return {...state,serviceRMData:action.payload}

  case 'FAILED_SERVICE_RM_DATA':
    return {...state,serviceRMData:action.payload}

  case 'GOT_LEVEL_USERS_FILTERED':
    return { ...state, levelUsersFiltered: action.payload }    

  case 'FAILED_TO_GET_LEVEL_USERS_FILTERED':
    return { ...state, levelUsersFiltered: [] }

  case 'COMPONENT_LOADER_LEVEl_USERS_FILTERED':
    return { ...state, loaderLevelUsers: action.payload }

    default:
      new Error('No action type matched in User reducer')
  }
  return state
}
