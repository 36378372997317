export default function taskManagement(state={},action){
	switch(action.type){
		
        case 'GOT_BROKER_LIST':
            return {...state, brokerDataList:action.payload }
        case 'FAILED_BROKER_LIST':
            return{...state,brokerDataList:action.payload}
        case 'COMPONENT_LOADER_GOT_BROKER':
            return{...state,loaderTaskManagement:action.payload}
        
        case 'MANAGEMENT_FORM_LOADER':
            return{...state,loaderManagement:action.payload}    
        case 'CREATE_AUTOMATION_JOB':
            return {...state, createAutoJob:action.payload,successFlag:true }
        case 'FAILED_TO_CREATE_AUTOMATION_JOB':
            return{...state,createAutoJob:action.payload,successFlag:false,JobStateEmpty:true}
        case 'JOB STATE EMPTY':
            return {...state,JobStateEmpty:false}
        case 'GOT_ARN_SOURCE_DATA':
            return {...state, arnSourceData:action.payload }
        case 'FAILED_ARN_SOURCE_DATA':
            return{...state,arnSourceData:action.payload}
        case 'COMPONENT_LOADER_ARN_SOURCE':
            return{...state,loaderTaskManagement:action.payload}

        case 'GOT_TASK_LIST':
            return {...state, taskListData:action.payload }
        case 'FAILED_TASK_LIST':
            return{...state,taskListData:action.payload}
        
        case 'GOT_CREATED_BY_LIST':
        case 'FAILED_CREATED_BY_LIST':
            return{...state,jobCreatedByData:action.payload}

        case 'GOT_RTA_LIST':
            return {...state, rtaListData:action.payload }
        case 'FAILED_RTA_LIST':
            return{...state,rtaListData:action.payload}
        case 'COMPONENT_LOADER_GOT_RTAS':
            return{...state,loaderTaskManagement:action.payload}

        case 'GOT_JOBS_DATA':
            return {...state, jobsData:action.payload }
        case 'FAILED_JOBS_DATA':
            return{...state,jobsData:action.payload}
        case 'COMPONENT_LOADER_GOT_JOBS':
            return{...state,loaderTaskManagement:action.payload}

        case 'GOT_DELETE_JOB':
            return {...state, deleteJobData:action.payload,successFlag:true }
        case 'FAILED_DELETE_JOB':
            return{...state,deleteJobData:action.payload,successFlag:false}

        case 'HIDE_NOTIFICATION':
            return {...state,createAutoJob:action.payload,deleteJobData:action.payload,reorderData:action.payload, scheduleSubscription: action.payload, notificationMsg : action.payload }

        case 'REORDER_SUCCESSFUL':
            return {...state, reorderData:action.payload,successFlag:true }
        case 'FAILED_TO_REORDER':
            return{...state,reorderData:action.payload,successFlag:false}

        case 'COMPONENT_LOADER_SCHEDULE_SUBSCRIPTION_TABLE':
            return { ...state, loaderScheduleSubscription: action.payload } 
        case 'SCHEDULE_SUBSCRIPTION_SUCCESSFUL':
            return { ...state, scheduleSubscription: action.payload, successFlag:true }
        case 'FAILED_TO_SCHEDULE_SUBSCRIPTION':
            return{ ...state, scheduleSubscription: action.payload, successFlag:false}

        case 'FUNDS_DATA_LIST':
            return {...state, fundsDataList:action.payload }
        case 'FUNDS_DATA_LIST_SPECIFIC':
            return {...state, [`${action.specificFundDataList}`]:action.payload }
        case 'FAILED_FUNDS_DATA_LIST':
            return{...state,fundsDataList:action.payload}
        case 'FAILED_FUNDS_DATA_LIST_SPECIFIC':
            return {...state, [`${action.specificFundDataList}`]:action.payload }    

        case 'COMPONENT_LOADER_FOLIO_WISE_LOADER':
            return { ...state, loaderFolioWiseMimatch: action.payload } 
        case 'SUCCESSFUL_FOLIO_WISE_MISMATCH_JOB':
            return { ...state, notificationMsg: action.payload, successFlag:true}
        case 'FAILED_FOLIO_WISE_MISMATCH_JOB':
            return{ ...state, notificationMsg: action.payload, successFlag:false}

        case 'COMPONENT_LOADER_DATA_DELETION':
            return { ...state, dataDeletionLoader: action.payload } 
        case 'SUCCESS_DATA_DELETION':
            return { ...state, notificationMsg: action.payload, successFlag:true}
        case 'FAILED_DATA_DELETION':
            return{ ...state, notificationMsg: action.payload, successFlag:false}
        case 'ALERT_ON_BROKER_LOGIN_UPDATED_SUCCESSFULLY':
            return {...state,notificationMsg:action.payload,errorNotification:false}
        case 'ALERT_ON_BROKER_LOGIN_UPDATED_FAILED':
            return {...state,notificationMsg:action.payload,errorNotification:true}
		default:
      		new Error('No action type matched in reducer')
	}
	return state;

}