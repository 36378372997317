export default function Config(state = {}, action) {
  switch (action.type) {
    case 'UPDATE_LOADER':
      return {...state, pageProgress:action.payload }

    case 'UPDATE_COMPONENT_LOADER':
      return {...state, loader:action.payload }
    default:
      new Error('No action type matched in Login reducer')
  }
  return state
}