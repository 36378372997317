
export default function reportAdmin(state={},action){
    switch(action.type){
        
        case 'GOT_ONEVIEWREPORT_DATA':
            return {...state, oneViewData:action.payload }
        case 'COMPONENT_LOADER_ADMIN_REPORT':
            return{...state,loaderReport:action.payload}
        case 'FAILED_ONEVIEWREPRT_DATA':
            return {...state, failedOneViewData :action.payload }
        case 'GOT_AUTHORIZATION_KEY': 
            return{...state, authorizationKey: action.payload}
        case 'FAILED_AUTHORIZATION_KEY': 
            return {...state, notificationStatus: action.payload,errorNotification: true}

        case 'GOT_ONEVIEWREPORT_GROUP_DATA':
            return {...state, oneViewGroupData:action.payload }
        case 'FAILED_ONEVIEWREPORT_GROUP_DATA':
            return {...state, failedOneViewGroupData :action.payload }

        case 'GOT_ONEVIEWREPORT_TRANSCONSOL_DATA' : 
            return {...state, oneViewTransConsolData : action.payload } 
        case 'FAILED_ONEVIEWREPORT_TRANSCONSOL_DATA':
            return {...state, oneViewTransConsolData : action.payload }
        
        case 'GOT_MAX_NAV_DATE':
            return {...state, maxNavDate :action.payload }
        case 'FAILED_MAX_NAV_DATE':
            return {...state, maxNavDate : action.payload }


         case 'SUCCESSFULLY_CALL_MISMATCHES':
            return {...state, notificationStatus:action.payload,errorNotification: false }
        case 'FAILED_TO_CALL_MISMATCHES':
            return {...state, notificationStatus: action.payload,errorNotification: true }
        case 'CLEAR_NOTIFICATION':
            return {...state, notificationStatus: action.payload}

        case 'CREATED_BULK_JOBS_SUCCESSFULLY' : 
            return {...state, notificationStatus: action.payload&& action.payload.message ,errorNotification: false }
        case 'FAILED_TO_CREATE_BULK_JOBS':
            return {...state, notificationStatus: action.payload,errorNotification: true }

        case 'GOT_MAIL_SCHEDULE_LIST_DATA':
            return { ...state, oneViewMailScheduleList: action.payload}
        case 'FAILED_TO_GET_MAIL_SCHEDULE_LIST':
            return { ...state, oneViewMailScheduleList: []}

        case 'SUCCESS_REMARKS_UPDATE' :
            return {...state, notificationStatus: action.payload, errorNotification: false }
        case 'FAILED_REMARKS_UPDATE' :
            return {...state, notificationStatus: action.payload, errorNotification: true}

        case 'SUCCESSFULLY_CALL_SUBSCRIPTION':
            return {...state, notificationStatus:action.payload,errorNotification: false }
        case 'FAILED_CALL_SUBSCRIPTION':
            return {...state, notificationStatus: action.payload,errorNotification: true }

        case 'SUCCESSFULLY_CREATED_BULK_JOB_USING_REFERENCE_NO':
            return { ...state, notificationStatus: action.payload, errorNotification: false }

        case 'FAILED_TO_CREATE_BULK_JOB_USING_REFERENCE_NO':
            return { ...state, notificationStatus: action.payload, errorNotification: true }

        case 'SUCCESS_RUN_AUM_RECO' :
            return {...state, notificationStatus: action.payload, errorNotification: false }
        case 'FAILED_RUN_AUM_RECO' :
            return {...state, notificationStatus: action.payload, errorNotification: true}

        case 'SET_MIGRATE_BROKER_REQUEST_SUCESSFULLY' :
            return {...state, notificationStatus: action.payload, errorNotification: false }
        case 'FAILED_TO_SET_MIGRATE_BROKER_REQUEST' :
            return {...state, notificationStatus: action.payload, errorNotification: true}
            
        case 'SUCCESS_SYSTEM_UPDATE_DATA':
            return {...state, notificationStatus:action.payload,errorNotification: false }
        case 'FAILED_SYSTEM_UPDATE_DATA':
            return {...state, notificationStatus: action.payload,errorNotification: true }

         case 'COMPONENT_LOADER_SYSTEM_UPDATE':
            return { ...state, systemUpdateLoader: action.payload}   


        case 'GOT_ONEVIEWREPORT_CREDENTIAL_STATUS':
                return {...state, oneViewCredentialData:action.payload }
        case 'FAILED_ONEVIEWREPORT_CREDENTIAL_STATUS':
                return {...state, oneViewCredentialData :action.payload }

        case 'GOT_ADMIN_EXECUTIVES' :
            return {...state, adminExecutives: action.payload }
        case 'FAILED_ADMIN_EXECUTIVES' :
            return {...state, adminExecutives: action.payload }
        
        default:
            new Error('No action type matched in reducer')
    }
    return state;

}