import React from 'react'
import { Route,Switch} from 'react-router-dom';
import { USER_TYPE } from 'app/constants'
import Loadable from 'react-loadable';
import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import Loader from 'app/uiCollection/shared/loaders'
import * as commonConst from 'app/constants/shared/commonConst'

const Loading = () => <div class='posRelative loaderMinHeight initialLoader'>
         <Loader 
            loaderType = 'line'
            loaderWidth = { LOADER_WIDTH[3].width }
            loaderHeight = { LOADER_WIDTH[3].height }
            loaderMessage = {commonConst.CAPTION_MESSAGE[2].label}
          />
        </div>

const Broker = Loadable({
  loader: () => import('app/uiCollection/broker'),
  loading: Loading,
})

const BrokerRoute =  () =>{
    return (
    <Switch>
       <Route path="/broker" component={Broker} name={USER_TYPE.broker}/>
    </Switch>
    )
}

export default BrokerRoute;


