var initialState = {
    availableInvoiceNoData : null
}

export default function taskManager( state = initialState, action){
    switch( action.type ){
        
        case 'COMPONENT_LOADER_CLIENT_BILL' :
            return { ...state, loaderForClientBill : action.payload }

        case 'CLEAR_NOTIFICATION':
            return{...state,notificationData:action.payload}
        
        case 'SUCCESSFUL_GENERATE_CLIENT' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_GENERATE_CLIENT' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'GOT_AUM_BASE_FEE_DATA' :
            return { ...state, clientBillingAumData:action.payload }
        case 'FAiLED_AUM_BASE_FEE_DATA' :
            return {...state, clientBillingAumData:action.payload}

        case 'GOT_BILLING_LIST_DATA' :
            return { ...state, clientBillingListingData:action.payload }
        case 'FAILED_BILLING_LIST_DATA' :
            return {...state, clientBillingListingData:action.payload}

        case 'SUCCESSFUL_DELETE_CLIENT_BILL' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_DELETE_CLIENT_BILL' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'SUCCESSFUL_DOWNLOAD_PDF_BILL' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_DOWNLOAD_PDF_BILL' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'SUCCESSFUL_MAIL_BILL' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_MAIL_BILL' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'SUCCESSFUL_CONVERTED_INVOICE' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_CONVERTED_INVOICE' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'GOT_REVERSE_TAX_DATA' :
            return { ...state, calculateReverseTax:action.payload }
        case 'FAILED_REVERSE_TAX_DATA' :
            return {...state, calculateReverseTax:action.payload}

        case 'SUCCESSFUL_AMC_BILL' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_AMC_BILL' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'SUCCESSFUL_MARK_AMC_PAID' :
            return { ...state, notificationStatus:action.payload, errorNotification: false }
        case 'FAILED_MARK_AMC_PAID' :
            return {...state, notificationStatus:action.payload, errorNotification: true }

        case 'GOT_FUND_DATA' :
            return { ...state, fundDetailsData:action.payload }
        case 'FAILED_FUND_DATA' :
            return {...state, fundDetailsData:action.payload}

        case 'GOT_AVAILABLE_INVOICE_NO':
            return { ...state, availableInvoiceNoData:action.payload}
        case 'COMPONENT_LOADER_CHECK_AVAILABILITY':
            return {...state,loaderCheckUser :action.payload}
        case 'FAILED_AVAILABLE_INVOICE_NO':
            return {...state, failedInvoiceNoData : action.payload}

        default:
        new Error('No action type matched in reducer')
    }
    return state
}