import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'app/uiCollection/shared/chunks/loading';
//import delay from '../utils/delay';
import path from 'path';

const LoadableResetPassword = Loadable({
  loader: () => import('app/uiCollection/user/resetPassword'),
  loading: Loading,
});

export default function LoadableResetPasswordCont() {
	
  return <LoadableResetPassword/>;
}