import { applyMiddleware, createStore ,compose} from 'redux'

import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducer from './reducers'
import * as Sentry from "@sentry/react";
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
});

const middleware = (process.env.NODE_ENV == 'production') ? compose(applyMiddleware(promise, thunk),sentryReduxEnhancer) : compose(applyMiddleware(promise, thunk, logger),sentryReduxEnhancer)

const rootReducer = ( state, action ) => {
  if ( action.type === "CLEAR_STORE" ) {
    state = undefined;
  }
      
  return reducer(state, action)
}

const store = createStore(rootReducer, middleware)
window.store = store
export default store