var intialState = {}
  
export default function calculator(state=intialState,action){
  switch(action.type){

    case 'GOT_PASSKEY':
      return {...state,passKeyData:action.payload}
    case 'FAILED_PASSKEY':
      return {...state,passKeyData:action.payload}

    case 'GOT_RISK_LIST_DATA':
      return {...state,riskListData:action.payload}
    case 'FAILED_RISK_LIST_DATA':
      return {...state,riskListData:action.payload}

    case 'GOT_RISK_QUESTION_LIST_DATA':
      return {...state,riskQuestionListData:action.payload}
    case 'FAILED_RISK_QUESTION_LIST_DATA':
      return {...state,riskQuestionListData:action.payload}

    case 'GOT_RISK_RESULT_LIST_DATA':
      return {...state,riskResultData:action.payload}
    case 'FAILED_RISK_RESULT_LIST_DATA':
      return {...state,riskResultData:action.payload}

    case 'GOT_GOAL_BASED_REDEM_LIST_DATA':
      return {...state,goalBasedListData:action.payload}
    case 'FAILED_GOAL_BASED_REDEM_LIST_DATA':
      return {...state,goalBasedListData:action.payload}

    case 'GOT_GOAL_BASED_ALLOCATION_LIST_DATA':
      return {...state,goalBasedAllocationData:action.payload}
    case 'FAILED_GOAL_BASED_ALLOCATION_LIST_DATA':
      return {...state,goalBasedAllocationData:action.payload}

    default:
      new Error('No action type matched in Login reducer')
  }
  return state
}