export default function overlay(state={},action){
	switch (action.type) {

	  case 'OPEN_OVERLAY':
	    return {...state, overlayData:action.payload.cmp, overlayFlag:true }

	  case 'HIDE_OVERLAY':
	    return {...state, overlayFlag:false } 

	  default:
	    new Error('No action type matched')
	}
	return state;
}
