import React from 'react'
import { Route, IndexRoute, Router, Switch } from 'react-router-dom'
import { USER_TYPE } from 'app/constants'
import Loadable from 'react-loadable'
import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import Loader from 'app/uiCollection/shared/loaders'
import * as commonConst from 'app/constants/shared/commonConst'

const Loading = () => <div class='posRelative cl loaderMinHeight initialLoader'>
        <Loader 
            loaderType = 'line'
            loaderWidth = { LOADER_WIDTH[3].width }
            loaderHeight = { LOADER_WIDTH[3].height }
            loaderMessage = {commonConst.CAPTION_MESSAGE[2].label}
        />
        </div>

const Dashboard = Loadable({
  loader: () => import('app/uiCollection/client'),
  loading: Loading,
})

const ClientInfoRoutes =  () => {
  return(
    <Switch>
      <Route path='/client' component={Dashboard} role={USER_TYPE.client}/>{/* role is given for root route only*/}
    </Switch>
    )
  }
export default ClientInfoRoutes;


