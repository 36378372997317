import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'app/uiCollection/shared/chunks/loading';
//import delay from '../utils/delay';

const LoadableSignUp = Loadable({
  loader: () => import('app/uiCollection/user/singup'),
  loading: Loading,
});

export default function LoadableSignUpCont() {
	
  return <LoadableSignUp/>;
}