var intialState = {
};

export default function cart(state = intialState, action) {
  switch (action.type) {
    case 'GOT_CART_ITEMS_DATA':
      return { ...state, data: action.payload.data }
    case 'COMPONENT_LOADER_GOT_CART_ITEMS':
      return {...state,loaderGotCart:action.payload}
    case 'FAILED_CART_ITEMS_DATA':
      return { ...state, errorMsg: action.payload }
    case 'SHOW_HIDE_DELETE_CART_NOTIFICATION':
      return {...state,deletingCartItem:action.payload }
    case 'ORDER_PLACED': 
      return { ...state, placingOrder: true}
    case 'START_ORDER_PLACED': 
      return { ...state, buttonLoader: action.payload }
    case 'FAILED_ORDER_PLACED': 
      return { ...state, placingOrder: false, notification: action.payload, errorMsg: true }
    case 'PLACING_ORDER_STARTED' :
      return {...state, placingOrderStarted: action.payload} 
    case 'UPDATE_CART_ITEM': 
      return { ...state, updatingItem: true }
    case 'CART_ITEM_UPDATED': 
      return { ...state, updatingItem: false }
    case 'FAILED_CART_ITEM_UPDATE': 
      return { ...state, updatingItem: false, errorMsg: action.payload }
    case 'EDIT_CART_ORDER':
    case 'EDIT_CART_ORDER_FAILED': 
      return { ...state, editCartItem: action.payload }
    case 'UPDATE_CART': 
      return { ...state, data: action.payload }

    case 'HIDE_NOTIFICATION': 
      return {...state, notification: null}
    default:
      new Error('No action type matched in reducer')
  }
  return state
}