import React, {Component} from 'react'
import {Route, Switch,IndexRoute, Router} from 'react-router-dom'
import { USER_TYPE } from 'app/constants'
import Loadable from 'react-loadable'
import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import Loader from 'app/uiCollection/shared/loaders'
import * as commonConst from 'app/constants/shared/commonConst'

const Loading = () => <div class='posRelative cl loaderMinHeight initialLoader'>
        <Loader
          loaderType = 'line'
          loaderWidth = { LOADER_WIDTH[3].width }
          loaderHeight = { LOADER_WIDTH[3].height }
          loaderMessage = {commonConst.CAPTION_MESSAGE[2].label}
        />
      </div>

const Admin = Loadable({
  loader: () => import('app/uiCollection/admin'),
  loading: Loading,
})


const AdminInfoRoutes =  () =>{
  return(
    <Switch>
      <Route path='/admin' component={Admin} role={USER_TYPE.admin}/>
    </Switch>
  )
}
  
export default AdminInfoRoutes;
