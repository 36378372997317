var intialState = {
 systematicTxnsList: {}
}

export default function mySystematicTxns(state = intialState, action) {
  switch (action.type) {

    case 'GOT_SYSTEMATIC_TXNS_LIST':
      return { ...state, systematicTxnsList: action.payload }
    case 'COMPONENT_LOADER_SIP':
    	return {...state ,loaderSip:action.payload}
    case 'FAILED_SYSTEMATIC_TXNS_LIST':
      return { ...state, systematicTxnsList: { errorMsg: action.payload } }
    
    case 'GOT_ADVANCED_SYSTEMATIC_TXNS_LIST':
      return { ...state, systematicAdvanceTxnsList: action.payload }
    case 'COMPONENT_LOADER_ADVANCED_SIP':
      return {...state ,loaderAdvancedSip:action.payload}
    case 'FAILED_ADVANCED_SYSTEMATIC_TXNS_LIST':
      return { ...state, systematicAdvanceTxnsList: { errorMsg: action.payload } }

    case 'GOT_RUNNING_SYSTEMATIC_TXNS_LIST':
      return { ...state, runningSystematicTxnsList: action.payload }

    case 'FAILED_RUNNING_SYSTEMATIC_TXNS_LIST':
      return { ...state, runningSystematicTxnsList: { errorMsg: action.payload } } 

    case 'GOT_SIP_MODIFICATION_DATA':
    case 'FAILED_TO_GOT_SIP_MODIFICATION_DATA':
      return { ...state, sipModificationRequest: action.payload }
    
    default:
      new Error('No action type matched in reducer')
  }
  return state
}