
import * as txnsConfirmationRepo from 'app/actions/public/repositories/txnsConfirmation.jsx'
//import {stringifyFilter} from 'app/actions/shared/dataFormatter'


export const GetMfuOrderData = (param,isOpenAPI) => {
    return function (dispatch){
        dispatch({ type: "COMPONENT_LOADER_MFU_ORDER", payload: param.componentForLoader })
        txnsConfirmationRepo.getMfuOrderRepo(param,isOpenAPI)
        .then((response) =>{
            dispatch({ type: "COMPONENT_LOADER_MFU_ORDER", payload: null })
            if(response.status == 0){
                 dispatch({type:"GOT_MFU_ORDER",payload:response.result})
            }
            else{
                dispatch({type:"FAILED_MFU_ORDER",payload: response.message })
                window.open(window.location.origin,"_self")
            }
        })
        .catch((err) => {
             dispatch({type:"FAILED_MFU_ORDER",payload: err })
             window.open(window.location.origin,"_self")
        })
    }
}


export const PlaceMfuOrder = (param,isOpenAPI) => {
    return function (dispatch){
        dispatch({ type: "COMPONENT_LOADER_MFU_ORDER", payload: param.componentForLoader })
        txnsConfirmationRepo.placeMfuOrderRepo(param,isOpenAPI)
        .then((response) =>{
            dispatch({ type: "COMPONENT_LOADER_MFU_ORDER", payload: null })
            if(response.status == 0){
                 dispatch({type:"PLACE_MFU_ORDER_SUCCESSFULLY",payload:response.result})
            }
            else{
                dispatch({type:"FAILED_TO_PLACE_MFU_ORDER",payload: response.message })
            }
        })
        .catch((err) => {
             dispatch({type:"FAILED_TO_PLACE_MFU_ORDER",payload: err })
        })
    }
}

export const NavUpdatedDate = (param) => {
    return function (dispatch){
        dispatch({ type: "COMPONENT_NAV_DATE_LOADER", payload: true })
        txnsConfirmationRepo.navUpdatedDateRepo(param)
        .then((response) =>{
            dispatch({ type: "COMPONENT_NAV_DATE_LOADER", payload: false })
            if(response.status == 0){
                 dispatch({type:"NAV_UPDATE_DATE",payload:response.result})
            }
            else{
                dispatch({type:"FAILED_NAV_UPDATE_DATE",payload: response.message })
            }
        })
        .catch((err) => {
             dispatch({type:"FAILED_NAV_UPDATE_DATE",payload: err })
        })
    }
}

