export default function Utils(state={},action){
	switch (action.type) {

	    case 'UPLOADING_FILE':
	    	return {...state, uploadingFile:true }
	    case 'FILE_UPLOADED':
	    	return {...state, fileUploadedData:action.payload, fileUploaded:true,uploadingFile:false, fileUploadFailed: false,notificationData:{status:0,message:'File uploaded successfully'} } 
	    case 'FILE_UPLOAD_FAILED':
	    	return {...state, fileUploadedData:action.payload, fileUploadFailed:true, fileUploaded: false ,notificationData:{status:-1,message:action.payload}} 
	    case 'COMPONENT_LOADER_UPLOADED':
	    	return{...state,loaderForUpload:action.payload}
		case 'GETTING_INCOME_SLAB':
			return{...state,}
		case 'GOT_INCOME_SLAB':
			return{...state,incomeSlabList:action.payload}
		case 'FAILED_GETTING_INCOME_SLAB':
			return{...state,}

		case 'GETTING_OCCUPATION':
			return{...state,}
		case 'GOT_OCCUPATION':
			return{...state,occupationList:action.payload}
		case 'FAILED_GETTING_OCCUPATION':
			return{...state,}

		case 'GETTING_WEALTH_SOURCE':
			return{...state,}
		case 'GOT_WEALTH_SOURCE':
			return{...state,wealthSourceList:action.payload}
		case 'FAILED_GETTING_WEALTH_SOURCE':
			return{...state,}

		case 'CLEAR_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'SELECTED_TAB_FROM_FILTER': 
			return {...state, selectedTabFromFilter: action.payload }

		case 'GOT_STATE':
			return{...state, stateMaster:action.payload}
		case 'FAILED_STATE':
			return{...state, stateMaster:action.payload}

		case 'UPLOAD_FILE_SIZE_BIGGER_THAN_CHECK':
      return { ...state, fileSizeBigger: action.payload }

	    case 'UPLOAD_FILE_NAME_LENGTH_BIGGER_THAN_CHECK':
			return{...state, fileNameLengthBigger:action.payload}

      case 'GOT_NPS_CONSTANTS':
			return{...state, npsConstantData:action.payload}
		case 'FAILED_NPS_CONSTANTS':
			return{...state, npsConstantData:action.payload}

		case 'GOT_NPS_MY_ORDERS_DATA':
			return { ...state, myOrderListNPS:action.payload }
		case 'COMPONENT_LOADER_NPS_MY_ORDERS':
			return {...state,loaderMyOrderNPS:action.payload}
		case 'FAILED_NPS_MY_ORDERS_DATA':
			return { ...state, myOrderListNPS:action.payload }
		
		case 'GOT_NPS_PROFILE_LIST_DATA':
			return { ...state, profileListNPS:action.payload }
		case 'COMPONENT_LOADER_NPS_PROFILE_LIST':
			return {...state,loaderProfileListNPS:action.payload}
		case 'FAILED_NPS_PROFILE_LIST_DATA':
			 return { ...state, profileListNPS:action.payload }
		
		case 'GOT_NPS_PROFILE_DATA':
			return { ...state, profileNPS:action.payload }
		case 'COMPONENT_LOADER_NPS_PROFILE':
			return {...state,loaderProfileNPS:action.payload}
		case 'FAILED_NPS_PROFILE_DATA':
			return { ...state, profileNPS:action.payload }


		case 'GOT_CUSTOM_COLUMNS':
			return { ...state, customTableColumns:action.payload }
		case 'FAILED_CUSTOM_COLUMNS':
			return {...state,customTableColumns:action.payload}

		case 'SUCCESS_CUSTOM_COLUMNS_UPDATE':
			return { ...state, createUpdatecustomColumns:action.payload }
		case 'FAILED_CUSTOM_COLUMNS_UPDATE':
			return {...state,createUpdatecustomColumns:action.payload}

		case 'COMPONENT_LOADER_CUSTOM_COLUMN':
			return {...state,loaderCustomColumns:action.payload}

		case 'UPDATE_TABLE_CUSTOM_HEADING' :
			return {...state, updateTableCustomHeading : action.payload}

		case 'WINDOW_LOADER_LOADED' :
			return {...state, windowLoaderLoaded : action.payload}
			
		case 'GOT_CLIENT_ESCALATION_LIST_DATA':
		case 'FAILED_TO_GOT_CLIENT_ESCALATION_LIST_DATA':
			return {...state, getEscalationListData:action.payload}

		case 'RETAIN_SELECTED_FILTERS':
			return {...state, retainSelectedFilters: action.payload}
			 
	  default:
	    new Error('No action type matched')
	}
	return state; 
}
