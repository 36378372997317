var intialState = {
	securityExposureData: []
};

    
export default function securityExposureReducer ( state=intialState, action ){

    switch (action.type) {

        case "ADD_SECURITY_EXPOSURE_FILTERS_FILTERS":
            return { ...state, securityExposureFilters: action.payload  }

        case "COMPONENT_LOADER_SECURITY_EXPOSURE_TABLE":
        	return { ...state, loaderSecurityExposureTable: action.payload  }

       	case "GOT_SCHEME_EXPOSURE_DATA" : 
       		return { ...state, securityExposureData: action.payload }

       	case "FAILED_TO_GET_SCHEME_EXPOSURE_DATA" : 
       		return { ...state, securityExposureData: [] }

       	case "GOT_CLIENT_EXPOSURE_DATA" : 
       		return { ...state, securityExposureData: action.payload }

       	case "FAILED_TO_GET_CLIENT_EXPOSURE_DATA" : 
       		return { ...state, securityExposureData: [] }

        default:
            new Error('No action type matched')
            
    }
   
    return state;
}
