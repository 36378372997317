    
export default  function utilityReducer( state = {}, action ){
    switch(action.type){

    case 'COMPONENT_LOADER_CREATE_MODEL_PORTFOLIO':
        return {...state, modelPortfolioLoader: action.payload}

    case 'MODEL_PORTFOLIO_SUCCESSFULLY_CREATED':
        return {...state, createModelPortfolio: action.payload, notificationData:action.payload}

    case 'FAILED_TO_CREATE_MODEL_PORTFOLIO':
        return {...state,createModelPortfolio: action.payload, notificationData:action.payload}

    case 'COMPONENT_LOADER_GET_MODEL_PORTFOLIO_DATA':
        return {...state, modelPortfolioLoader: action.payload}
    case 'GOT_MODEL_PORTFOLIO_DATA': 
        return {...state, getModelPortfolioData:action.payload}
    case 'FAILED_TO_GET_MODEL_PORTFOLIO_DATA':
        return {...state, getModelPortfolioData:action.payload}
    
    case 'GOT_MODEL_PORTFOLIO_SCHEME_DATA': 
        return {...state, getModelPortfolioSchemeData:action.payload}
    case 'FAILED_MODEL_PORTFOLIO_SCHEME_DATA':
        return {...state, getModelPortfolioSchemeData:action.payload}

    case 'COMPONENT_LOADER_GET_CLIENT_PORTFOLIO_DATA':
        return {...state, clientPortfolioLoader: action.payload}
    case 'GOT_CLIENT_PORTFOLIO_DATA': 
        return {...state, getClientPortfolioData:action.payload}
    case 'FAILED_TO_GET_CLIENT_PORTFOLIO_DATA':
        return {...state, getClientPortfolioData:action.payload}

    case 'MODEL_PORTFOLIO_DELETED': 
        return {...state, deleteModel:action.payload, notificationData:action.payload}

    case 'FAILED_TO_DELETE_MODEL_PORTFOLIO': 
        return {...state, deleteModel:action.payload, notificationData:action.payload}

    case 'MODEL_SCHEME_REMOVED':
        return {...state,removeModelScheme:action.payload} 
        
    case 'FAILED_TO_REMOVE_MODEL_SCHEME':
        return {...state,removeModelScheme:action.payload}

    case 'GOT_MODEL_PORTFOLIO_GRAPH_DATA':
        return {...state, modelPortfolioGraph: action.payload}
        
    case 'FAILED_TO_GET_MODEL_PORTFOLIO_GRAPH_DATA':
        return {...state, modelPortfolioGraph: action.payload}

    case 'CLEAR_NOTIFICATION':
        return{...state,notificationData:action.payload}

    case 'GOT_DELETE_SCHEME_MODEL': 
        return {...state, deleteSchemeModel:action.payload, notificationData:action.payload}
    case 'FAILED_DELETE_SCHEME_MODEL': 
        return {...state, deleteSchemeModel:action.payload, notificationData:action.payload}

    case 'COMPONENT_LOADER_AMC_LIST':
        return {...state, loaderAMCList : action.payload }
    case 'GOT_AMC_LIST_DATA':
        return {...state, amcList: action.payload}
    case 'FAILED_AMC_LIST_DATA':
        return {...state, errorNotification: true, notificationStatus: action.payload }

    case 'COMPONENT_LOADER_FORM_LIST':
        return {...state, loaderFormList : action.payload }
    case 'GOT_FORM_LIST_DATA':
        return {...state, formList: action.payload}
    case 'FAILED_FORM_LIST_DATA':
        return {...state, formList: [] }

    case 'GOT_FORM_SUCESSFULLY' : 
        return { ...state, formInfo : action.payload } 
    case 'FAILED_TO_GET_FORM' :
        return { ...state, formInfo: [], errorNotification: true, notificationStatus: action.payload }

    case 'GOT_UTILITY_FORM_DETAILS_DATA':
        return {...state, folioDetailsForUtilityForm: action.payload}
        
    case 'FAILED_UTILITY_FORM_DETAILS_DATA':
        return {...state, folioDetailsForUtilityForm: action.payload}    

    case 'HIDE_NOTIFICATION' : 
        return { ...state, notificationStatus: null, errorNotification: false}

    case "GOT_BULK_UPLOAD_MP_DATA" : 
      return { ...state, bulkUploadData : action.payload }

    case "BULK_UPLOAD_MP_FAILED" :
      return { ...state, bulkUploadData : action.payload  }

    case "ADD_MF_TALLY_FILTERS":
        return { ...state, mfTallyFilters: action.payload }

    case 'COMPONENT_LOADER_MFTALLY':
        return { ...state, mfTallyLoader: action.payload }
    case 'GOT_GROWTH_RETURN':
        return { ...state, growthReturnData: action.payload }
    case 'FAILED_TO_GET_GROWTH_RETURN':
        return { ...state, failGetGrowthReturnData: action.payload }

    case 'GOT_SIP_RETURN_DETAIL':
        return { ...state, sipReturnDetailData: action.payload }
    case 'FAILED_TO_GET_SIP_RETURN_DETAIL':
        return { ...state, sipReturnDetailData: action.payload }

    case 'GOT_SIP_RETURN_SUMMARY':
        return { ...state, sipReturnSummaryData: action.payload }
    case 'FAILED_TO_GET_SIP_RETURN_SUMMARY':
        return { ...state, failGetSipReturnSummaryData: action.payload }

    case 'GOT_TRAILING_RETURN':
        return { ...state, trailingReturnData: action.payload }
    case 'FAILED_TO_GET_TRAILING_RETURN':
        return { ...state, trailingReturnData: action.payload, notificationData : action.payload }

    case 'COMPONENT_LOADER_GROWTH_SCHEMES':
        return { ...state, loaderGrowthSchemes: action.payload }
    case 'GOT_GROWTH_SCHEMES':
        return { ...state, growthSchemes: action.payload && action.payload.result }
    case 'FAILED_TO_GET_GROWTH_SCHEMES':
        return { ...state, growthSchemes: null }

    case 'COMPONENT_LOADER_SCHEMES_SUBCATEGORY':
        return { ...state, loaderSchemeSubCategory: action.payload }
    case 'GOT_SCHEMES_SUBCATEGORY':
        return { ...state, schemeSubCategory: action.payload && action.payload.result }
    case 'FAILED_TO_GET_SCHEMES_SUBCATEGORY':
        return { ...state, schemeSubCategory: null }

    case 'GOT_SWP_CALCULATOR_DATA':
        return {...state, swpCalculatorData:  action.payload}   
    case 'FAILED_SWP_CALCULATOR_DATA':
        return {...state, swpCalculatorData: action.payload}

    case "GOT_SIP_DELAY_CALCULATOR_DATA":
        return { ...state, sipDelayCalulatorData: action.payload };
    case "FAILED_SIP_DELAY_CALCULATOR_DATA":
        return { ...state, sipDelayCalculatorData: action.payload };
        
    case 'GOT_STP_CALCULATOR_DATA':
        return {...state, stpCalculatorData:  action.payload}   
    case 'FAILED_STP_CALCULATOR_DATA':
        return {...state, stpCalculatorData: action.payload}

    case "POST_GROWTH_RETURN_PDF_SUCESSFUL":
        return { ...state, postGrowthPdfData: action.payload }
    case "FAILED_POST_GROWTH_RETURN_PDF":
        return { ...state, failGetPostGrowthPdfData: action.payload }

    case "POST_SIP_RETURN_PDF_SUCESSFUL":
         return { ...state, postSIPPdfData: action.payload }
    case "FAILED_POST_SIP_RETURN_PDF":
         return { ...state, failGetPostSIPPdfData: action.payload }

    case "GOT_BENCHMARK_SCHEMES":
        return { ...state, benchmarkSchemes: action.payload }
    case "FAILED_TO_GET_BENCHMARK_SCHEMES":
        return { ...state, benchmarkSchemes: null }

    case "POST_TRAILING_RETURN_XLS_SUCESSFUL":
        return { ...state, postTrailingReturnXlsData: action.payload }
    case "FAILED_POST_TRAILING_RETURN_XLS":
        return { ...state, postTrailingReturnXlsData: null }

    case "GOT_DIVIDEND_HISTORY_DATA":
        return { ...state, dividendData: action.payload }
    case "FAILED_DIVIDEND_HISTORY_DATA":
        return { ...state, dividendData: action.payload }
    case 'GET_NFO_DETAILS':
        return {...state,nfoDetails:action.payload}          
    case 'FAILED_NFO_DETAILS':
        return {...state,nfoDetails:action.payload}  

 
    case "GET_SIP_CALCULATOR_DATA":
        return {...state,sipCalData:action.payload}          
    case "FAILED_SIP_CALCULATOR_DATA":
        return {...state,sipCalData:action.payload}

    case 'GET_SIP_STEP_UP_CALCULATOR_DATA' :
        return {...state , sipStepUpCalculatorData:action.payload}
    case 'FAILED_SIP_STEP_UP_CALCULATOR_DATA' :
        return {...state , sipStepUpCalculatorData:action.payload}

    case "GET_CORRELATION_MATRIX_DATA":
        return {...state, correlationMatrixData:action.payload}
    case "FAILED_CORRELATION_MATRIX_DATA":
        return {...state, correlationMatrixData:action.payload}

    case "GET_NAV_HISTORY_DATA":
        return { ...state, navHistoryData: action.payload };
    case "FAILED_NAV_HISTORY_DATA":
        return { ...state, navHistoryData: action.payload };
    
    case 'GET_FMP_LIST_DATA' :
        return {...state , fMPSchemeData:action.payload}
    case 'FAILED_FMP_LIST_DATA' :
        return {...state , fMPSchemeData:action.payload}

    case 'GET_VOLT_MF_DATA':
        return { ...state, voltMfData: action.payload }
    case 'FAILED_VOLT_MF_DATA':
        return { ...state, voltMfData:action.payload}
    case 'GET_NOTES_DATA':
    case 'FAILED_NOTES_DATA':
        return { ...state, notesData:action.payload}
    case 'GET_NOTE_DETAILS_DATA':
    case 'FAILED_NOTE_DETAILS_DATA':
        return { ...state, noteDetailsResponse:action.payload }
    case 'DELETE_NOTES':
    case 'FAILED_DELETE_NOTES':
        return { ...state, deleteNoteResponse: action.payload }
    case 'CREATE_OR_UPDATE_NOTES':
    case 'FAILED_CREATE_OR_UPDATE_NOTES':
        return { ...state, createNoteResponse: action.payload }
    case 'CREATE_LABEL':
    case 'FAILED_CREATE_LABEL':
        return { ...state, createLabelResponse: action.payload}
    case 'GET_LABEL_DATA':
    case 'FAILED_LABEL_DATA':
        return { ...state, labelData:action.payload}

    case 'COMPONENT_LOADER_PORTFOLIO_OVERLAP':
        return { ...state, portfolioOverlapLoader: action.payload }
    case 'GOT_PORTFOLIO_OVERLAP' :
    case 'FAILED_PORTFOLIO_OVERLAP' :
        return { ...state, portfolioOverlapData:action.payload}

    case 'GET_USAGE_GUIDE_DATA':
    case 'FAILED_USAGE_GUIDE_DATA':
        return { ...state, usageGuideData: action.payload }
    case 'USAGE_GUIDE_LOADER':
        return { ...state, usageGuideLoader: action.payload }

    case 'GET_SCHEME_SUB_CATEGORIES':
    case 'FAILED_SCHEME_SUB_CATEGORIES':
        return {...state, schemeWiseSubCategory: action.payload }

    case 'GET_TOP_SCHEMES_MULTISELECT_FILTERS':
    case 'FAILED_TOP_SCHEMES_MULTISELECT_FILTERS':
        return {...state, getTopSchemesMultiSelectFiltersData: action.payload }

    case 'GOT_REPORT_PREFERENCES':
    case 'FAILED_TO_GET_REPORT_PREFERENCES':
        return { ...state,  reportPreferences : action.payload  }

    case 'GET_LUMPSUM_CALCULATOR_DATA':
    case 'FAILED_LUMPSUM_CALCULATOR_DATA':
        return {...state,lumpsumCalData:action.payload}
    case "GET_RISK_ANALYSIS_SCATTER_PLOT_DATA":
    case "FAILED_TO_GET_RISK_ANALYSIS_SCATTER_PLOT_DATA":
        return {...state, riskAnalysisPlotData: action.payload}
    default:
        new Error('No action type matched in reducer')
    }
    return state
}