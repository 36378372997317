import React from 'react'
import Cookie from '../../../utils/Cookie'
import {connect} from 'react-redux'
import buildInfo from 'investwellFront/config/build-id.json'
import { Field, reduxForm } from 'redux-form'

import AlertMsg from '../../shared/alert/errorMsg'
import {THEMES,LOADER_TEXT} from '../../../constants/dashboardConst'
import { LOADER_WIDTH,MFU_TXNS_TYPES } from 'app/constants/shared/commonConst'
import Loader from 'app/uiCollection/shared/loaders'
import {GetMfuOrderData, PlaceMfuOrder} from 'app/actions/public/txnsConfirmation'
import {GetUserTheme} from 'app/actions/user'
import { DOCS_LOCATION_DOMAIN, INVESTWELL_LOGOS, INVESTWELL_CLOUD_IMAGES, INVESTWELL_LARGE_LOGOS } from 'app/constants'
import TxnsSuccessfullyPopup from 'app/uiCollection/public/TxnsConfirmation/txnsSuccessfullyPopup'

class TxnsConfirmation extends React.Component {
  constructor(props) {
    super(props)
	const { buildId = '' } = buildInfo || {}
    const link = document.createElement("link");
    link.rel = "stylesheet"
    link.href = `/ux/confirmation/media/css/confirmation/confirmation.css?v=${buildId}`
    document.head.appendChild(link);
	let version = new Date().getTime()
    
	this.domainObj = {domainName : window.location.hostname.split('.')[0]}
	let params = {};
	params.brokerDomain = this.domainObj.domainName;
	props.dispatch(GetUserTheme(params));

	this.state = {version,showPopup: false, orderCancelPopup: false }
  }

  componentDidMount(){
  	this.domainObj = {domainName : window.location.hostname.split('.')[0]}
    this.tokenObj = {token : window.location.href.split('#')[1].split('/')[2]}
    if(this.tokenObj.token){
      let params = {};
      params.token = this.tokenObj.token;
      params.componentForLoader = {componentName:'getMFUOrder'}
      this.props.dispatch(GetMfuOrderData(params));
    } 
    
  }
  confirmOrder(){
  	let params = {}
  	params.token = this.tokenObj.token;
  	params.confirmOrder = true
  	params.componentForLoader = {componentName:'getMFUOrder'}
  	this.props.dispatch(PlaceMfuOrder(params))

  	this.setState({
  		showPopup: true
  	})
  }

  orderCancel(){
  	this.setState({
  		orderCancelPopup: true
  	})
  }
  closeTab(){
  	window.open(window.location.origin,"_self")
  	this.setState({
  		orderCancelPopup: false,
  		showPopup: false
  	})

  	
  }

  render() {
  	let mfuOrderData = this.props.openStore && this.props.openStore.mfuOrderData
  	let placeMFUOrder = this.props.openStore && this.props.openStore.placeMFUOrder
    let txnType = mfuOrderData && mfuOrderData.txnType
    MFU_TXNS_TYPES.find((obj)=> {
      if (obj.value == txnType) {
        txnType = obj.label
      }
    })
	let largeLogoUrl = `${DOCS_LOCATION_DOMAIN}/${INVESTWELL_CLOUD_IMAGES}/${INVESTWELL_LOGOS}/${INVESTWELL_LARGE_LOGOS}/`

    return (
    	<>

    	<div id="wrapperArea">
    	    <header id="headerArea">
    	        <div class="headerTopContainer">
    	            <div id="logoArea">
						{this.props.userTheme && <img src={`${largeLogoUrl}${this.props.userTheme.logo}?v=${this.state.version} `} onError={(e)=>{e.target.onerror = null; e.target.src="../app/media/images/blankLogo.png"}} alt={this.props.userTheme.domain} />}
    	            </div>
    	        </div>
    	        <div class="bannerArea txtCenter">
    	            <img src="/ux/confirmation/media/images/redemption.png" alt="redemption" />
    	        </div>
    	    </header>
    	    <div class="siteContainer">
    	        <div class="box p50 lightGrayBg topShifted">
    	        	{this.props.openStore && this.props.openStore.mfuOrderLoader 
    	        		&& this.props.openStore.mfuOrderLoader.componentName == 'getMFUOrder' 
    	        		&& <Loader />
    	        	}
    	            <div class="boxContainer">
    	                <h1 class="txtCenter">Confirmation for {txnType} Order to MFU</h1>

    	                <div class="box whtBg mTopBtm30">
    	                    <div class="listingArea">
    	                        <ul>
    	                            <li>
    	                                <span class="heading">
    	                                    CAN :&nbsp;
    	                                </span>
    	                                <span class="sortContent">
    	                                   {mfuOrderData && mfuOrderData.canNumber}
    	                                </span>
    	                            </li>
    	                            <li>
    	                                <span class="heading">
    	                                    First holder :&nbsp;
    	                                </span>
    	                                <span class="sortContent">
    	                                    {mfuOrderData && mfuOrderData.investorName}
    	                                </span>
    	                            </li>
    	                            <li>
    	                                <span class="heading">
    	                                    Tax Status :&nbsp;
    	                                </span>
    	                                <span class="sortContent">
    	                                    {mfuOrderData && mfuOrderData.taxStatus}
    	                                </span>
    	                            </li>
    	                            <li>
    	                                <span class="heading">
    	                                    Holding :&nbsp; 
    	                                </span>
    	                                <span class="sortContent">
    	                                    {mfuOrderData && mfuOrderData.holding}
    	                                </span>
    	                            </li>
    	                            <li>
    	                                <span class="heading">
    	                                    Scheme :&nbsp; 
    	                                </span>
    	                                <span class="sortContent">
    	                                   {mfuOrderData && mfuOrderData.scheme}
    	                                </span>
    	                            </li>

                                  {mfuOrderData && mfuOrderData.targetSchemeName && <li>
                                      <span class="heading">
                                          Target Scheme :&nbsp; 
                                      </span>
                                      <span class="sortContent">
                                         {mfuOrderData && mfuOrderData.targetSchemeName}
                                      </span>
                                  </li>}
    	                        </ul>
    	                    </div>
    	                </div>
    	                {(mfuOrderData && mfuOrderData.redeemType == 'amount') && <h2 class="txtCenter">Amount - {mfuOrderData && mfuOrderData.amount}</h2>}
    	                {(mfuOrderData && mfuOrderData.redeemType == 'units') &&<h2 class="txtCenter">Units - {mfuOrderData && mfuOrderData.amount}</h2>}

    	                <div class="btnsContainer txtCenter pTop10 posRelative">
                        {this.props.openStore && this.props.openStore.mfuOrderLoader 
                          && this.props.openStore.mfuOrderLoader.componentName == 'getMFUOrder' 
                          && <Loader />
                        }
    	                    <button type="submit" onClick= {()=> this.confirmOrder()} >Confirm</button>
    	                    <button class="cancel" onClick= {()=> this.orderCancel()} >Cancel</button>
    	                </div>
    	            </div>
    	        </div>

    	        {this.state.showPopup && placeMFUOrder 
    	        	&& <TxnsSuccessfullyPopup 
    	        		closeTab = {() => this.closeTab()}
                  orderType = {txnType}
    	        		/>}
    	        {this.state.orderCancelPopup && 
        	        <div id="popupContainer">
        	            <div class="popupArea box whtBg">
        	                <div class="popupContentsBox txtCenter">
        	                    <p>Order has been cancelled 
        							The order link in Email is no longer valid.
        	                    </p>
        	                    <div class="btnsContainer  pTop10">
        	                        <a class="gray" href="javascript:void(0)" onClick= {()=> this.closeTab()} >Ok</a>
        	                    </div>
        	                </div>
        	            </div>
        	        </div>
    	        }

    	    </div>
    	</div>
    	</>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    openStore: state.factsheetReducer,
	userTheme: state.userReducer.theme,
  }
}
const mapDispatchToProp = (dispatch) => {
  return {dispatch:dispatch}
 }

export default connect(mapStateToProps, mapDispatchToProp)(TxnsConfirmation)




