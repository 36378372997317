var intialState = {
   
}
   
export default function dividendReportData(state = {}, action) {
  switch (action.type) {
    case 'DIVIDEND_REPORT_DATA':
     return { ...state, dividendReport: action.payload }

    case 'FAILED_DIVIDEND_REPORT_DATA':
     return { ...state, dividendReport: action.payload }

    case 'DIVIDEND_REPORT_PDF_DATA':
      return { ...state, dividendReportPdfData: action.payload }
      
    case 'FAILED_DIVIDEND_REPORT_PDF_DATA':
      return { ...state, dividendReportPdfData: action.payload }

    default:
     new Error('No action type matched in reducer')
  }
 return state
}