var intialState = {
    capitalGainSellData: {}
   }
   
   export default function capitalGainRealizedPDF(state = intialState, action) {
     switch (action.type) {
   
       case 'GOT_REALIZED_SELL_DATE':
         return { ...state, capitalGainSellData: action.payload }
        case 'COMPONENT_LOADER_CAP_GAIN_REALIZED':
          return {...state,loaderGainRealized:action.payload}
       case 'FAILED_REALIZED_SELL_DATE':
         return { ...state, capitalGainSellData: { errorMsg: action.payload } }

      case 'GOT_REALIZED_REPORT':
        return { ...state, realizedReportData: action.payload }
      case 'COMPONENT_LOADER_REALIZED_REPORT':
        return {...state,loaderRealizedReportData:action.payload}
      case 'FAILED_REALIZED_REPORT':
        return { ...state, realizedReportData:action.payload}

      case 'GOT_UNREALIZED_REPORT':
        return { ...state, unRealizedReportData: action.payload }
      case 'COMPONENT_LOADER_UNREALIZED_REPORT':
        return {...state,loaderRealizedReportData:action.payload}
      case 'FAILED_UNREALIZED_REPORT':
        return { ...state, unRealizedReportData:action.payload}

      case 'COMPONENT_LOADER_REDEMPTION_REPORT':
        return {...state,loaderRedemptionReport:action.payload}
      case 'GOT_REDEMPTION_REPORT':
        return { ...state, redemptionReportData: action.payload }
      case 'FAILED_REDEMPTION_REPORT':
        return { ...state, redemptionReportData:action.payload}

      case 'ADD_CAPITAL_GAIN_REALIZED_FILTERS':
        return { ...state, capitalGainRealizedFilters: action.payload }

      case 'ADD_CAPITAL_GAIN_UNREALIZED_FILTERS':
        return { ...state, capitalGainUnrealizedFilters: action.payload }

      case 'ADD_DIVIDENT_REPORT_FILTERS':
        return { ...state, dividendReportFilters: action.payload }



   
       default:
         new Error('No action type matched in reducer')
     }
     return state
   }