import axios from 'axios'

export const getMfuOrderRepo = (data,isOpenAPI) => {
  	return axios({
    	method: 'GET',
    	url: '/api/op/getMfuOrder',
    	params:data
  	})
}

export const placeMfuOrderRepo = (data,isOpenAPI) => {
  	return axios({
    	method: 'POST',
    	url: '/api/op/placeMfuOrder',
    	data:data
  	})
}

export const navUpdatedDateRepo = (data) => {
    return axios({
      method: 'GET',
      url: '/api/op/getMaxNavDate',
      params:data
    })
}