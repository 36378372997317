var intialState = {
    insuranceData : {},
    insuranceDetail : {}
};

    
export default function insuranceReducer ( state=intialState, action ){

    switch (action.type) {

        case "GETTING_INSURANCE_LIST":
            return { ...state }

        case "COMPONENT_LOADER_INSURANCE_TABLE" : 
        	return { ...state, loaderInsuranceTable : action.payload }

        case "GOT_INSURANCE_LIST" : 
        	return { ...state, insuranceData : action.payload  }

        case "FAILED_INSURANCE_LIST" : 
        	return { ...state, notificationStatus : action.payload, errorNotification : true  }


        
        case "GOT_INSURANCE_DETAILS" :
            return { ...state, insuranceDetail : action.payload, errorMessage : null }

        case "FAILED_GETTING_INSURANCE_DETAILS" :
            return { ...state, errorMessage : action.payload }



        case "ADDED_INSURANCE_SUCCESSFULLY" : 
            return { ...state, notificationStatus : action.payload, errorNotification : false }

        case "FAILED_ADDING_INSURANCE" :
            return { ...state, notificationStatus : action.payload, errorNotification : true }


        case "DELETED_INSURANCE_SUCCESSFULLY" : 
            return { ...state, notificationStatus : action.payload, errorNotification : false }

        case "FAILED_DELETING_INSURANCE" :
            return { ...state, notificationStatus : action.payload, errorNotification : true }

        case "LOADER_BULK_UPLOAD_INSURANCE":
            return { ...state, loaderBulkInsurance: action.payload }

        case "BULK_INSURANCE_FILE_UPLOADED" : 
            return { ...state, bulkUploadInsurance : action.payload }

        case "BULK_INSURANCE_FILE_UPLOAD_FAILED" : 
            return { ...state, bulkUploadInsurance : { message : action.payload , status : -1 }  }

        case "GOT_INSURANCE_COMPANIES" : 
            return { ...state, insuranceCompanies : action.payload }
        case "FAILED_TO_GET_INSURANCE_COMPANIES" : 
            return { ...state, insuranceCompanies : action.payload }
        case "GOT_INSURANCE_POLICIES":
            return {...state,insurancePolicies:action.payload}
        case "FAILED_TO_GET_INSURANCE_POLICIES":
            return {...state,insurancePolicies:action.payload}

        case "COMPONENT_LOADER_INSURANCE_POLICY_DATA":
            return {...state,loaderInsurancePolicies:action.payload}    
        case "GOT_INSURANCE_POLICIES_DATA":
            return {...state,insurancePolicies:action.payload}
        case "FAILED_TO_GET_INSURANCE_POLICIES_DATA":
            return {...state,insurancePolicies:action.payload}
        

        case "CLEAR_NOTIFICATION" : 
            return { ...state, notificationStatus : null, errorNotification : false, notificationFor : null }

        default:
            new Error('No action type matched')
            
    }
   
    return state;
}
