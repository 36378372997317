var intialState = {};
export default function investOnline(state=intialState,action){
	switch(action.type){
		case 'GETTING_CLIENT_INVESTMENT_DATA':
			return{...state,status:"Getting Client Investment data"}
		case 'GOT_CLIENT_INVESTMENT_DATA':
			return{...state,iinNoData:action.payload}
		case 'COMPONENT_LOADER_INVEST_ONLINE':
			return {...state, loaderInvestOnline:action.payload}
		case 'FAILED_CLIENT_INVESTMENT_DATA':
			return{...state,status:"Unable To fetch Client Investment data"}
		case 'GETTING_IIN_LIST_SUMMARY':
			return{...state,}
		case 'GOT_IIN_LIST_SUMMARY':
			return{...state,iinNoSummary:action.payload}
		case 'COMPONENT_LOADER_INVEST_ONLINE_SUMMARY':
			return{...state,loaderKyc:action.payload}
		case 'FAILED_IIN_LIST_SUMMARY':
			return{...state,}
		case 'SUCCESS_SYNC_IIN':
				return {...state,notificationData:action.payload}
		case 'FAILED_SYNC_IIN':
			return {...state,notificationData:action.payload}
		
		case 'COMPONENT_LOADER_FOR_RETRIGGER_CONFORMATION_MAIL':
			return{...state,loaderForRetriggerConfirmationLink:action.payload}
		case 'GOT_RETRIGGER_CONFORMATION_MAIL':
			return{...state,notificationData:action.payload}
		case 'FAILED_RETRIGGER_CONFORMATION_MAIL':
			return{...state,notificationData:action.payload}
		case 'GETTING_TAX_STATUS':
			return{...state,}
		case 'GOT_TAX_STATUS':
			return{...state,taxStatusList:action.payload}
		case 'FAILED_GETTING_TAX_STATUS':
			return{...state,}
		case 'GETTING_BANK_DETAILS':
			return{...state,gettingIFSCdata:true,gotIFSCdata:false,failedIFSCdata:false}
		case 'GOT_BANK_DETAILS':
			return{...state,bankDetailsByIFSC:action.payload,gettingIFSCdata:false,gotIFSCdata:true,failedIFSCdata:false}
		case 'FAILED_GETTING_BANK_DETAILS':
			return{...state,gettingIFSCdata:false,gotIFSCdata:false,failedIFSCdata:true}
		case 'GETTING_BANK_ACC_TYPE':
			return{...state,}
		case 'GOT_BANK_ACC_TYPE':
			return{...state,accTypeList:action.payload}
		case 'FAILED_GETTING_BANK_ACC_TYPE':
			return{...state,}
		case 'GETTING_BANK_LIST':
			return{...state,}
		case 'GOT_BANK_LIST':
			return{...state,bankList:action.payload}
		case 'COMPONENT_LOADER_BANK_LIST':
			return{...state,loaderKyc:action.payload}
		case 'FAILED_GETTING_BANK_LIST':
			return{...state,}
		case 'GETTING_CITY_STATE':
			return{...state,gettingCityAndState:true}
		case 'GOT_CITY_STATE':
			return{...state,cityAndState:action.payload,gotCityAndState:true,gettingCityAndState:false}
		case 'COMPONENT_LOADER_CITY_LIST':
			return{...state,loaderKyc:action.payload}
		case 'FAILED_GETTING_CITY_STATE':
			return{...state,failedGettingCityAndState:true}
		case 'GETTING_COUNTRY_LIST':
			return{...state,gettingCountryList:true}
		case 'GOT_COUNTRY_LIST':
			return{...state,countryList:action.payload,gotCountryList:true,gettingCountryList:false}
		case 'FAILED_GETTING_COUNTRY_LIST':
			return{...state,failedGettingCountryList:true}
		case 'GETTING_CLIENT_DATA_BY_IIN':
			return{...state,investorDetailsByIIN:{gettingInvestorDetailsByIIN:true}}
		case 'GOT_CLIENT_DATA_BY_IIN':
			return{...state,investorDetailsByIIN:action.payload,gotInvestorDetailsDataByIIN:true,gettingInvestorDetailsByIIN:false}
		case 'FAILED_CLIENT_DATA_BY_IIN':
			return{...state,investorDetailsByIIN:{failedInvestorDetailsByIIN:true}}
		case 'CHECKING_KYC':
			return{...state,checkingKYC:true,checkedKYC:false,failedCheckingKYC:false}
		case 'CHECKED_KYC':
			return{...state,panStatus:action.payload.data,checkedKYC:true,checkingKYC:false}
		case 'COMPONENT_LOADER_CHECK_KYC':
			return{...state,loaderKyc:action.payload}
		case 'FAILED_CHECKING_KYC':
			return{...state,iinNo:{failedCheckingKYC:true,checkingKYC:false}}
		case 'UPLOADING_CHEQUE':
			return{...state,uploadingCheque:true}
		case 'UPLOADED_CHEQUE':
			return{...state,chequeUploaded:true}
		case 'FAILED_UPLOADING_CHEQUE':
			return{...state,faileduploadingCheque:true}
		case 'SUBMITTING_DOC':
			return{...state,submittingDoc:true,submittedDoc:false}
		case 'DOC_SUBMITTED':
			return{...state,submittedDoc:true,submittingDoc:false}
		case 'FAILED_SUBMITTING_DOC':
			return{...state,failedsubmittingDoc:true,docSubmissionError:action.payload}
		case 'CREATE_IIN_FOR_DATA':
			return{...state,fatcaData:action.payload}
		case 'FAILED_IIN_FOR_DATA':
			return{...state,fatcaData:{errorMsg:action.payload}}
		case 'CREATING_IIN':
			return{...state,creatingIIN:true}
		case 'IIN_CREATED':
			return{...state,createdIIN:true,creatingIIN:false,createdIINdata:action.payload}
		case 'FAILED_CREATING_IIN':
			return{...state,failedCreatingIIN:true,creatingIIN:false,createIINerror:action.payload}
		case 'LOADER_GET_FOLIO_DATA':
			return{ ...state, loaderGetFolioData: action.payload }
		case 'GETTING_CLIENT_FOLIO_DATA':
			return{...state,gettingClinetData:true}
		case 'GOT_CLIENT_FOLIO_DATA':
			return{...state,gotClinetData:true,gettingClinetData:false,clientFolioData:action.payload[0],gotClientData:true}
		case 'FAILED_GETTING_CLIENT_FOLIO_DATA':
			return{...state,failedGettingClinetData:true}
		case 'GETTING_CLIENT_WITHOUT_IIN_DATA':
			return{...state,}
		case 'GOT_CLIENT_WITHOUT_IIN_DATA':
			return{...state,clientWithOutIIN:action.payload}
		case 'FAILED_CLIENT_WITHOUT_IIN_DATA':
			return{...state,}
		
		case 'GETTING_MANDATE_LIST_DATA':
			return{...state,}
		case 'GOT_MANDATE_LIST_DATA':
			return{...state,mandateList:action.payload}
		case 'COMPONENT_LOADER_MANDATE_LIST':
			return{...state,mandateListLoader:action.payload}
		case 'FAILED_MANDATE_LIST_DATA':
			return{...state,}

		case 'GETTING_SUBMIT_MANDATE_DATA':
			return{...state,}
		case 'GOT_SUBMIT_MANDATE_DATA':
			return{...state,createSubmitMandate:action.payload}
		case 'FAILED_SUBMIT_MANDATE_DATA':
			return{...state,}
		
		case 'GETTING_DOWNLOAD_MANDATE_FORM_DATA':
			return{...state,}
		case 'GOT_DOWNLOAD_MANDATE_FORM_DATA':
			return{...state,downloadMandateForm:action.payload}
		case 'FAILED_DOWNLOAD_MANDATE_FORM_DATA':
			return{...state,}
		
		case 'FATCA_SUBMITTED': 
		return{...state,fatcaSbmitted:action.payload, fatcaPass: true}

		case 'COMPONENT_LOADER_FATCA':
			return{...state,loaderKyc:action.payload}
		case 'FAILED_SUBMITTING_FATCA': 
		return{...state,fatcaSbmitted:action.payload}

		case 'STOP_API_CALL': 
		return{...state,fatcaPass:action.payload}

		case 'CHECKING_FATCA': 
		return{...state,checkingFatca:true,checkedFatca:false}

		case 'CHECKED_FATCA': 
		return{...state,checkingFatca:false,checkedFatca:true,fatcaStatus:action.payload}

		case 'FAILED_CHECKING_FATCA': 
		return{...state,checkingFatca:false,checkedFatca:false,failedFatca:true}

		case 'FATCA_SUBMITTED_JOINT': 
		return{...state,fatcaSbmittedJoint:action.payload}

		case 'FAILED_SUBMITTING_FATCA_JOINT': 
		return{...state,fatcaSbmittedJoint:{errorMsg:action.payload}}

		case 'SUBMIT_FATCA_FROM_LIST': 
		return{...state,fatcaSbmittedFromList:action.payload}

		case 'COMPONENT_LOADER_EXISTING_IIN': 
		return{...state,loaderInvestOnline:action.payload}

		case 'CHECKED_EXISTING_IIN': 
		return{...state,existingIIN:action.payload,notificationData:action.payload}

		case 'FAILED_CHECKING_EXISTING_IIN': 
		return{...state,existingIIN :action.payload,notificationData:action.payload}

		case 'GOT_CREATE_IIN_DATA':
			return{...state,createProfileData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_CREATE_IIN':
			return{...state,loaderCreateProfile:action.payload}
		case 'FAILED_CREATE_IIN_DATA':
			return{...state,createProfileData:action.payload,notificationData:action.payload}

		case 'STEP_NO_NSE_FORM':
			return {...state,stepNo:action.payload}

		case 'STEP_NO_NSE_FORM_FLAG':
			return {...state,stepNoFlag:action.payload}

		case 'SAVE_AND_EXIT':
			return {...state,saveAndExit:action.payload}

		case 'INDEX_UPLOAD_DOC':
			return {...state,indexUploadDoc:action.payload}

		case 'ON_PAN_CHANGE_FLAG':
			return {...state,CheckKYCFlag:action.payload}

		case 'NOMINEES_DETAILS_DATA':
			return {...state,savedNomineeDetailsData:action.payload}

		case 'PREFILED_NOMINEES_DETAILS_DATA':
			return {...state,prefilledNomineeDetails:action.payload}

		case 'EDIT_NOMINEES_DETAILS_DATA':
			return {...state,editNomineeDetailsData:action.payload}

		case 'EDIT_BANK_DETAILS_DATA_NSE':
			return{...state,editBankDetails:action.payload}

		case 'DELETE_BANK_FROM_LIST_NSE':
			return{...state,deleteBankData:action.payload}	

		case 'BANK_DETAILS_DATA_NSE':
			return{...state,savedBankDetails:action.payload}

		case 'SET_AS_DEFAULT_BANK_NSE':
			return{...state,defaultBankData:action.payload}

		case 'PREFILLED_BANK_DETAILS_DATA_NSE':
			return{...state,prefilledBankDetails:action.payload}

		case 'EDIT_FATCA_DETAILS_DATA_NSE':
			return{...state,editFatcaDetails:action.payload}

		case 'FATCA_DETAILS_FINISH_FLAG_NSE':
			return{...state,fatcaDetailsFinish:action.payload}

		case 'ALREADY_FATCA_DONE_FLAG':
			return{...state,fatcaCompleted:action.payload}

		case 'SHOW_HIDE_NOTIFICATION_MSG':
			return {...state,showHideNotification:action.payload}

		case 'CREATE_PROFILE_SUBMIT_OBJECT':
			return {...state,createProfileSubmitObj:action.payload}

		case 'GOT_INVESTOR_REGISTRATION_DATA_NSE':
			return{...state,registrationData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_INVESTOR_REGISTRATION_NSE':
			return {...state,loaderInvestorRegistration:action.payload}
		case 'FAILED_INVESTOR_REGISTRATION_DATA_NSE':
			return{...state,registrationData:action.payload, notificationData:action.payload}

		case 'GOT_NOMINEE_DETAILS_DATA_NSE':
			return{...state,nomineeDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_NOMINEE_DETAILS_NSE':
			return {...state,loaderNomineeDetails:action.payload}
		case 'FAILED_NOMINEE_DETAILS_DATA_NSE':
			return{...state,nomineeDetailsData:action.payload, notificationData:action.payload}

		case 'GOT_BANK_DETAILS_DATA_NSE':
			return{...state,bankDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_BANK_DETAILS_NSE':
			return {...state,loaderBankDetails:action.payload}
		case "COMPONENT_LOADER_FOR_COMPLETE_IIN_FORM":
			return {...state,loaderIINform:action.payload}
		case 'FAILED_BANK_DETAILS_DATA_NSE':
			return{...state,bankDetailsData:action.payload, notificationData:action.payload}

		case 'GOT_FATCA_DETAILS_DATA_NSE':
			return{...state,fatcaDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_FATCA_NSE':
			return {...state,loaderFatca:action.payload}
		case 'FAILED_FATCA_DETAILS_DATA_NSE':
			return{...state,fatcaDetailsData:action.payload, notificationData:action.payload}

		case 'GOT_SUBMIT_DOCUMENT_NSE':
			return{...state,submitDocData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_SUBMIT_DOCUMENT_NSE':
			return {...state,loaderSubmitDoc:action.payload}
		case 'FAILED_SUBMIT_DOCUMENT_NSE':
			return{...state,submitDocData:action.payload,notificationData:action.payload}

		case 'GOT_EDIT_IIN_DATA':
			return{...state,editUserIINData:action.payload}
		case 'COMPONENT_LOADER_EDIT_IIN_DATA':
			return {...state,loaderEditIINData:action.payload}
		case 'FAILED_EDIT_IIN_DATA':
			return{...state,editUserIINData:action.payload}

		case 'SUCCESS_EDIT_IIN':
			return{...state,editIINData:action.payload}
		case 'FAILED_EDIT_IIN':
			return{...state,editIINData:action.payload}

		case 'GOT_IIN_HOLDERS_DATA':
			return{...state,iinHoldersData:action.payload}
		case 'FAILED_IIN_HOLDERS_DATA':
			return{...state,iinHoldersData:action.payload}
		case 'COMPONENT_LOADER_IIN_HOLDERS':
			return{...state,loaderIINHolders:action.payload}

		case 'GOT_NSE_MANDATES_LIST_DATA': 
			return{...state,mandateListData:action.payload}
		case 'COMPONENT_LOADER_NSE_MANDATES_LIST': 
			return{...state,loaderMandateList:action.payload}
		case 'FAILED_NSE_MANDATES_LIST_DATA':
			return{...state,mandateListData :action.payload}

		case 'GOT_DELETE_MANDATE_LIST_DATA': 
			return{...state,deleteMandateListData:action.payload,notificationData:action.payload}
		case 'FAILED_DELETE_MANDATE_LIST_DATA': 
			return{...state,deleteMandateListData :action.payload,notificationData:action.payload}

		case 'GOT_DELETE_NSE_PROFILE_DATA': 
			return{...state,deleteNseProfileData:action.payload,notificationData:action.payload}
		case 'FAILED_DELETE_NSE_PROFILE_DATA': 
			return{...state,deleteNseProfileData :action.payload,notificationData:action.payload}

		case 'GOT_MANDATES_LIST_SUMMARY_DATA': 
			return{...state,mandateListSummaryData:action.payload}
		case 'FAILED_MANDATES_LIST_SUMMARY_DATA':
			return{...state,mandateListSummaryData :action.payload}

		case 'GOT_REGISTER_MANDATE_DATA': 
			return{...state,registerMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_REGISTER_MANDATES': 
			return{...state,loaderRegisterMandate:action.payload}
		case 'FAILED_REGISTER_MANDATE_DATA': 
			return{...state,registerMandateData :action.payload,notificationData:action.payload}

		case 'GOT_UPLOAD_NSE_MANDATES_DATA': 
			return{...state,uploadMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_UPLOAD_NSE_MANDATES': 
			return{...state,loaderUploadMandate:action.payload}
		case 'FAILED_UPLOAD_NSE_MANDATES_DATA': 
			return{...state,uploadMandateData :action.payload,notificationData:action.payload}

		case 'GOT_UPLOAD_NSE_DOC_FORM_DATA':
			return{...state,gotUploadDocFormData:action.payload}
		case 'COMPONENT_LOADER_UPLOAD_NSE_DOC_FORM':
			return{...state,loaderDocFormUpload:action.payload}
		case 'FAILED_UPLOAD_NSE_DOC_FORM_DATA':
			return{...state,gotUploadDocFormData:action.payload}

		case 'GOT_STATE_MASTER':
			return{...state,stateMasterData:action.payload}
		case 'COMPONENT_LOADER_STATE_MASTER':
			return{...state,loaderStatemaster:action.payload}
		case 'FAILED_STATE_MASTER':
			return{...state,stateMasterData:action.payload}

		case 'SHOW_HIDE_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'CLEAR_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'SUCCESS_ADD_ADDITIONAL_BANK':
			return{...state,additionalBankDetailsData:action.payload,notificationData:action.payload}
		case 'FAILED_ADD_ADDITIONAL_BANK':
			return{...state,additionalBankDetailsData:action.payload, notificationData:action.payload}

		case 'COMPONENT_LOADER_PROFILE_LIST': 
			return{...state, loaderProfileList:action.payload}


		case 'GOT_NSE_FATCA_STATUS':
			return{...state,fatcaInfo:action.payload,notificationData:action.payload}
		case 'FAILED_NSE_FATCA_STATUS':
			return{...state,fatcaInfo:action.payload,notificationData:action.payload}


		case 'GOT_IIN_BANK_DETAILS':
			return{ ...state, iinBankDetails: action.payload }
		case 'FAILED_TO_GET_IIN_BANK_DETAILS':
			return{ ...state, iinBankDetails: action.payload }

		case 'LOADER_TO_CHECK_NSE_FATCA': 
			return{...state, loaderProfileList:action.payload}

		case "DOWNLOAD_KYC_FORM_LOADER":
			return { ...state, downloadKycFormLoader: action.payload }
		case "DOWNLOAD_KYC_FORM":
			return { ...state, downloadKycForm: action.payload }
		case "FAILED_DOWNLOAD_KYC_FORM":
			return { ...state, downloadKycForm: action.payload }

		case 'GOT_VIDEO_KYC_LINK_MAIL':
			return{...state,videoKycMailData:action.payload,notificationData:action.payload}
		case 'FAILED_VIDEO_KYC_LINK_MAIL':
			return{...state,videoKycMailData:action.payload,notificationData:action.payload}

		case 'GOT_VIDEO_KYC_LINK_WHATSAPP':
			return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}
		case 'FAILED_VIDEO_KYC_LINK_WHATSAPP':
			return{...state,videoKycWhatsAppData:action.payload,notificationData:action.payload}

		case 'GOT_CHECK_IIN_STATUS_PROFILE': 
			return{...state,checkIINStatusResult:action.payload,notificationData:action.payload}
		case 'FAILED_CHECK_IIN_STATUS_PROFILE': 
			return{...state,checkIINStatusResult :action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_MANDATE_STATUS':
			return{...state,loaderMandateList:action.payload}
		case 'GOT_CHECK_IIN_MANDATE_STATUS':
			return{...state,iinMandateStatus:action.payload,notificationData:action.payload}
		case 'FAILED_CHECK_IIN_MANDATE_STATUS':
			return{...state,iinMandateStatus:action.payload,notificationData:action.payload}
		case 'GOT_CHECK_ORDER_STATUS':
		case 'FAILED_CHECK_ORDER_STATUS':
			return { ...state, checkOrderStatus: action.payload,notificationData: action.payload }
		case 'GOT_TXN_SLIP':
			return{...state,trxnSlip:action.payload}
		case 'FAILED_TO_GET_TXN_SLIP':
			return{...state,notificationData:action.payload}
		case 'CANCEL_ORDER_STATUS':
			return{...state,cancelOrderStatus:action.payload}
		case "COMPONENT_LOADER_CANCEL_ORDER_STATUS":
			return {...state,loaderCancelOrder: action.payload}
		default:
      		new Error('No action type matched in Filter reducer')
	}
	return state;
}

